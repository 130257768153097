// src/utils/indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'snp_react_db';
const STORE_PRODUCT = 'snp_react_prd_table';
const STORE_STATE = 'appState'
const DB_VERSION = 1;

// const dbPromise = openDB(DB_NAME, DB_VERSION, {
//     upgrade(db) {
//         if (!db.objectStoreNames.contains(STORE_NAME)) {
//             db.createObjectStore(STORE_NAME, { keyPath: 'id' });
//         }
//     },
// });

let dbInstance = null;

export const getDBConnection = async () => {
  if (!dbInstance) {
    dbInstance = await openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_PRODUCT)) {
          db.createObjectStore(STORE_PRODUCT, { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains(STORE_STATE)) {
          db.createObjectStore(STORE_STATE);
        }
      },
    });
  }
  return dbInstance;
};

export async function addDataIndb(data) {
    const db = await getDBConnection();
    const tx = db.transaction(STORE_PRODUCT, 'readwrite');
    const store = tx.objectStore(STORE_PRODUCT);
    await store.put(data);
    await tx.done;
}

export async function getDataFromDb(id) {
    const db = await getDBConnection();
    const tx = db.transaction(STORE_PRODUCT, 'readonly');
    const store = tx.objectStore(STORE_PRODUCT);
    const data = await store.get(id);
    await tx.done;
    return data;
}

export async function getAllData() {
    const db = await getDBConnection();
    return db.getAll(STORE_PRODUCT);
}

export async function deleteItemForDb(id) {
    const db = await getDBConnection();
    const tx = db.transaction(STORE_PRODUCT, 'readwrite');
    const store = tx.objectStore(STORE_PRODUCT);
    await store.delete(id);
    await tx.done;
}



// Save Redux State with Transaction
export const saveStateToIndexedDB = async (state) => {
    try {
      const db = await getDBConnection();
      const tx = db.transaction(STORE_STATE, 'readwrite');
      await tx.store.put(state, 'reduxState');
      await tx.done;
    } catch (error) {
      console.error('Failed to save Redux state:', error);
    }
  };
  
  // Get Redux State
  export const getReduxState = async () => {
    try {
      const db = await getDBConnection();
      return await db.get(STORE_STATE, 'reduxState');
    } catch (error) {
      console.error('Failed to retrieve Redux state:', error);
      return null;
    }
  };

// Clear IndexedDB Store (useful for logging out users)
export const clearStore = async (storeName) => {
    try {
      const db = await getDBConnection();
      const tx = db.transaction(storeName, 'readwrite');
      await tx.store.clear();
      await tx.done;
    } catch (error) {
      console.error(`Failed to clear store ${storeName}:`, error);
    }
  };
