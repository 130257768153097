import React, { useState } from "react";
import { DataGrid, GridFilterPanel } from "@mui/x-data-grid";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { styled } from '@mui/material/styles';
import GridToolbarCustom from './GridToolbarCustom';
import { Box } from "@mui/material";
import { INITIAL_PAGEMODEL, PAGESIZEOPTIONLISTDEF } from "../../constants/constant";
import './CustomDataGrid.css';
//import CustomFilterInput from "./CustomFilterInput";


export default function CustomDataGrid(props) {
  //const [filt, setFilt] = useState([])
  const MyFilterAltIcon = styled(FilterAltIcon)({
    color: 'white',
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const {
    getRowId = "",
    rows = "",
    columns = "",
    loading = false,
    paginationAllowed = true,
    paginationModel = INITIAL_PAGEMODEL,
    paginationMode = "client",
    pageSizeOptionList = PAGESIZEOPTIONLISTDEF,
    disableSelectionOnClick = true,
    onPageDataChange = (e) => e,
    checkboxSelection = false,
    cbPageSizeChange = (e) => e,
    getRowClassName = (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row',
    mFilterMode = "client",
    mDensity = "compact",
    mOnFilterModelChange = (e) => e,
    mDisableColumnFilter = false,
    mFilterPanel = () => <GridFilterPanel />,
    // children,
  } = props;
  const [internalPaginationModel, setInternalPaginationModel] = useState(INITIAL_PAGEMODEL);
  // useEffect(() => {
  //   //console.log("useEffect",internalPaginationModel);
  // }, [internalPaginationModel]);


  /** Handlers */
  const onPaginationModelModelChange = (mPage) => {
    console.log("onPaginationModelModelChange", mPage);
    if (paginationMode === 'server')
      onPageDataChange(mPage);
    else {
      let data = { page: mPage, pageSize: internalPaginationModel.pageSize, rowCount: internalPaginationModel.rowCount };
      setInternalPaginationModel(data);
    }

  };


  const onBasePageSizeChange = (mPageSize) => {
    console.log("onBasePageSizeChange", mPageSize);
    if (paginationMode === 'server')
      cbPageSizeChange(mPageSize);
    else
      setInternalPaginationModel({
        ...internalPaginationModel, pageSize: mPageSize
      })
  }

  const onFilterChange = React.useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    //console.log("onFilterChange",filterModel)
    mOnFilterModelChange(filterModel)
  }, []);



  const getRowHeight = (rowIndex, columnIndex) => {
    // Get the content of the cell.
    const cellContent = rows[rowIndex][columnIndex];
    // Calculate the height of the cell.
    const height = cellContent.length * 16;
    // Return the height.
    return height;
  };


  return (
    <>
      <Box sx={{
        height: 480,
        width: "100%",
        marginTop: "1%",
      }}>
        {
          paginationMode === "server" ?
            <DataGrid
              disableColumnFilter={mDisableColumnFilter}
              density="compact"
              getRowId={getRowId}
              rows={rows}
              filterMode={mFilterMode}
              onFilterModelChange={onFilterChange}
              columns={columns}
              getRowClassName={getRowClassName}
              pagination={paginationAllowed}
              page={paginationMode === 'server' ? paginationModel.page : internalPaginationModel.page}
              pageSize={paginationMode === 'server' ? paginationModel.pageSize : internalPaginationModel.pageSize}
              onPageChange={onPaginationModelModelChange}
              onPageSizeChange={onBasePageSizeChange}
              rowCount={paginationModel.rowCount || (rows || []).length}
              rowsPerPageOptions={pageSizeOptionList}
              paginationModel={paginationMode === 'server' ? paginationModel : internalPaginationModel}
              //onPaginationModelChange={onPaginationModelModelChange}
              paginationMode={paginationMode}
              // pageSizeOptions={pageSizeOptionList}
              disableSelectionOnClick={disableSelectionOnClick}
              isCellEditable={() => false}
              experimentalFeatures={{ newEditingApi: true }}
              loading={loading}
              checkboxSelection={checkboxSelection}
              components={{
                ColumnMenuIcon: MyFilterAltIcon,
                FilterPanel: mFilterPanel,
                Toolbar: GridToolbarCustom
              }}
              componentsProps={{
                toolbar: {
                  selectionModel,
                },
              }}
              // filterModel={{
              //     items: filt
              //   }}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              sx={{
                boxShadow: 15,
                border: 2,
                borderRadius: 3,
                borderColor: 'primary.paperborder',
                backgroundColor: "background.paper",
                '& .MuiDataGrid-cell:hover': {
                  color: 'brown',
                  backgroundColor: 'lightbrown',
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-row.Mui-odd ': {
                  backgroundColor: 'primary.datagridrow',
                },
                '&.MuiDataGrid-withBorderColor' : {
                  withBorderColor:"#00333e"
                },
                '& .MuiDataGrid-columnHeaders': {
                  // backgroundColor:'primary.datagridCol',
                  backgroundColor: 'brown',
                  color: 'white',
                  fontFamily: '"Nunito", sans-serif',
                },
                '& .MuiDataGrid-row': {
                  backgroundColor: 'background.paper',
                },
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
              }}

            /> :
            <>
            {/* {console.log("muidata",mDensity)} */}
            <DataGrid
              getRowId={getRowId}
              getRowHeight={() => 'auto'} // Dynamic row height
              rows={rows}
              density={mDensity}
              columns={columns}
              getRowClassName={getRowClassName}
              pagination={paginationAllowed}
              //   paginationMode={paginationMode}
              //   page={state.page}
              //   onPageChange={onTablePageChangeHandler}
              //   pageSize={state.pageSize}
              //   onPageSizeChange={onTablePageSizeChangeHandler}
              rowCount={paginationModel.rowCount || (rows || []).length}
              //   rowsPerPageOptions={state.pageSizeOptionList}
              paginationModel={paginationMode === 'server' ? paginationModel : internalPaginationModel}
              onPaginationModelChange={onPaginationModelModelChange}
              paginationMode={paginationMode}
              pageSizeOptions={pageSizeOptionList}
              disableSelectionOnClick={disableSelectionOnClick}
              isCellEditable={() => false}
              experimentalFeatures={{ newEditingApi: true }}
              loading={loading}
              checkboxSelection={checkboxSelection}
              components={{
                ColumnMenuIcon: MyFilterAltIcon,
                Toolbar: GridToolbarCustom
              }}
              componentsProps={{
                toolbar: {
                  selectionModel,
                },
              }}
              // filterModel={{
              //     items: filt
              //   }}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              sx={{
                boxShadow: 15,
                border: 2,
                borderRadius: 3,
                borderColor: 'primary.paperborder',
                backgroundColor: "background.paper",
                '& .MuiDataGrid-cell:hover': {
                  color: 'brown',
                  backgroundColor: 'lightbrown',
                  fontWeight: 'bold',
                  border: '2px solid #00333e',
                  borderRadius: 2,
                },
                '& .MuiDataGrid-columnHeaders': {
                  // backgroundColor:'primary.datagridCol',
                  backgroundColor: 'brown',
                  color: 'white',
                  fontFamily: '"Nunito", sans-serif',
                },
                
              }}

            /></>

        }

      </Box>
    </>
  );
};



//Backup
// '&.MuiDataGrid-withBorderColor' : {
//   withBorderColor: "#00333e"
// },
// '& .MuiDataGrid-row.Mui-odd ': {
//   backgroundColor: 'primary.datagridrow',
//                 },
// '& .MuiDataGrid-row': {
//   backgroundColor: 'background.paper',
//                 },
// '.MuiDataGrid-iconButtonContainer': {
//   visibility: 'visible',
//                 },
// '.MuiDataGrid-sortIcon': {
//   opacity: 'inherit !important',
//                 },