import { printDebugLog } from "../constants/constant";

export const initialState = {
    productData : {} // Why were we using map here?
}

const reducer = (state = initialState, action) => {
    printDebugLog("SET_PRD_DATA",action.productData);
    switch (action.type) {
        case "SET_PRD_DATA" : {
            return {
                ...state, 
                productData : action.productData
            }
        }
        default : return state;
    }
}

export default reducer;