import React from "react";
import SnpBillComp from "./SnpBillComp";
import { INITIAL_BILL_DETAILS, INITIAL_BILL_STOCK_DATA, INITIAL_DEL_ITEM, INITIAL_GENERATE_DIALOG, INITIAL_LISTBILLINGDATA, INITIAL_PARTIAL_PAY, INITIAL_PAYMENT_DETAILS, INITIAL_RIDER_DETAILS, INITIAL_SNP_COMPONENT, INITIALROOMDETAIS } from "./RestBillConstants";

export default function RestoBillingModuleV2() {
  

  return (
    <>
      <SnpBillComp  />
      {/* <SnpComponent
        showDialog={snpComponent.showDialog}
        isLoader={snpLoader}
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        handleDialogClose={handleSnpDialogClose}
        iconReq={snpComponent.iconReq}
        iconContent={snpComponent.iconContent}
        // content={renderSnpContent()}
        onSnackClose={handleSnackClose}
        cancelExtra={snpComponent.cancelExtra}
        onCancel={
          snpComponent.requestType === 99
            ? snpComponent.handleSnpCancel
            : handleSnpCancel
        }
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss}
        handleSubmit={
          snpComponent.requestType === 99
            ? snpComponent.handleSnpSubmit
            : handleSnpSubmit
        }
        gui={snpComponent.gui}
        snackHorizontal={snpComponent.snackHorizontal}
        snackVertical={snpComponent.snackVertical}
        snackColor={snpComponent.snackColor}
      /> */}
    </>
  );
  //UI Corner end
}
