import React, { useLayoutEffect, useState } from "react";
import './RestoProductStock.css';
import { Grid, TextField, Box, Checkbox, TableBody, Card, FormControl } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InventoryIcon from '@mui/icons-material/Inventory';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Autocomplete from "@mui/material/Autocomplete";
import {
  GET_CATEOGORY_DATA,
  GET_RESTAURANT_DATA,
  SET_RESTAURANT_DATA,
  getPreUrlAccordingToCart,
  SUCCESS,
  // ItemStatus,
  FoodType,
  Spicy, OfferType,
  CART_INITIAL_STATE,
  ITEMWISEOFFER,
  GETSINGLECONFIGS,
  isRoleAllowed,
  INVENTORYCREATE,
  INVENTORYUPDATEPRICING,
  INITIAL_PAGEMODEL,
  THEMEMGMT,
  ECARTAPP,
  ItemStatus,
  printDebugLog,
  isFeatureAllowed,
  DELETEIMAGE,
  printInfoLog,
  GETFOODITEMSUGGESTION,
  isEmpty,
  LIVESTOCKUPDATE,
  STOCKMGMT,
  GET_SUPPLIER_FEW_DATA,
  SUPMGMT,
  STOCKPRICINGFEATURE,
  GET_CONVERSIONTYPE_DATA,
  GETRESTOSTOCKENTRY,
  isNumberEmpty,
  SETRESTOSTOCKENTRY,
  formatDateInReact,
  formatDateInSnp,
  SETLINKOSTOCKENTRY,
  GETLINKOSTOCKENTRY,
  ITEMMULTIACCOUNT,
  GETVIDEOPATH,
  RECPMGMT,
  FOODIMAGEVIAID,
  GETQUICKITEMS,
  local_test_url,
} from "../../constants/constant";
import ImageUploadService from "../../service/imageUploadService";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import {
  Button, RadioGroup, FormControlLabel,
  FormLabel, Radio
} from "@mui/material";
import spiceLevel3Logo from '../../assets/chili-pepper.png';
import spiceLevel1Logo from '../../assets/chili.png';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomDataGrid from "../global/CustomDataGrid";
import { useSelector } from "react-redux";
import { snpAxios } from '../global/api';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../global/loader/Loader";
import SnpComponent from "../global/SnpComponent";
import { SCREEN_INVENTORY } from "../../constants/VideoConstant";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import utilities from "../../utilities/utilities";
import { useNavigate } from "react-router-dom";
export default function RestoProductStock() {
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const navigate = useNavigate();

  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    iconReq: false,
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: null,
    handleSnpCancel: null,
    gui: "",
  };

  const INITIAL_RATE_CARD = {
    index: -1,
    name: "",
    sAmt: 0.0,
    cAmt: 0.0,
    id: "",
  };

  const INITIAL_CATEOGORY = {
    catgId: "",
    catgName: "",
  };

  const INITIAL_RESTO_STOCK_DATA = {
    id: "",
    catgId: "",
    catgName: "",
    itemStatus: ItemStatus.ACTIVE,
    prdName: "",
    productImage: [],
    spicy: Spicy.LESS_SPICY,
    foodType: FoodType.VEG,
    sgst: 2.5,
    cgst: 2.5,
    igst: 0.0,
    outOfStock: false,
    offertype: OfferType.No_Offer,
    offerAmt: 0.0,
    price: [],
    liveStockCount: "",
    ingredients: "",
    cartVisible: true,
    accountName: "",
    accountTitle: "",
    stockVisible: true,
  };

  const INITIAL_STOCK_DETAILS = {
    id: "",
    stockId: "", //Product Id
    stockName: "",
    supplierId: "",
    supplierName: "",
    billNo: "",
    mfgDate: "",
    expiryDate: "",
    sgst: 0.0,
    cgst: 0.0,
    custGstDisabled: false,
    fullPrice: 0.0,
    price: 0.0,
    liveStockCountStr: "",
    quantity: 0,
    smallQuantity: 0,
    taxMethod: false,
    productmrPrice: 0.0,
    offerDenom: OfferType.No_Offer,
    offerAmt: 0.0,
    productSellingPrice: 0.0,
    productWholeSalePrice: 0.0,
    sproductmrPrice: 0.0,
    purchasePrice: 0.0,
    sofferDenom: OfferType.No_Offer,
    sofferAmt: 0.0,
    sproductSellingPrice: 0.0,
    sproductWholeSalePrice: 0.0,
    buyerGstTaken: true,
    cnvId: "",
    unitName: "",
    unitCount: 1,
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 0,
    isExpired: false,
    typeStr: "",
    type: 2,
    description: "",
    systemGenerated: false,
    createdAt: ""
  };

  const INITIAL_SUPPLIER = {
    supplierId: "",
    supplierName: "",
  };

  const INITIAL_LINK_STOCK = {
    id: "",
    stockId: "",
    stockName: "",
    linkedToId: "",
    linkedToName: "",
    smallQuantity: 0.0,
  };

  const INITIAL_CONVERSION_SINGLE_DATA = {
    cnvId: "",
    unitName: "",
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 1,
  };

  const INITIAL_ITEM_DATA = {
    id: "",
    name: "",
  }


  const [paginationModel, setPaginationModel] = useState(INITIAL_PAGEMODEL);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showStockDialog, setStockShowDialog] = useState(false);
  const [linkStockDialog, setLinkShowDialog] = useState(false);
  const [dialogLoader, setDialogLoader] = useState(false);
  //const [deltedSuccessfully, setDelSuccess] = useState(false);
  const [isEditProduct, setEditProduct] = useState(false);
  const [restoData, setRestoData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [imageSelectedError, setImageSelectedError] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState("");
  const [selectedRateCard, setSelectedRateCard] = useState(INITIAL_RATE_CARD);
  const [rateCard, setRateCard] = useState([]);
  const [selectedCategory, setSelectedcategory] = useState(INITIAL_CATEOGORY);
  const [restoSingleData, setRestoSingleData] = useState(INITIAL_RESTO_STOCK_DATA);
  const [showTable, setShowTable] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [itemWiseOffer, setItemWiseOffer] = useState(false);
  const [ecartApp, setEcartApp] = useState(false);
  const [stockPricingFeature, setStockPricingFeature] = useState(false);
  const [foodItemData, setFoodItemData] = useState([]);
  const [suggestedFoodItem, setSuggestedFoodItem] = useState([])
  const [suggestedFoodItemDeleted, setSuggestedFoodItemDeleted] = useState([])
  const [suggestionAvail, setSuggAvail] = useState(false);
  const [itemData, setItemData] = useState(INITIAL_ITEM_DATA);
  const [supplierData, setSupplierData] = useState(INITIAL_SUPPLIER);
  const [stockSingleData, setStockData] = useState(INITIAL_STOCK_DETAILS);
  const [listStockData, setListStockData] = useState([]);
  const [listSupplierData, setListSupplierData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
  const [conversionSingleData, setConversionSingleData] = useState(INITIAL_CONVERSION_SINGLE_DATA);
  const [conversionTypeData, setConversionTypeData] = useState([]);
  const [choiceUnit, setChoiceUnit] = useState({
    unitChecked: false,
    smallUnitChecked: false,
  });
  const [linkSingleData, setLinkSingleData] = useState(INITIAL_LINK_STOCK);
  const [itemMultiAcc, setItemMultiAcc] = useState(false);
  const [listAcc, setListAcc] = useState([]);
  const [videoModel, setVideoModel] = useState({ show: false, path: "" });
  const [listFoodItems, setListFoodItem] = useState([]);

  const [inputValue, setInputValue] = useState('');

  const handleItemSearchChange = (event) => {
    if (event)
      setInputValue(event.target.value);
  }

  const handleSelectChange = (event, catg) => {
    if (catg != null) {
      //console.log("handleSelectChange", catg, catg.catgId, catg.catgName);
      setSelectedcategory({ ...selectedCategory, catgId: catg.catgId, catgName: catg.catgName })
      setRestoSingleData({ ...restoSingleData, ...selectedCategory })
    }
    //console.log("handleSelectChange-> ",restoSingleData);
  };

  const handleSubmitRate = (event) => {
    setRateCard([
      ...rateCard, selectedRateCard
    ]);
    setSelectedRateCard(INITIAL_RATE_CARD);
    setShowTable(true);
  };

  //on edit icon click for rate name
  const handleSelectListChange = (val, index) => {
    //console.log("handleSelectListChange", val, index);
    printDebugLog("handleSelectListChange", val, index);
    setSelectedRateCard({
      ...selectedRateCard,
      name: val.name,
      cAmt: val.cAmt,
      sAmt: val.sAmt,
      index: index,
      id: val.id,
    });
  };

  const handleDeleteRateCard = (event) => {
    var rateCardCopy = [...rateCard];
    if (selectedRateCard.index >= 0 && rateCard[selectedRateCard.index]) {
      rateCardCopy.splice(selectedRateCard.index, 1);
      setRateCard([...rateCardCopy]);
    }
    setSelectedRateCard(INITIAL_RATE_CARD);
  }

  const handleEditRate = (event) => {
    let rateCardCopy = rateCard;
    if (selectedRateCard.index >= 0 && rateCard[selectedRateCard.index]) {
      rateCardCopy[[selectedRateCard.index]] = { ...selectedRateCard }
      setRateCard([...rateCardCopy]);
    }
    setSelectedRateCard(INITIAL_RATE_CARD);
  }

  const handleInputChange = (event) => {
    //console.log("handleInputChange",event);
    const { name, value } = event.target;
    // console.log("handleInputChange", name, value);
    if (name === 'imgPath') {
      let file = event.target.files[0];
      setUploadedDocument(file);
      if (file) {
        setImageSelectedError(false);
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
        //console.log('VN Data', reader.result,url)
        printDebugLog("handleInputChange", url);
        reader.onloadend = function (e) {
          //console.log('VN ssData', reader.result,url)
          setSelectedImage(reader.result)
        }
      } else {
        setSelectedImage('')
        setImageSelectedError(true);
      }
    } else if (name === 'name' || name === 'cAmt' || name === 'sAmt') {
      setSelectedRateCard({
        ...selectedRateCard,
        [name]: value,
      });
    } else if (name === 'itemStatus') {
      let num = Number(value);
      setRestoSingleData({
        ...restoSingleData,
        itemStatus: num,
        cartVisible: (num === ItemStatus.ACTIVE) ? true : false,
      });
    } else if (name === 'unitChecked') {
      let previousCheckedValue = choiceUnit.unitChecked;
      setChoiceUnit({
        ...choiceUnit,
        unitChecked: !previousCheckedValue,
      });
      setStockData({
        ...stockSingleData,
        quantity: 0,
      });
    } else if (name === 'smallUnitChecked') {
      let previousCheckedValue = choiceUnit.smallUnitChecked;
      setChoiceUnit({
        ...choiceUnit,
        smallUnitChecked: !previousCheckedValue,
      });
      setStockData({
        ...stockSingleData,
        smallQuantity: 0,
      });
    } else if (name === 'outOfStock') {
      let previousCheckedValue = restoSingleData.outOfStock;
      setRestoSingleData({
        ...restoSingleData,
        outOfStock: !previousCheckedValue,
      });
    } else {
      setRestoSingleData({
        ...restoSingleData,
        [name]: value,
      });
    }
    //console.log("setRestoSingleData-> ",restoSingleData);
  };

  const handleSnackClose = () => {
    setSnpComponent({
      ...snpComponent,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };

  const handleSupplierChange = (event, option) => {
    if (option != null) {
      //console.log("handleSupplierChange", event, option);
      setSupplierData({
        ...supplierData,
        supplierId: option.supplierId,
        supplierName: option.supplierName
      });
      setStockData({
        ...stockSingleData, supplierId: option.supplierId,
        supplierName: option.supplierName
      });
    }
  };

  const getCustomUnitDetails = (option) => {
    let data = "";
    printDebugLog("getCustomUnitDetails",option);
    if (null === option || undefined === option)
      return data;
    if (option.id === 'cr')
      data = option.unitName;
    else {
      if (option.unitName) {
        if (option.unitRestrictions) {
          if (option.smallUnitCount > 0 && option.smallUnitName)
            data = "Only " + option.unitName + "(" + option.smallUnitCount + " " + option.smallUnitName + ")";
          else
            data = "Only " + option.unitName;
        } else {
          data = option.unitName + "=" + option.smallUnitCount + " " + option.smallUnitName;
        }
      }
    }
    return data;
  };

  const getUnitDetails = () => {
    let check = stockSingleData.smallUnitCount > 0 && !stockSingleData.unitRestrictions;
    return check ? (stockSingleData.unitName + "=" + stockSingleData.smallUnitCount + " " + stockSingleData.smallUnitName + "(Loose)")
      : stockSingleData.unitName;
  };

  const getQuantityDetails = (val) => {
    let resp = val.quantity + " " + val.unitName;
    if (!val.unitRestrictions)
      resp = resp + "/" + val.smallQuantity + " " + val.smallUnitName;
    return resp;
  };


  const handleSelectStock = (option) => {
    setConversionSingleData({
      ...conversionSingleData,
      cnvId: option.cnvId,
      unitName: option.unitName,
      unitCount: option.unitCount,
      unitRestrictions: option.unitRestrictions,
      smallUnitName: option.smallUnitName,
      smallUnitCount: option.smallUnitCount,
    });
    setStockData(option);
    setSupplierData({
      ...supplierData,
      supplierId: option.supplierId,
      supplierName: option.supplierName
    });
    if (option.unitRestrictions) {
      setChoiceUnit({
        ...choiceUnit,
        unitChecked: true,
        smallUnitChecked: false,
      })
    } else {
      setChoiceUnit({
        ...choiceUnit,
        unitChecked: true,
        smallUnitChecked: false,
      })
    }
    setEditProduct(true);
  }



  const handleConversionChange = (event, option) => {
    if (option != null) {
      setConversionSingleData({
        ...conversionSingleData,
        cnvId: option.id,
        unitName: option.unitName,
        unitCount: option.unitCount,
        unitRestrictions: option.unitRestrictions,
        smallUnitName: option.smallUnitName,
        smallUnitCount: option.smallUnitCount,
      });
      setStockData({
        ...stockSingleData,
        cnvId: option.id,
        unitName: option.unitName,
        unitCount: option.unitCount,
        unitRestrictions: option.unitRestrictions,
        smallUnitName: option.smallUnitName,
        smallUnitCount: option.smallUnitCount,
      });
      if (option.unitRestrictions) {
        setChoiceUnit({
          ...choiceUnit,
          unitChecked: true,
          smallUnitChecked: false,
        })
      }
    } else {
      setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
      setStockData({
        ...stockSingleData,
        cnvId: "",
        unitName: "",
        unitCount: 0,
        unitRestrictions: false,
        smallUnitName: "",
        smallUnitCount: 0,
      });
      setChoiceUnit({
        ...choiceUnit,
        unitChecked: false,
        smallUnitChecked: false,
      })
    }
  };

  const getFoodItemsViaId = (id) => {
    let foodUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + FOODIMAGEVIAID + `?&token=${CART_INITIAL_STATE.apiToken}`
      + `&id=${id}`;
    let data = {};
    printDebugLog("getFoodItemsViaId<=", foodUrl);
    axios.get(
      foodUrl,
      data
    )
      .then((response) => {
        printDebugLog("getFoodItemsViaId=>", response.data);
        setLoader(false);
        if (response.data.status === SUCCESS) {
          setEditProduct(true);
          setSelectedcategory({
            ...selectedCategory,
            catgId: response.data.jcJson.catgId,
            catgName: response.data.jcJson.catgName
          })
          setRestoSingleData({
            ...restoSingleData,
            itemStatus: response.data.jcJson.itemStatus,
            prdName: response.data.jcJson.name,
            ingredients: response.data.jcJson.ingredients,
            brandName: response.data.jcJson.brandName,
            id: response.data.jcJson.id,
            productImage: response.data.jcJson.productImage,
            catgId: response.data.jcJson.catgId,
            catgName: response.data.jcJson.catgName,
            price: response.data.jcJson.price,
            spicy: response.data.jcJson.spicy,
            foodType: response.data.jcJson.foodType,
            sgst: response.data.jcJson.sgst,
            cgst: response.data.jcJson.cgst,
            igst: 0.0,
            outOfStock: response.data.jcJson.outOfStock,
            offertype: response.data.jcJson.offertype,
            offerAmt: response.data.jcJson.offerAmt,
            liveStockCount: response.data.jcJson.liveStockCount,
            cartVisible: response.data.jcJson.cartVisible,
            stockVisible: response.data.jcJson.stockVisible,
            accountTitle: response.data.jcJson.accountName,
            accountName: response.data.jcJson.accountName,
          })
          setRateCard(response.data.jcJson.price);
          setShowTable(true);
          if (response.data.jcJson.listItemSuggestion.length > 0) {
            setSuggAvail(true);
            setSuggestedFoodItem(response.data.jcJson.listItemSuggestion);
          }
          setShowDialog(true);
        }
      }).catch((error) => {
        setLoader(false);
        printInfoLog("error : ", error);
        //window.location.href = "#/error";
      });
  }

  const handleDeleteImage = (index) => {
    let path = restoSingleData.productImage[index];
    let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + DELETEIMAGE + `?productHash=${restoSingleData.id}`
      + `&path=${path}`;
    let data = {};
    setDialogLoader(true);
    printDebugLog("handleDeleteImage<=", cUrl);
    axios.get(
      cUrl,
      data
    )
      .then((response) => {
        printDebugLog("handleDeleteImage=>", response.data);
        setDialogLoader(false);
        if (response.data.status === SUCCESS) {
          // If item is found, create a new array without it
          if (index !== -1) {
            let images = restoSingleData.productImage;
            const updatedItems = [...images.slice(0, index), ...images.slice(index + 1)];
            setRestoSingleData({
              ...restoSingleData,
              productImage: updatedItems,
            })
            setSnpComponent({
              ...snpComponent, snackBarMsg: "Image removed successfull ... Updating Items",
              snackBarOpen: true,
            })
            setShowDialog(false);
            getRestoDetails();

          }
        }
      }).catch((error) => {
        setDialogLoader(false);
        printInfoLog("error : ", error);
        //window.location.href = "#/error";
      });
  }
  const getAllAccountName = () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + "accounting/get-account-names";
    let data = {
      jcJson: {
        shopHash: storeActiveShopHash.id,
        apiToken: CART_INITIAL_STATE.apiToken,
      }
    };
    setLoader(true);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        //printLog("getAllAccountName response  => ", response.data);
        setLoader(false);
        if (response.data.status === SUCCESS) {
          const updatedList = [...response.data.jcJson.listAcc, 'Other'];
          setListAcc(updatedList);
        }
      }).catch((error) => {
        printDebugLog("getAllAccountName errr ", error);
        setLoader(false);
        //window.location.href = "#/error";
      });
  };

  const getSingleConfigs = (mConfigName) => {
    let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
      + `&configName=${mConfigName}&shopHash=${storeActiveShopHash.id}`;
    let data = {};
    //const urlFormattedString = encodeURIComponent(cUrl);
    //console.log("getSingleConfigs => ", cUrl);
    axios.get(
      cUrl,
      data
    )
      .then((response) => {
        //console.log("getSingleConfigs<=",response.data);
        if (response.data !== '') {
          if (mConfigName === ITEMWISEOFFER)
            setItemWiseOffer(response.data === 'Yes')
          else if (mConfigName === ECARTAPP)
            setEcartApp(response.data === 'Yes');
          else if (mConfigName === STOCKPRICINGFEATURE)
            setStockPricingFeature(response.data === 'Yes');
          else if (mConfigName === ITEMMULTIACCOUNT) {
            let responseData = (response.data === 'Yes');
            setItemMultiAcc(responseData);
            if (responseData) getAllAccountName();
          }
        }
      }).catch((error) => {
        console.log("error : ", error);
        //window.location.href = "#/error";
      });
  };
  const handleImageUpload = async () => {
    let isError = false;
    let check = true;
    if (isEditProduct && restoSingleData.productImage.length > 0) {
      if (!uploadedDocument) {
        check = false;
      }
    }
    if (rateCard.length === 0) {
      setSnpComponent({
        ...snpComponent,
        snackBarOpen: true,
        snackBarMsg: "Please Add Rate Card to Proceed",
      })
      isError = true;
    }
    if (check) {
      if (!uploadedDocument || uploadedDocument.size > 5242880 || !(uploadedDocument.type.includes("image"))) {    //5248256
        setImageSelectedError(true);
        isError = true;
      }
      if (!isError) {
        setDataLoaded(false);
        setShowDialog(false);
        let formData = new FormData();
        formData.append('fnm', CART_INITIAL_STATE.apiToken);
        formData.append("file", uploadedDocument);
        await ImageUploadService.upload(storeApiUrl.BACKEND_BASE_URL, getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
          setUploadedDocument('');
          if (response.data.folderName) {
            setSnpComponent({
              ...snpComponent, snackBarMsg: "Image Uploaded Successfully ...", snackBarOpen: true,
            })
            printInfoLog("folderName", response.data.folderName, restoSingleData);
            if (!restoSingleData.productImage)
              restoSingleData.productImage = [];
            restoSingleData.productImage.push(response.data.folderName);
            handleSubmitResto();
          } else {
            setSnpComponent({
              ...snpComponent, snackBarMsg: "Failed to upload the image ...", snackBarOpen: true,
            })
            setDataLoaded(true);
            printDebugLog("response.data error", response.data);
          }

        }
        ).catch(error => {
          setDataLoaded(true);
          console.log("handleImageUpload = > ", error);
          handleSubmitResto();
          // window.location.href = "#/error";
        });
      }
    } else {
      handleSubmitResto();
    }
  };
  const getFoodType = (foodType) => {
    let data = "";
    if (foodType === FoodType.NONE)
      data = "None";
    else if (foodType === FoodType.VEG)
      data = "Veg";
    else if (foodType === FoodType.NON_VEG)
      data = "Non-Veg";
    else if (foodType === FoodType.EGG)
      data = "Egg";
    else if (foodType === FoodType.DRINK)
      data = "Drink";
    else if (foodType === FoodType.ICE_CREAM)
      data = "Ice-cream";
    else if (foodType === FoodType.SWEEETS)
      data = "Sweets";
    return data;
  };

  const columns = [
    {
      field: "productImage",
      headerName: "Image",
      width: 90,
      renderCell: (rowData) => (
        <div>
          <img
            alt=""
            style={{ height: "80px", width: "80px", borderRadius: "40%" }}
            src={storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
          ></img>
        </div>
      ),
    },
    { field: 'catgName', headerName: 'Category', width: 170 },
    {
      field: 'name', headerName: 'Dish Name', width: 170
    },
    {
      field: 'foodType', headerName: 'Food Type', width: 120,
      renderCell: (val) => {
        return (getFoodType(val.row.foodType));
      }
    },
    {
      field: 'itemStatusStr', headerName: 'Status', width: 120, valueGetter: (params) => params.row.itemStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 90,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              if (isRoleAllowed(INVENTORYUPDATEPRICING)) {
                handleCellClick(event, cellValues, false);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                  handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                });
              }

            }}
          >
          </EditIcon>
        );
      }
    }
  ];

  const columnsWithStock = [
    {
      field: "productImage",
      headerName: "Image",
      width: 90,
      renderCell: (rowData) => (
        <div>
          <img
            alt=""
            style={{ height: "80px", width: "80px", borderRadius: "40%" }}
            src={storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
          ></img>
        </div>
      ),
    },
    { field: 'catgName', headerName: 'Category', width: 170 },
    {
      field: 'name', headerName: 'Dish Name', width: 170
    },
    {
      field: 'itemStatusStr', headerName: 'Status', width: 90, valueGetter: (params) => params.row.itemStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    },
    {
      field: 'foodTypeStr', headerName: 'Food Type', width: 100
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 40,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={(event) => {
              if (isRoleAllowed(INVENTORYUPDATEPRICING)) {
                handleCellClick(event, cellValues, false);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Item Details.Contact the Owner",
                  handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                });
              }

            }}
          >
          </EditIcon>
        );
      }
    },
    { field: 'liveStockCount', headerName: 'Stock Count', width: 100 },
    {
      field: "add",
      headerName: "Add Stock",
      width: 50,
      renderCell: (cellValues) => {
        return (
          <InventoryIcon
            onClick={(event) => {
              if (isRoleAllowed(LIVESTOCKUPDATE)) {
                handleCellClick(event, cellValues, true);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                  handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                });
              }

            }}
          >
          </InventoryIcon>
        );
      }
    },
    {
      field: "link",
      headerName: "Link Stock",
      width: 100,
      renderCell: (e) => {
        return (
          <AddLinkIcon
            onClick={(event) => {
              if (isRoleAllowed(LIVESTOCKUPDATE)) {
                handleGetLinkStock(e.row.id);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                  handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                });
              }

            }}
          >
          </AddLinkIcon>
        );
      }
    },
    {
      field: 'stockVisibleStr', headerName: 'Only Stock', width: 90, valueGetter: (params) => params.row.itemStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    },
  ];



  const handleCellClick = (param, event, lookStock) => {
    printDebugLog("handleCellClick",event.row);
    if (!lookStock) {
      setLoader(true);
      getFoodItemsViaId(event.row.id, lookStock);
    } else {
      setRestoSingleData({
        ...restoSingleData,...event.row,
        prdName:event.row.name
      })
      getStockData(event.row.id);
    }
  };

  const handleSelectSearch = (e, cellData) => {
    printDebugLog("handleSelectSearch", cellData);
    setInputValue('');
    setLoader(true);
    getFoodItemsViaId(cellData.id, false);
  };

  const clearProductData = () => {
    setRateCard([]);
    setSelectedRateCard(INITIAL_RATE_CARD)
    setSelectedcategory(INITIAL_CATEOGORY)
    setRestoSingleData(INITIAL_RESTO_STOCK_DATA);
    setSelectedImage('');
  }

  const handleSubmitResto = async () => {
    if (rateCard.length > 0) {
      setDataLoaded(false);
      let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SET_RESTAURANT_DATA;
      let suggestionData = "";
      if (!isEmpty(suggestedFoodItem))
        suggestionData = suggestedFoodItem.map(item => { return item.foodItemId });

      let filledData = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          catgId: selectedCategory.catgId,
          catgName: selectedCategory.catgName,
          ingredients: restoSingleData.ingredients,
          itemStatus: Number(restoSingleData.itemStatus),
          name: restoSingleData.prdName,
          productImage: restoSingleData.productImage,
          id: restoSingleData.id,
          price: rateCard,
          foodType: parseInt(restoSingleData.foodType),
          spicy: parseInt(restoSingleData.spicy),
          outOfStock: restoSingleData.outOfStock,
          offertype: parseInt(restoSingleData.offertype),
          offerAmt: parseFloat(restoSingleData.offerAmt),
          sgst: parseFloat(restoSingleData.sgst),
          cgst: parseFloat(restoSingleData.cgst),
          shopHash: storeActiveShopHash.id,
          cartVisible: restoSingleData.cartVisible,
          listItemSuggestion: suggestionData,
          delListItemSuggestion: suggestedFoodItemDeleted,
          accountName: restoSingleData.accountName,
          stockVisible: restoSingleData.stockVisible,
        }
      };
      //console.log("handleSubmitResto => ", filledData);
      handleDialogClose();
      snpAxios.post(catgUrl, filledData)
        .then(response => {
          //console.log("responses => ", response.data);
          if (response.data.status === SUCCESS) {
            if (!isEmpty(filledData.jcJson.accountName))
              getAllAccountName();
            getRestoDetails();
          }
        }).catch(error => {
          console.log("error : ", error);
          setDataLoaded(false);
          // window.location.href = "#/error";
        });

      setShowTable(false);
      setSelectedImage('');
    } else {
      setSnpComponent({
        ...snpComponent,
        snackBarOpen: true,
        snackBarMsg: "Please Enter Price to Proceed",
      })
    }

  };
  //const [paginationModel, setPaginationModel] = useState(INITIAL_PAGEMODEL);
  const getRestoDetails = async () => {
    //let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_RESTAURANT_DATA+"/"+pageData.page+"/"+pageData.pageSize;
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_RESTAURANT_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //  console.log("getRestoDetails  => ",data);
    //setDelSuccess(false);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        // console.log("response  => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setRestoData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const getCartDetails = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CATEOGORY_DATA;

    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        //console.log("VSN", response);
        if (response.data.status === SUCCESS) {
          //setCategoryData(response.data.jcJson.value_2);
          let resp = response.data.jcJson.value_2.map((catg) => {
            let catgName = catg.catgName
            let catgId = catg.id
            return { catgName, catgId }
          })
          // console.log("Resut : ", resp);
          setCategoryData(resp);
          //console.log(categoryData);
        }
      }).catch((error) => {
        console.log("getCartDetails ", error);
      });
  };

  const getYoutubeVideo = (videoName, lang) => {
    let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
      + `&name=${videoName}&lang=${lang}`;
    let data = {};
    axios.get(
      cUrl,
      data
    )
      .then((response) => {
        //printLog("getSingleConfigs<=",response.data);
        if (response.data !== '') {
          setVideoModel({ ...videoModel, path: response.data });
        } else
          setVideoModel({ ...videoModel, path: '' });
      }).catch((error) => {
        //printLog("error : ", error);
        //window.location.href = "#/error";
        setVideoModel({ ...videoModel, path: '' });
      });
  };
  useLayoutEffect(() => {
    //  console.log('Printing Login Details : ',CART_INITIAL_STATE);
    getYoutubeVideo(SCREEN_INVENTORY, 'hi');
    getSingleConfigs(ITEMWISEOFFER);
    if (isFeatureAllowed(THEMEMGMT)) {
      getSingleConfigs(ECARTAPP);
    }
    getCartDetails();
    getRestoDetails();
    getSingleConfigs(ITEMMULTIACCOUNT);
    if (isFeatureAllowed(STOCKMGMT)) {
      getSingleConfigs(STOCKPRICINGFEATURE);
      getConversionData();
      if (isFeatureAllowed(SUPMGMT))
        getSupplierDetails();
    }
  }, [storeCartType, storeActiveShopHash, storeApiUrl]);

  const handleFoodItemsSearch = (event) => {
    const value = event.target.value;
    //console.log("handleQuickItemsSearch",value)
    if (value.length >= 3) {
      getAllFoodItem(value);
    }
  };

  const getAllFoodItem = async (regextStr) => {
    if (isEmpty(regextStr))
      return;
    let encodedHash = utilities.encode(storeActiveShopHash.id);
    let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETFOODITEMSUGGESTION + "?s=" + encodedHash + "&q=" + regextStr;
    let data = {};
    axios.get(
      fetchBillUrl,
      data
    )
      .then((response) => {
        printDebugLog("getAllFoodItem", response.data);
        if (response.data.status === SUCCESS) {
          setFoodItemData(response.data.jcJson.listItemSuggestion);
        }
      })
      .catch((error) => {
        printDebugLog("Error : ", error);
      });
  };

  const getSupplierDetails = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_SUPPLIER_FEW_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        if (response.data.status === SUCCESS) {
          setListSupplierData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const getConversionData = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + GET_CONVERSIONTYPE_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        createNew: stockPricingFeature,
      }
    };
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        printDebugLog("Conversion Data", response.data);
        if (response.data.status === SUCCESS) {
          setConversionTypeData(response.data.jcJson.value_2);
          if (!stockPricingFeature) {
            let firstData = response.data.jcJson.value_2[0];
            setConversionSingleData(firstData);
            setStockData({
              ...stockSingleData,
              cnvId: firstData.id,
              unitName: firstData.unitName,
              unitCount: firstData.unitCount,
              unitRestrictions: firstData.unitRestrictions,
              smallUnitName: firstData.smallUnitName,
              smallUnitCount: firstData.smallUnitCount,
            });
            if (firstData.unitRestrictions) {
              setChoiceUnit({
                ...choiceUnit,
                unitChecked: true, smallUnitChecked: false,
              })
            }

          }

        }
      }).catch((error) => {
        console.log("error : ", error);
        //window.location.href = "#/error";
      });
  };

  const getStockData = async (mStockId) => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETRESTOSTOCKENTRY;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        stockId: mStockId,
        limit: 2,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    setLoader(true);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setLoader(false);
        setStockShowDialog(true);
        if (response.data.status === SUCCESS) {
          setListStockData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        setLoader(false);
        setStockShowDialog(true);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const handleSetLinkStock = async () => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + SETLINKOSTOCKENTRY;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        ...linkSingleData,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    setLoader(true);
    handleLinkStockDialogClose();
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setLoader(false);
        if (response.data.status === SUCCESS) {
          setSnpComponent({
            ...snpComponent,
            isLoader: false,
            negativeReq: false,
            positiveReq: true,
            positiveAction: "Ok",
            showDialog: true,
            outSideClickDismiss: true,
            cancelExtra: true,
            requestType: 99,
            subTitle: "Record added successfull.",
            handleSnpSubmit: () => {
              setSnpComponent(INITIAL_SNP_COMPONENT);
              setDataLoaded(false);
              getRestoDetails();
            },
          });
        }
      }).catch((error) => {
        setLoader(false);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };


  const handleGetLinkStock = async (mStockId) => {
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + GETLINKOSTOCKENTRY;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        stockId: mStockId,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    setLoader(true);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setLoader(false);
        if (response.data.status === SUCCESS) {
          setLinkSingleData({
            ...linkSingleData, ...response.data.jcJson,
          });
          if (!isEmpty(response.data.jcJson.linkedToId)) {
            setItemData({
              ...itemData, id: response.data.jcJson.linkedToId,
              name: response.data.jcJson.linkedToName,
            })
          }
          setLinkShowDialog(true);
        }
      }).catch((error) => {
        setLoader(false);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const setStockEntryToServer = async () => {
    if (isEmpty(stockSingleData.cnvId)) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Select Custom Unit", snackBarOpen: true,
      })
      return;
    }
    else if (stockSingleData.type === 2 && isEmpty(stockSingleData.purchasePrice)) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Enter Purchase Price > 0.0", snackBarOpen: true,
      })
      return;
    } else if (stockSingleData.type === 2 && stockSingleData.purchasePrice <= 0.0) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Enter Purchase Price > 0.0", snackBarOpen: true,
      })
      return;
    } else if (stockSingleData.type === 2 && isEmpty(stockSingleData.quantity)) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Enter Quantity > 0", snackBarOpen: true,
      })
      return;
    } else if (stockSingleData.type === 2 && Number(stockSingleData.quantity) <= 0) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Enter Quantity > 0", snackBarOpen: true,
      })
      return;
    }
    let cartUrl = storeApiUrl.BACKEND_BASE_URL + SETRESTOSTOCKENTRY;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        ...stockSingleData,
        mfgDate: formatDateInSnp(stockSingleData.mfgDate, ""),
        expiryDate: formatDateInSnp(stockSingleData.expiryDate, ""),
        stockId: restoSingleData.id,
      }
    };
    //  console.log("getSupplierDetails  => ",data);
    setLoader(true);
    handleStockDialogClose();
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setLoader(false);
        if (response.data.status === SUCCESS) {
          setSnpComponent({
            ...snpComponent,
            isLoader: false,
            negativeReq: false,
            positiveReq: true,
            positiveAction: "Ok",
            showDialog: true,
            outSideClickDismiss: true,
            cancelExtra: true,
            requestType: 99,
            subTitle: "Record added successfull.",
            handleSnpSubmit: () => {
              setSnpComponent(INITIAL_SNP_COMPONENT);
              setDataLoaded(false);
              getRestoDetails();
            },
          });
        }
      }).catch((error) => {
        setLoader(false);
        console.log("errr ", error);
        //window.location.href = "#/error";
      });
  };

  const fetchQuickItems = async (regextStr, mode) => {
    let encodedHash = utilities.encode(storeActiveShopHash.id);
    let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + GETQUICKITEMS + "?s=" + encodedHash + "&q=" + regextStr + "&m=" + mode;
    let data = {};
    axios.get(
      fetchBillUrl,
      data
    )
      .then((response) => {
        //console.log("fetchBill   => ", response.data);
        if (response.data.status === SUCCESS) {
          setListFoodItem(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        console.log("errr ", error);
      });
  };


  const renderDialogContent = () => {
    return (
      <React.Fragment>
        <div>
          <Grid container spacing={1} justifyContent="center" alignItems="center" >
            {
              restoSingleData.productImage.length > 0 && restoSingleData.productImage.map((item, index) => (
                <Card orientation="horizontal" size="md" key={"Image_" + index} variant="outlined">
                  <img
                    style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                    srcSet={`${storeApiUrl.BACKEND_BASE_URL + "files/" + item}`}
                    src={`${storeApiUrl.BACKEND_BASE_URL + "files/" + item}`}
                    alt={"Image_" + index}
                  />
                  {
                    restoSingleData.productImage.length > 1 && <DeleteIcon
                      onClick={() => handleDeleteImage(index)} />
                  }

                </Card>

              ))
            }

            <Grid item md={6} sm={12} xs={12} lg={6} >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  accept="image/*"
                  id="imgPath"
                  name="imgPath"
                  label=""
                  type="file"
                  placeholder="Restaurant Image(Image should be less than 5Mb.)"
                  onChange={handleInputChange}
                  style={{ border: "1px solid lightgray", marginRight: "2px", padding: "3px 1px" }}
                />
                {
                  selectedImage &&
                  <img
                    alt=""
                    style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                    // src={storeApiUrl.BACKEND_BASE_URL + "files/" + imgv}
                    src={selectedImage}
                  />
                }
              </div>
            </Grid>



            {imageSelectedError &&
              <p className="error" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Please select the Image (image format) having size below 5Mb before uploading.</p>
            }
            <Grid item md={12} sm={12} xs={12} lg={12} >

              <FormLabel component="legend">Select Food Type :</FormLabel>
              <RadioGroup row aria-label="foodType" name="foodType" value={restoSingleData.foodType.toString()} onChange={handleInputChange} >
                <FormControlLabel value={FoodType.VEG.toString()} control={<Radio />} label="Veg" />
                <FormControlLabel value={FoodType.NON_VEG.toString()} control={<Radio />} label="Non Veg" />
                <FormControlLabel value={FoodType.EGG.toString()} control={<Radio />} label="Egg" />
                <FormControlLabel value={FoodType.DRINK.toString()} control={<Radio />} label="Drink" />
                <FormControlLabel value={FoodType.ICE_CREAM.toString()} control={<Radio />} label="Ice Cream" />
                <FormControlLabel value={FoodType.SWEEETS.toString()} control={<Radio />} label="Sweets" />
                <FormControlLabel value={FoodType.NONE.toString()} control={<Radio />} label="Other" />
              </RadioGroup>
            </Grid>
            {
              dialogLoader && <Grid item xs={12}>
                <center>
                  <Loader /></center>
              </Grid>
            }

            <Grid item md={6} sm={12} xs={12} lg={6} >
              <Autocomplete
                id="catgselect"
                variant="filled"
                size="small"
                disabled={isEditProduct}
                options={categoryData}
                onChange={handleSelectChange}
                isOptionEqualToValue={(option, value) => option.catgId === value.catgId}
                getOptionLabel={(option) => option.catgName}
                defaultValue={selectedCategory}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.catgName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a category"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    style={{ width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12} lg={6}>
              <TextField
                required
                id="prdName"
                variant="filled"
                size="small"
                label="Enter Dish Name"
                name="prdName"
                defaultValue={restoSingleData.prdName}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TextField
                required
                id="ingredients"
                variant="filled"
                size="small"
                label="Enter Ingredients(Comma Separated)"
                name="ingredients"
                defaultValue={restoSingleData.ingredients}
                onChange={handleInputChange}
                multiline={true}
                rows={3}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <FormLabel component="legend">Select Spice Level :</FormLabel>
              <RadioGroup row aria-label="spicy" name="spicy" value={restoSingleData.spicy.toString()} onChange={handleInputChange} >
                <FormControlLabel value={Spicy.LESS_SPICY.toString()} control={<Radio />} style={{ marginLeft: "0", marginRight: "0" }} label={<> Mild Spice <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /> </>} />
                <FormControlLabel value={Spicy.MEDIUM_SPICY.toString()} control={<Radio />} style={{ marginLeft: "0", marginRight: "0" }} label={<>Medium Spice <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /> <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /></>} />
                <FormControlLabel value={Spicy.EXTRA_SPICY.toString()} control={<Radio />} style={{ marginLeft: "0", marginRight: "0" }} label={<>Hot Spice <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel3Logo} alt="Hot Spice" /> </>} />
                <FormControlLabel value={Spicy.NONE.toString()} control={<Radio />} style={{ marginLeft: "0", marginRight: "0" }} label="None" />
              </RadioGroup>
              <br />
            </Grid>
          </Grid>
          <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>**Add Different Prices for your Dish(Eg: Half,Full,Regular)**</p>
          <Grid container spacing={1}
            style={{ border: "1px solid black", padding: "30px 10px" }}>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TextField
                id="name"
                label="Food Quantity Name"
                variant="filled"
                size="small"
                name="name"
                value={selectedRateCard.name}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12} lg={6}>
              <TextField
                id="cAmt"
                variant="filled"
                size="small"
                label="Cost Price(Without GST)"
                name="cAmt"
                value={selectedRateCard.cAmt}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12} lg={6}>
              <TextField
                id="sAmt"
                variant="filled"
                size="small"
                label="Selling Price(Without GST)"
                name="sAmt"
                value={selectedRateCard.sAmt}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12} className="rate_card__button--container">
              {selectedRateCard.index === -1 &&
                <Button variant="filled" color="primary" onClick={handleSubmitRate} className="rate_card--button" >
                  Add Rate Card
                </Button>
              }
              {selectedRateCard.index >= 0 && <Button variant="filled" color="primary" className="rate_card--button" onClick={handleEditRate}>
                Update rate card
              </Button>}
              {
                selectedRateCard.index >= 0 && <Button variant="filled" color="bRed" onClick={handleDeleteRateCard}>
                  Delete
                </Button>
              }
            </Grid>
          </Grid>

          {showTable &&
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" className="rate_card">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Food Quantity</TableCell>
                      <TableCell align="center">Cost Price</TableCell>
                      <TableCell align="center">Selling Price</TableCell>
                      <TableCell align="center">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(rateCard).map((val, index) => {
                      return (
                        <TableRow
                          key={index}
                          className="rate_card--row"
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="center">{val.name}</TableCell>
                          <TableCell align="center">{val.cAmt}</TableCell>
                          <TableCell align="center">{val.sAmt}</TableCell>
                          <TableCell align="center"><EditIcon button onClick={() => handleSelectListChange(val, index)} /></TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer></Grid>
          }
          <br />

          <Grid container spacing={1}>
            {
              itemWiseOffer && <Grid item md={12} sm={12} xs={12} lg={12}>
                <FormLabel component="legend">Any Offer :</FormLabel>
                <RadioGroup row aria-label="offertype" name="offertype" value={restoSingleData.offertype.toString()} onChange={handleInputChange}>
                  <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                  <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                  <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                </RadioGroup>
              </Grid>
            }

            {
              (itemWiseOffer && restoSingleData.offertype > 1) &&
              <Grid item md={6} sm={6} xs={12} lg={6}>
                <TextField
                  required
                  id="offerAmt"
                  name="offerAmt"
                  variant="filled"
                  size="small"
                  label="Enter Offer Value"
                  value={restoSingleData.offerAmt.toString()}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                /> </Grid>
            }

            <Grid item md={6} sm={6} xs={12} lg={6}>
              <TextField style={{ width: "100%" }}
                disabled
                id="sgst"
                name="sgst"
                variant="filled"
                size="small"
                label="SGST(In %)"
                value={restoSingleData.sgst}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12} lg={6}>
              <TextField style={{ width: "100%" }}
                disabled
                id="cgst"
                name="cgst"
                variant="filled"
                size="small"
                label="CGST(In %)"
                value={restoSingleData.cgst}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Enter Item Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="itemStatus"
                  name="itemStatus"
                  value={Number(restoSingleData.itemStatus)}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value={Number(ItemStatus.ACTIVE)}
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value={Number(ItemStatus.INACTIVE)}
                    control={<Radio />}
                    label="InActive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {
              ecartApp && <Grid item xs={12} md={6} sm={6} lg={6}>
                <Autocomplete
                  multiple
                  disabled={(restoSingleData.itemStatus === ItemStatus.ACTIVE) ? false : true}
                  value={suggestedFoodItem}
                  options={foodItemData}
                  limitTags={2}
                  sx={{ width: 300 }}
                  onBlur={(e) => setFoodItemData([])}
                  onInputChange={handleItemSearchChange}
                  inputValue={inputValue}
                  isOptionEqualToValue={(option, value) => option.foodItemId === value.foodItemId}
                  getOptionLabel={(option) => option.foodItemName}
                  onChange={(event, newValue, reason, detail) => {
                    printDebugLog("Autocomplete", newValue, reason);
                    setInputValue('');
                    if (suggestionAvail) {
                      if (reason === 'removeOption') {
                        const updatedItems = [...suggestedFoodItemDeleted, detail.option.foodItemId];
                        setSuggestedFoodItemDeleted(updatedItems);
                      } else if (reason === 'selectOption') {
                        if (suggestedFoodItemDeleted.length > 0) {
                          let idToDelete = detail.option.foodItemId;
                          const indexToDelete = suggestedFoodItemDeleted.findIndex(item => item === idToDelete);
                          // If item is found, create a new array without it
                          if (indexToDelete !== -1) {
                            const updatedItems = [...suggestedFoodItemDeleted.slice(0, indexToDelete), ...suggestedFoodItemDeleted.slice(indexToDelete + 1)];
                            setSuggestedFoodItemDeleted(updatedItems);
                          }
                        }
                      } else if (reason === 'clear') {
                        suggestedFoodItem.forEach(element => {
                          const updatedItems = [...suggestedFoodItemDeleted, element.foodItemId];
                          setSuggestedFoodItemDeleted(updatedItems);
                        });
                      }
                    }
                    setSuggestedFoodItem(newValue);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.foodItemId}>
                      {option.foodItemName}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Item Suggestion"
                      placeholder="Search"
                      onChange={handleFoodItemsSearch}
                    />
                  )}
                />
              </Grid>
            }
            {
              ecartApp && <Grid item md={12} xs={12} sm={12} lg={12} style={{ display: "flex", justifyContent: "center", color: "#01333e" }}>
                <center>
                  <FormControlLabel name="cartVisible"
                    checked={restoSingleData.cartVisible} disabled={(restoSingleData.itemStatus === ItemStatus.ACTIVE) ? false : true} control={<Checkbox />} onChange={() => {
                      let pVal = !restoSingleData.cartVisible;
                      setRestoSingleData({
                        ...restoSingleData,
                        cartVisible: pVal,
                      })
                    }} label="Item visible to end customer" />
                </center>
              </Grid>
            }
            {
              isFeatureAllowed(RECPMGMT) &&
              <Grid item md={12} xs={12} sm={12} lg={12} style={{ display: "flex", justifyContent: "center", color: "#01333e" }}>
                <center>
                  <FormControlLabel name="stockVisible"
                    checked={restoSingleData.stockVisible} disabled={(restoSingleData.itemStatus === ItemStatus.ACTIVE) ? false : true} control={<Checkbox />} onChange={() => {
                      let pVal = !restoSingleData.stockVisible;
                      setRestoSingleData({
                        ...restoSingleData,
                        stockVisible: pVal,
                      })
                    }} label="Item visible to Billing" />
                </center>
              </Grid>
            }

            {ecartApp &&
              <Grid item md={12} sm={12} xs={12} lg={12} style={{ display: "flex", justifyContent: "center", color: "#01333e" }}>
                <FormControlLabel control={<Checkbox
                  variant="filled"
                  size="small"
                  checked={restoSingleData.outOfStock}
                  onChange={handleInputChange} id="outOfStock" />}
                  name="outOfStock"
                  label={<> <strong>Out Of Stock </strong> </>} />
              </Grid>
            }
            {
              itemMultiAcc &&
              <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>**Note:This balance type used to separate this item into different account.Remember any balance type selection will not work in Home Delivery</p>}
            {
              itemMultiAcc && <Grid item md={6} xs={12} lg={6} sm={6}>
                <Autocomplete
                  id="bal-select"
                  options={listAcc}
                  autoHighlight
                  variant="filled"
                  size="small"
                  onChange={(e, val) => {
                    if (val != null) {
                      if (val === 'Other') {
                        setRestoSingleData({
                          ...restoSingleData,
                          accountName: "",
                          accountTitle: val,
                        })
                      } else {
                        setRestoSingleData({
                          ...restoSingleData,
                          accountName: val,
                          accountTitle: val,
                        })
                      }

                    } else {
                      setRestoSingleData({
                        ...restoSingleData,
                        accountName: "",
                        accountTitle: "",
                      })
                    }
                  }
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  value={restoSingleData.accountTitle}
                  defaultValue={restoSingleData.accountTitle}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Balance Type"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            }

            {
              restoSingleData.accountTitle === "Other" &&
              <Grid item md={6} xs={12} lg={6} sm={6}>
                <TextField
                  id="accountName"
                  name="accountName"
                  variant="filled"
                  size="small"
                  label="Enter Other Balance Type Name"
                  value={restoSingleData.accountName}
                  onChange={(e) => setRestoSingleData({
                    ...restoSingleData,
                    accountName: e.target.value,
                  })}
                />
              </Grid>
            }

          </Grid>
        </div>
      </React.Fragment >
    );
  }

  const renderDialogAction = () => {
    return (
      <React.Fragment>
        <SnpButton variant="contained" color="primary" onClick={handleImageUpload}>
          {!isEditProduct ? "Add" : "Update"}
        </SnpButton>
        <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
          Dismiss
        </DismissButton>
      </React.Fragment>
    );
  }

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setShowDialog(false);
    clearProductData();
    setEditProduct(false);
    setImageSelectedError(false);
    setShowTable(false);
    setSuggestedFoodItem([]);
    setSuggAvail(false);
    setSuggestedFoodItemDeleted([]);
    
  };

  const handleStockDialogClose = () => {
    // console.log("handleStockDialogClose");
    setStockShowDialog(false);
    setEditProduct(false);
    clearProductData();
    setListStockData([]);
    setSupplierData(INITIAL_SUPPLIER);
    if (stockPricingFeature) {
      setStockData(INITIAL_STOCK_DETAILS);
      setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
      setChoiceUnit({
        ...choiceUnit, smallUnitChecked: false, unitChecked: true,
      })
    } else {
      setStockData({
        ...stockSingleData,
        id: "",
        stockId: "", //Product Id
        stockName: "",
        supplierId: "",
        supplierName: "",
        billNo: "",
        mfgDate: "",
        expiryDate: "",
        sgst: 0.0,
        cgst: 0.0,
        custGstDisabled: false,
        fullPrice: 0.0,
        price: 0.0,
        liveStockCountStr: "",
        quantity: 0,
        smallQuantity: 0,
        taxMethod: false,
        productmrPrice: 0.0,
        offerDenom: OfferType.No_Offer,
        offerAmt: 0.0,
        productSellingPrice: 0.0,
        productWholeSalePrice: 0.0,
        sproductmrPrice: 0.0,
        purchasePrice: 0.0,
        sofferDenom: OfferType.No_Offer,
        sofferAmt: 0.0,
        sproductSellingPrice: 0.0,
        sproductWholeSalePrice: 0.0,
        buyerGstTaken: true,
        isExpired: false,
        createdAt: "",
      })
    }
  };


  const handleLinkStockDialogClose = () => {
    // console.log("handleStockDialogClose");
    setLinkShowDialog(false);
    setItemData(INITIAL_ITEM_DATA)
    setLinkSingleData(INITIAL_LINK_STOCK);
  };

  const renderLinkStockDialogContent = () => {
    return (
      <>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <TextField
              required
              id="stockName"
              name="stockName"
              disabled
              variant="filled"
              size="small"
              label="Item Name"
              value={linkSingleData.stockName}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <Autocomplete
              required
              id="item-select"
              options={restoData}
              autoHighlight
              variant="filled"
              size="small"
              onChange={(event, val) => {
                if (val != null) {
                  setItemData({ ...itemData, id: val.id, name: val.name });
                  setLinkSingleData({ ...linkSingleData, linkedToId: val.id, linkedToName: val.name });
                } else {
                  setItemData(null);
                  setLinkSingleData({ ...linkSingleData, linkedToId: "", linkedToName: "" });
                }
              }}
              fullWidth
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              defaultValue={itemData}
              renderOption={(props, option) => (
                <li {...props} key={`${option.id}`}>
                  {
                    linkSingleData.stockId !== option.id &&
                    option.name
                  }
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Item For Link"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6} sm={6} xs={12}>
            <TextField
              id="smallQuantity"
              name="smallQuantity"
              variant="filled"
              size="small"
              type="number"
              label="Enter Quantity to be reduced"
              value={linkSingleData.smallQuantity}
              onChange={(e) => {
                let val = e.target.value;
                if (!isNumberEmpty(val)) {
                  setLinkSingleData({
                    ...linkSingleData, smallQuantity: e.target.value,
                  })
                } else {
                  setLinkSingleData({
                    ...linkSingleData, smallQuantity: "",
                  })
                }
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderLinkStockDialogAction = () => {
    return (
      <>
        <SnpButton variant="contained" color="primary" onClick={handleSetLinkStock}>
          Update
        </SnpButton>

        <DismissButton variant="contained" color="primary" onClick={handleLinkStockDialogClose}>
          Dismiss
        </DismissButton>
      </>
    );
  }

  const renderStockDialogContent = () => {
    return (
      <>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          {
            stockSingleData.isExpired && <Grid item xs={12}>
              <FormControlLabel disabled control={<Checkbox checked={stockSingleData.isExpired}
                id="isExpired" />} name="isExpired" label="Is Expired" />
            </Grid>
          }

          <Grid item xs={12} md={12} lg={12} sm={12} >
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Select Stock Type
              </FormLabel>
              <RadioGroup
                row
                aria-label="type"
                name="type"
                disabled={stockSingleData.systemGenerated}
                value={stockSingleData.type}
                onChange={(e) => {
                  setStockData({
                    ...stockSingleData,
                    type: Number(e.target.value),
                  })
                }}
              >
                <FormControlLabel
                  value={1}
                  disabled={stockSingleData.systemGenerated}
                  control={<Radio />}
                  label="Reduce"
                />
                <FormControlLabel
                  value={2}
                  disabled={stockSingleData.systemGenerated}
                  control={<Radio />}
                  label="Add"
                />
              </RadioGroup>
            </FormControl>
          </Grid>


          <Grid item xs={12} md={6} lg={6} sm={6}>
            <TextField
              required
              id="stockName"
              name="stockName"
              disabled
              variant="filled"
              size="small"
              label="Item Name"
              value={restoSingleData.prdName}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6} sm={6}>
            <TextField
              required
              id="billNo"
              name="billNo"
              variant="filled"
              size="small"
              disabled={stockSingleData.systemGenerated}
              label="Enter Bill Number"
              value={stockSingleData.billNo}
              onChange={(e) => setStockData({
                ...stockSingleData, billNo: e.target.value,
              })}
              fullWidth
            />
          </Grid>
          {
            stockSingleData.type === 2/*Credit*/ && <Grid item xs={12} md={6} lg={6} sm={6}>
              <TextField
                id="mfgDate"
                name="mfgDate"
                variant="filled"
                size="small"
                disabled={stockSingleData.systemGenerated}
                label="Enter Manufacture Date"
                value={formatDateInReact(stockSingleData.mfgDate)}
                onChange={(e) => setStockData({
                  ...stockSingleData, mfgDate: e.target.value,
                })}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }} />
            </Grid>
          }
          {
            stockSingleData.type === 2/*Credit*/ &&
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <TextField
                id="expiryDate"
                name="expiryDate"
                variant="filled"
                size="small"
                disabled={stockSingleData.systemGenerated}
                label="Enter Expiry Date"
                value={formatDateInReact(stockSingleData.expiryDate)}
                onChange={(e) => setStockData({
                  ...stockSingleData, expiryDate: e.target.value,
                })}
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }} />
            </Grid>
          }

          {
            (stockSingleData.type === 2/*Credit*/ && isFeatureAllowed(SUPMGMT)) &&
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <Autocomplete
                required
                id="supplier-select"
                options={listSupplierData}
                autoHighlight
                variant="filled"
                disabled={stockSingleData.systemGenerated}
                size="small"
                onChange={handleSupplierChange}
                fullWidth
                isOptionEqualToValue={(option, value) => option.supplierId === value.supplierId}
                getOptionLabel={(option) => option.supplierName}
                defaultValue={supplierData}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.supplierName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Supplier Information"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
          }

          <Grid item md={6} lg={6} sm={6} xs={12}>
            < Autocomplete
              id="cnv-select"
              size="small"
              options={conversionTypeData}
              disabled={stockSingleData.systemGenerated}
              onChange={handleConversionChange}
              fullWidth
              getOptionLabel={(option) => getCustomUnitDetails(option)}
              defaultValue={conversionSingleData}
              renderOption={(props, option) => (
                <Box
                  component="li"

                  {...props}
                >
                  {getCustomUnitDetails(option)}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Custom Unit Type"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
          {
            stockPricingFeature && !isEmpty(stockSingleData.cnvId) && <Grid item xs={12} md={12} lg={12} sm={12} >
              <FormControl component="fieldset" >
                <FormLabel component="legend">{"Select Quantity: " + getUnitDetails()}</FormLabel>
                <FormControlLabel disabled={stockSingleData.systemGenerated} control={<Checkbox checked={choiceUnit.unitChecked}
                  onChange={handleInputChange} id="unitChecked" />} name="unitChecked" label={stockSingleData.unitName} />
                {
                  stockSingleData.smallUnitCount > 0 && <FormControlLabel disabled={stockSingleData.unitRestrictions} control={<Checkbox checked={stockSingleData.smallUnitChecked}
                    onChange={handleInputChange} id="smallUnitChecked" />} name="smallUnitChecked" label={stockSingleData.smallUnitName} />
                }
              </FormControl>
            </Grid>
          }

          {
            !isEmpty(stockSingleData.cnvId) && choiceUnit.unitChecked &&
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <TextField
                id="quantity"
                name="quantity"
                variant="filled"
                size="small"
                disabled={stockSingleData.systemGenerated}
                label={"Enter Quantity(for " + stockSingleData.unitName + ")"}
                value={stockSingleData.quantity}
                onChange={(e) => {
                  let val = e.target.value;
                  if (!isNumberEmpty(val)) {
                    setStockData({
                      ...stockSingleData, quantity: val,
                    })
                  } else {
                    setStockData({
                      ...stockSingleData, quantity: "",
                    })
                  }
                }}
                fullWidth
              />
            </Grid>
          }
          {
            !isEmpty(stockSingleData.cnvId) && choiceUnit.smallUnitChecked &&
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <TextField
                id="smallQuantity"
                name="smallQuantity"
                variant="filled"
                size="small"
                disabled={stockSingleData.systemGenerated}
                label={"Enter Quantity(for " + stockSingleData.smallUnitName + ")"}
                value={stockSingleData.smallQuantity}
                onChange={(e) => {
                  let val = e.target.value;
                  if (!isNumberEmpty(val)) {
                    setStockData({
                      ...stockSingleData, smallQuantity: Number(e.target.value),
                    })
                  } else {
                    setStockData({
                      ...stockSingleData, smallQuantity: "",
                    })
                  }
                }}
                fullWidth
              />
            </Grid>
          }
          {stockSingleData.type === 2/*Credit*/ &&
            <Grid item md={6} lg={6} sm={6} xs={12}>
              <TextField
                required
                id="purchasePrice"
                name="purchasePrice"
                variant="filled"
                size="small"
                disabled={stockSingleData.systemGenerated}
                label="Enter Purchase Price"
                value={stockSingleData.purchasePrice}
                onChange={(e) => {
                  let val = e.target.value;
                  if (!isNumberEmpty(val)) {
                    setStockData({
                      ...stockSingleData, purchasePrice: Number(e.target.value),
                    })
                  } else {
                    setStockData({
                      ...stockSingleData, purchasePrice: "",
                    })
                  }
                }}
                fullWidth
              />
            </Grid>
          }
          <Grid item md={6} lg={6} sm={6} xs={12}>
            <TextField
              id="description"
              variant="filled"
              size="small"
              label="Enter Description"
              name="description"
              disabled={stockSingleData.systemGenerated}
              defaultValue={stockSingleData.description}
              onChange={(e) => setStockData({
                ...stockSingleData, description: e.target.value,
              })}
              multiline={true}
              rows={3}
              fullWidth
            />
          </Grid>
          {listStockData.length > 0 &&
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" className="rate_card">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">Bill No</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Purchase Price</TableCell>
                      <TableCell align="center">At</TableCell>
                      <TableCell align="center">Edit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listStockData.map((val, index) => {
                      return (
                        <TableRow
                          className="rate_card--row"
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell align="center">{val.typeStr}</TableCell>
                          <TableCell align="center">{val.billNo}</TableCell>
                          <TableCell align="center">{getQuantityDetails(val)}</TableCell>
                          <TableCell align="center">{"₹" + val.purchasePrice}</TableCell>
                          <TableCell align="center">{val.createdAt}</TableCell>
                          <TableCell align="center"><EditIcon button onClick={() => handleSelectStock(val)} /></TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer></Grid>
          }
          {
            listStockData.length > 0 && <Grid item md={6} lg={6} sm={6} xs={12}>
              <center><SnpButton variant="contained" mbgcolor="#009ac7" 
                    onClick={() => navigate("/dashboard/stockentry", { state: { prdId: restoSingleData.id } })}>
                See All Stock
              </SnpButton></center>
            </Grid>
          }

        </Grid >
      </>
    )
  };

  const renderStockDialogAction = () => {
    return (
      <>
        {
          isEditProduct && <SnpButton variant="contained" color="primary" onClick={(e) => {
            setEditProduct(false);
            setStockData({
              ...stockSingleData,
              id: "",
              stockId: "", //Product Id
              stockName: "",
              supplierId: "",
              supplierName: "",
              billNo: "",
              mfgDate: "",
              expiryDate: "",
              quantity: 0,
              sgst: 0.0,
              cgst: 0.0,
              custGstDisabled: false,
              fullPrice: 0.0,
              price: 0.0,
              liveStockCountStr: "",
              smallQuantity: 0,
              taxMethod: false,
              productmrPrice: 0.0,
              offerDenom: OfferType.No_Offer,
              offerAmt: 0.0,
              productSellingPrice: 0.0,
              productWholeSalePrice: 0.0,
              sproductmrPrice: 0.0,
              purchasePrice: 0.0,
              sofferDenom: OfferType.No_Offer,
              sofferAmt: 0.0,
              sproductSellingPrice: 0.0,
              sproductWholeSalePrice: 0.0,
              buyerGstTaken: true,
              isExpired: false,
              type: 2,
              systemGenerated: false,
              description: "",
            })
          }}>
            Create New
          </SnpButton>
        }
        {
          !stockSingleData.systemGenerated && <SnpButton variant="contained" color="primary" onClick={setStockEntryToServer}>
            Update
          </SnpButton>
        }

        <DismissButton variant="contained" color="primary" onClick={handleStockDialogClose}>
          Dismiss
        </DismissButton>
      </>
    );
  }

  const handleQuickItemsSearch = (event, mode) => {
    const value = event.target.value;
    //console.log("handleQuickItemsSearch",value)
    if (value.length >= 3) {
      fetchQuickItems(value, mode);
    }
  };

  const renderSnpContent = () => {
    return (
      <Grid container spacing={1}
        alignItems="center"
        justifyContent="center">
        <Grid item md={5} xs={12} sm={6}>
          <Box sx={{ backgroundColor: 'white' }}>
            <Autocomplete id="quick-select"
              options={listFoodItems}
              autoHighlight
              variant="filled"
              fullWidth
              onChange={handleSelectSearch}
              onInputChange={handleItemSearchChange}
              onBlur={(e) => setListFoodItem([])}
              getOptionLabel={(option) => option.details}
              inputValue={inputValue}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                >
                  {option.details}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Dish Name"
                  onChange={(e) => handleQuickItemsSearch(e, 3)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            /></Box>
        </Grid>
        <Grid item md={5} xs={12} sm={6} >
          <center>
            <Button variant="contained" color="primary" style={{ float: 'right' }} className="float-right"
              onClick={(event) => {
                if (isRoleAllowed(INVENTORYCREATE)) {
                  setShowDialog(true);
                } else {
                  setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: false,
                    positiveReq: true,
                    positiveAction: "Ok Got it",
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                    handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                  });
                }
              }}>
              Add New Restaurant Data
            </Button>
          </center>
        </Grid>
        < DialogBox
          showDialog={showDialog}
          title={isEditProduct ? "Update Restaurant Data" : "Add New Restaurant Data"}
          onClose={handleDialogClose}
          dialogContent={renderDialogContent()}
          dialogActions={renderDialogAction()}
        />
        < DialogBox
          showDialog={showStockDialog}
          title={isEditProduct ? "Update Stock Details" : "Add new Stock entry"}
          onClose={handleStockDialogClose}
          dialogContent={renderStockDialogContent()}
          dialogActions={renderStockDialogAction()}
        />
        < DialogBox
          showDialog={linkStockDialog}
          title="Link Stock"
          onClose={handleLinkStockDialogClose}
          dialogContent={renderLinkStockDialogContent()}
          dialogActions={renderLinkStockDialogAction()}
        />
        <CustomDataGrid
          getRowId={row => row.id}
          rows={restoData}
          columns={isFeatureAllowed(STOCKMGMT) ? columnsWithStock : columns}
          loading={!isDataLoaded}
          paginationMode="client"
          paginationModel={paginationModel}
          onPageDataChange={(e) => setPaginationModel({
            ...paginationModel, page: e,
          })}
          cbPageSizeChange={(mPageSize) => setPaginationModel({
            ...paginationModel, pageSize: mPageSize,
          })}
        />
        {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({ ...videoModel, show: false })} />}
        {videoModel.path && <SingleFab onClick={(e) => setVideoModel({ ...videoModel, show: true })} />}
      </Grid>
    )
  }


  return (
    <SnpComponent showDialog={snpComponent.showDialog}
      title={snpComponent.title}
      isLoader={isLoader}
      subTitle={snpComponent.subTitle}
      negativeAction={snpComponent.negativeAction}
      positiveReq={snpComponent.positiveReq}
      positiveAction={snpComponent.positiveAction}
      negativeReq={snpComponent.negativeReq}
      handleDialogClose={(e) => setSnpComponent(INITIAL_SNP_COMPONENT)}
      iconReq={snpComponent.iconReq}
      content={renderSnpContent()}
      onSnackClose={handleSnackClose}
      cancelExtra={snpComponent.cancelExtra}
      onCancel={snpComponent.handleSnpCancel}
      snackBarOpen={snpComponent.snackBarOpen}
      snackBarMsg={snpComponent.snackBarMsg}
      outSideClickDismiss={snpComponent.outSideClickDismiss}
      handleSubmit={snpComponent.handleSnpSubmit}
      gui={snpComponent.gui}
    />
  );
}


