import React, { useState } from "react";
import { INITIAL_DEL_ITEM } from "./RestBillConstants";
import { TextField } from "@mui/material";
import SnpButton from "../../global/SnpButton";
import DismissButton from "../../global/DismissButton";
import DialogBox from "../../global/DialogBox";

const BillItemDelete = ({ showDeleteDialog, setShowDeleteDialog, productId }) => {
  const [deleteItemData, setDeleteItemData] = useState({});
  const [deleteReason, setDeleteReason] = useState("")
   // const deleteFromBill = async (productId) => {
  //   let error = false;
  //   if (delReason && isEmpty(deleteItemData.delReason)) {
  //     error = true;
  //     setSnpComponent({
  //       ...snpComponent,
  //       snackBarMsg: "Enter Reason for delete",
  //       snackBarOpen: true,
  //     });
  //   }
  //   // what is billNo and value 2
  //   if (!error && !isEmpty(listbillingData.billNo)) {
  //     var array = [...listbillingData.value_2];
  //     var index = array.findIndex(
  //       (singleValue) => singleValue.prdId === productId
  //     );
  //     if (index !== -1) {
  //       //printLog("handleCellClick b", index, array);

  //       array.splice(index, 1);
  //       //printLog("handleCellClick ", listbillingData.value_2);
  //     }
  //     let deleteUrl = storeApiUrl.BACKEND_BASE_URL + DEL_ITEM_BILL;
  //     let data = {
  //       jcJson: {
  //         value: CART_INITIAL_STATE.apiToken,
  //         value_1: mPrdId,
  //         shopHash: storeActiveShopHash.id,
  //         value_2: listbillingData.billNo,
  //         delMsg: delItemDialog.delReason,
  //       },
  //     };
  //     setSnpLoader(true);
  //     setDeleteItemData(INITIAL_DEL_ITEM);
  //     snpAxios
  //       .post(deleteUrl, data)
  //       .then((response) => {
  //         //printLog("response  => ", response.data.jcJson);
  //         //setSnpLoader(false);
  //         // if (response.data.status === SUCCESS) {
  //         //     fetchBill(listbillingData.billNo,array);
  //         // }
  //         //fetchBill(listbillingData.billNo, array);
  //       })
  //       .catch((error) => {
  //         //setSnpLoader(false);
  //         //printLog("errr ", error);
  //         //window.location.href = "#/error";
  //       });
  //   }
  // };
  const renderDeleteItemContent = () => {
    return (
      <>
        <p
          style={{
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            fontSize: "16px",
          }}
        >
          {"Do you want to delete " + deleteItemData.prdName}
        </p>
        <TextField
          id="delReason"
          required
          name="delReason"
          variant="filled"
          size="small"
          label={"Enter Reason For Delete"}
          defaultValue={deleteItemData.delReason}
          onChange={(e) =>
            setDeleteItemData({
              ...deleteItemData,
              delReason: e.target.value, 
            })
          }
          style={{ width: "100%" }}
        />
      </>
    );
  };

  const renderrDeleteItemActions = () => {
    return (
      <>
        <SnpButton
          variant="contained"
          color="primary"
         // onClick={() => deleteFromBill(productId)}
        >
          Delete
        </SnpButton>
        <DismissButton
          variant="contained"
          color="primary"
          // onClick={() => {
          //   setDeleteItemData(INITIAL_DEL_ITEM);
          //   setShowDialog(false);
          // }}
        >
          Dismiss
        </DismissButton>
      </>
    );
  };

  return (
    <div>
      <DialogBox
        showDialog={showDeleteDialog}
        title="Delete Item"
        dialogContent={renderDeleteItemContent()}
        dialogActions={renderrDeleteItemActions()}
      ></DialogBox>
    </div>
  );
};

export default BillItemDelete;
