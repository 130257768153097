import printJS from "print-js";
import { detectBrowser, printDebugLog } from "../../constants/constant";
import { isEmpty } from "lodash";

export const baseGenerateDynamicHTML = (k, s, g,apiToken,shopHash, blNo) => {
    const dynHtml =
        "print://snpnextgen.com/snp/a2z/print?key1=" + apiToken + "&key2=" + shopHash + "&key3=" + blNo + "&k1=" + k + "&s1=" + s + "&g1=" + g;
    window.location.href = dynHtml;
}

const basePrintFallBackMechanism = (printUrl,isMobile) => {
    const browser = detectBrowser();
    printDebugLog("basePrintFallBackMechanism => ", "Mobile: " + isMobile + " ,Browser" + browser);
    if (!isMobile && browser === 'Chrome') {
        printJS(printUrl);
    } else
        window.open(printUrl, '_blank');
};

export const baseRaiseInvoiceClicked = (baseUrl,apiToken,shopHash,rPrint, name,isMobile,billNo, fireSnackBarMsg) => {
    //setCustCopy(false);
    if (!isEmpty(rPrint) && !rPrint.includes('Successfully Print')) {
        const url = baseUrl + "files/" + rPrint;
        //e.preventDefault();
        basePrintFallBackMechanism(url,isMobile);


        // var iframe = document.createElement('iframe');
        // // iframe.id = 'pdfIframe'
        // iframe.className = 'pdfIframe'
        // document.body.appendChild(iframe);
        // iframe.style.display = 'none';
        // iframe.onload = function () {
        //     setTimeout(function () {
        //         iframe.focus();
        //         iframe.contentWindow.print();
        //         URL.revokeObjectURL(url)
        //         // document.body.removeChild(iframe)
        //     }, 1);
        // };
        // iframe.src = url;
    } else if (!isEmpty(rPrint) && rPrint.includes('Successfully Print')) {
        fireSnackBarMsg("Print Initiated...");
        if (name.includes('gen'))
            baseGenerateDynamicHTML(0, 0, 1,apiToken,shopHash, billNo);
        else if (name.includes('kot')) {
            const kotBillNo = name.split("@");
            baseGenerateDynamicHTML(1, 0, 0,apiToken,shopHash, kotBillNo[1]);
        } else if (name.includes('shop'))
            baseGenerateDynamicHTML(0, 1, 0,apiToken,shopHash, billNo);
        else if (name.includes('cust',))
            baseGenerateDynamicHTML(0, 0, 0,apiToken,shopHash, billNo);
    }
    else {
        fireSnackBarMsg("Saved Successfully");
    }
};