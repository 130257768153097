import React, { useState } from "react";
import { SearchOption } from "../../../constants/constant";
import {
    FormControl, FormControlLabel,
    FormLabel, Grid, LinearProgress,
    Paper,
    Radio, RadioGroup, TextField
} from "@mui/material";

const ReturnSearchChoice = ({ loader, searchText, setSearchText, onSearch }) => {
    const optionList = [
        { id: SearchOption.SEARCHBYBARC, optName: "Bar Code" },
        // { id: SearchOption.SEARCHBYCUSTOMBARCODE, optName: "Custom Bar Code" },
        { id: SearchOption.SEARCHBYBATCHN, optName: "Batch Number" }
    ];
    const [defaultId, setDefaultId] = useState(SearchOption.SEARCHBYBARC);

    const handleItemSearchChange = (event) => {
        if (event) {
            setSearchText(event.target.value);
        }
    };

    return (
        <Paper elevation={3}
            autoHeight
            sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#ebd2c7",
                boxShadow: 1,
                borderRadius: 5,
                padding: 1,
                marginTop: 1,
            }} >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Search By</FormLabel>
                        <RadioGroup
                            aria-label="searchBy"
                            name="searchBy"
                            value={defaultId}
                            onChange={(e) => setDefaultId(Number(e.target.value))}
                            row={true}
                        >
                            {optionList.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    value={option.id}
                                    control={<Radio />}
                                    label={option.optName}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    {loader && <LinearProgress />}
                    <TextField
                        required
                        id="inputField"
                        name="inputField"
                        variant="filled"
                        size="small"
                        label={defaultId === SearchOption.SEARCHBYBARC ? "Scan Bar code" : "Enter Batch Number"}
                        fullWidth
                        autoFocus={true} // Ensure the TextField is always focused
                        InputLabelProps={{
                            style: { fontSize: '12px', fontWeight: 'bold' }
                        }}
                        value={searchText}
                        onChange={handleItemSearchChange} // Update `searchText` on input change
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                onSearch(defaultId); // Trigger the search
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ReturnSearchChoice;