import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SnpComponent from "../global/SnpComponent";
import './BillingModule.css';
import MartReturnWasteHeader from "./martreturnwaste/MartReturnWasteHeader";
import {
    BillStatus, CART_INITIAL_STATE, GETBILLBYREGEX, getDeviceType, InternalBillType,
    isEmpty,
    isNumberEmpty, isRoleAllowed, local_test_url, OfferType, PayMedium, printDebugLog, SALESRIGHTS, SearchOption, SUCCESS, twoDecimal
} from "../../constants/constant";
import { useEffect, useMemo, useState } from "react";
import uiUtilInstance from "../../utilities/uiUtil";
import { CircularProgress } from "@mui/material";
import SnpButton from "../global/SnpButton";
import CustomDataGrid from "../global/CustomDataGrid";
import { snpAxios } from "../global/api";
import DialogBox from "../global/DialogBox";
import ReturnDialogContent from "./martreturnwaste/ReturnDialogContent";
import DismissButton from "../global/DismissButton";
import ReturnPaymentInfo from "./martreturnwaste/ReturnPaymentInfo";
import { baseRaiseInvoiceClicked } from "./printBillApi";
import EnterButton from "../global/EnterButton";
import axios from "axios";
import ReturnSearchChoice from "./martreturnwaste/ReturnSearchChoice";
import { getColumnByItemIndex } from "./mart/BillItemAddUseCase";
import WarningIcon from '@mui/icons-material/Warning';

export default function MartReturnWasteBill() {
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const location = useLocation();

    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
        snackVertical: "",
        snackHorizontal: "",
        snackColor: "",
    };

    const INITIAL_PAYMENT_DETAILS = {
        onlinePayMedium: "",
        onlinePay: 0.0,
        cashPay: 0.0,
        // totalItem:0,
        subTotal: 0.0,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        isGstAllowed: true,
        roundOff: 0.0,
        total: 0.0,
        totalBeforeOffer: 0.0,
        offerDenom: OfferType.No_Offer,
        offerAmt: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        totalOfferApplied: 0.0,
        payMedium: PayMedium.NOPAY,
        isOnlinePayEnabled: false,
        isCashPayEnabled: false,
        externalTotalOffer: 0.0,
    };

    const INITIAL_CUSTOMER_DATA = {
        custId: "",
        custMob: "",
        associateId: "",
        custName: "",
        custEmail: "",
        address: "",
        custGstDetails: "",
        custCompanyName: "",
        assosiateAvailable: false,
        useAssociateBenefit: true,
    };

    const INITIAL_ITEM_DETAILS = {
        cnvDetails: "",
        decimalSmRestrictions: false,
        prdId: "",
        hsn: "",
        prdType: "",
        colorId: "",
        colorDetails: "",
        rack_no: "",
    }

    const columns = [
        {
            field: "return",
            headerName: "",
            renderCell: (cellValues) => {
                return (
                    isPaid ? 
                    (isNumberEmpty(cellValues.row.status) || 0 === cellValues.row.status) ? "In Bill" : "Returned" :
                    (
                        <SnpButton variant="contained"
                            mbgcolor="#ca241e"
                            mpadding="1px"
                            onClick={() => {
                                handleReturnClick(cellValues.row);
                            }}
                        >{isNumberEmpty(cellValues.row.status) || cellValues.row.status === 0 ? "Return" : "Add"}</SnpButton>
                    )
                );
            }
        },
        {
            field: 'name', headerName: 'Item', width: 230, valueGetter: (params) => params.row.name, renderCell: (params) => (
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
            ),
        },
        {
            field: 'quantity', headerName: 'Qty. /unit', minWidth: 140, width: 150,
            valueGetter: (params) => params.row.quantity, renderCell: (params) => (
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.row.quantity + " " + params.row.unit}</p>
            ),
        },
        {
            field: 'r.quantity', headerName: 'Returned Qty. /unit', minWidth: 170, width: 170,
            valueGetter: (params) => params.row.removedQuan, renderCell: (params) => (
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', color: "white", fontWeight: "bolder" }}>{!isNumberEmpty(params.row.removedQuan) ? (params.row.removedQuan + " " + params.row.unit) : ""}</p>
            ),
        },
        {
            field: 'price', headerName: 'MRP', width: 100,
            renderCell: (val) => {
                return "\u20B9 " + twoDecimal(val.row.price);
            }
        },
        {
            field: 'offer', headerName: 'Offer', width: 100,
            renderCell: (val) => {
                return (parseInt(val.row.offerDenom) === OfferType.No_Offer) ?
                    0.0 : (parseInt(val.row.offerDenom) === OfferType.Percentage) ? twoDecimal(val.row.offerAmt) + "%" :
                        "Flat" + twoDecimal(val.row.offerAmt);
            }
        },
        {
            field: 'finalPrice', headerName: 'T. Price', width: 100,
            renderCell: (val) => {
                return "\u20B9 " + twoDecimal(val.row.finalPrice);
            }
        },
        {
            field: "edit",
            width: "150",
            headerName: "Action",
            renderCell: (cellValues) => {
                return (
                    <>
                        {
                            columnLoader === cellValues.row.itemIndex ? (
                                <CircularProgress />
                            ) : (
                                (isNumberEmpty(cellValues.row.status) || cellValues.row.status !== 1 /*full*/) &&
                                columnLoader === -1 && !isPaid && (
                                    <SnpButton
                                        variant="contained"
                                        mbgcolor="#eaa221"
                                        mpadding="2px"
                                        mcolor="#2e020d"
                                        onClick={() => handleCellClick(cellValues.row)}
                                    >
                                        Partial Remove
                                    </SnpButton>
                                )
                            )
                        }

                    </>
                );
            }
        },
    ];

    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [snpLoader, setSnpLoader] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [columnList, setColumnList] = useState([]);
    const [removedList, setRemoveList] = useState([]);
    const [columnLoader, setColumnLoader] = useState(-1);
    const [linearLoader, setLinearLoader] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [billType, setBillType] = useState(InternalBillType.RETURN_BILL);
    const [billNo, setBillNo] = useState("");
    const [refBillNo, setRefBillNo] = useState("");
    const [paymentDetails, setPaymentDetails] = useState(INITIAL_PAYMENT_DETAILS);
    const [prevPaymentDetails, setPrevPaymentDetails] = useState(INITIAL_PAYMENT_DETAILS);
    const [customerData, setCustomerData] = useState(INITIAL_CUSTOMER_DATA);

    const [itemDetails, setItemDetails] = useState(INITIAL_ITEM_DETAILS);
    const [cellData, setCellData] = useState({});
    const [stockInfos, setStockInfos] = useState([]);
    const [showItemDialog, setShowItemDialog] = useState(false);
    const [createdDate, setCreatedDate] = useState(uiUtilInstance.todayFormattedDate);

    useEffect(() => {
        printDebugLog("storeActiveShopHash", storeActiveShopHash);
        printDebugLog("storeApiUrl", storeApiUrl);
        printDebugLog("MartBilling", location.state?.sBillNo);
        if (!isEmpty(location.state?.sBillNo)) {
            fetchBill(location.state?.sBillNo);
        }
    }, [storeActiveShopHash, storeApiUrl, location.state]);

    useEffect(() => {
        printDebugLog("useEffet removeList", removedList);
    }, [removedList]);

    useEffect(() => {
        printDebugLog("useEffet columnList", columnList);
    }, [columnList]);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        //var currentTime = +new Date();
        const userAgent = navigator.userAgent;
        const handleMediaQueryChange = (event) => {
            const deviceType = getDeviceType(userAgent);
            const width = window.innerWidth;
            printDebugLog("deviceType<=", deviceType, event.matches, width);
            if (deviceType === 'Tablet') {
                setIsMobile(true);
            } else if (width <= 1280) {
                setIsMobile(true);
            } else
                setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);
        window.addEventListener("popstate", handleMediaQueryChange);
        window.addEventListener("hashchange", handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
            window.removeEventListener("popstate", handleMediaQueryChange);
            window.removeEventListener("hashchange", handleMediaQueryChange);
        };
    }, []);

    const handleReturnClick = (row) => {
        printDebugLog("handleReturnClick", row);
        const indexAvailble = removedList.findIndex((item) => item.itemIndex === row.itemIndex);
        if (indexAvailble === -1) {
            setRemoveList((prev) => [...prev, row]);
            setColumnList((prev) => prev.map((item) => item.itemIndex === row.itemIndex ? { ...item, status: 1, removedQuan: item.quantity } : item));
            doTotalByFull(row, true);
        } else {
            setRemoveList((prev) => prev.filter((item) => item.itemIndex !== row.itemIndex));
            setColumnList((prev) => prev.map((item) => item.itemIndex === row.itemIndex ? { ...item, status: 0, removedQuan: "" } : item));
            doTotalByFull(row, false);
        }
    };

    const getFinalPrice = (row) => {
        let finalPrice = 0.0;
        const parsedOfferAmt = parseFloat(row.offerAmt) || 0.0;
        switch (Number(row.offerDenom)) {
            case OfferType.Flat:
                finalPrice = row.price - parsedOfferAmt;
                break;
            case OfferType.Percentage:
                finalPrice = row.price - (row.price * parsedOfferAmt) / 100;
                break;
            default:
                finalPrice = row.price;
                break;
        }
        return finalPrice;
    }

    const getGSTById = (id) => {
        return stockInfos.reduce(
            (acc, item) => {
                if (item.myId === id) {
                    acc.cgstAmt += item.cgstAmt || 0;
                    acc.sgstAmt += item.sgstAmt || 0;
                }
                return acc;
            },
            { cgstAmt: 0, sgstAmt: 0 }
        );
    };


    const handleUpdateReturnDetails = () => {
        const indexAvailble = removedList.findIndex((item) => item.itemIndex === cellData.itemIndex);
        let existFinalPrice = 0.0;
        let existSubTotalPrice = 0.0;
        let existCgstAmt = 0.0;
        let existSgstAmt = 0.0;
        if (indexAvailble !== -1) {
            const prevStock = removedList[indexAvailble];
            let existQuan = 0;
            const localStockRefNo = new Map(Object.entries(prevStock.stockRefNo));
            for (const [key, value] of localStockRefNo.entries()) {
                const extractedValue = uiUtilInstance.getMatchedQuan(value);
                existQuan += extractedValue;
                const gstInfo = getGSTById(key);
                existCgstAmt += gstInfo.cgstAmt;
                existSgstAmt += gstInfo.sgstAmt;
            }
            existFinalPrice = getFinalPrice(prevStock) * existQuan;
            existSubTotalPrice = prevStock.price * existQuan;
        }
        const cellDataAcc = stockInfos.reduce(
            (acc, item) => {
                if (item.quantity !== item.maxQuan) {
                    acc.cgstAmt += Number(item.cgstAmt) || 0;
                    acc.sgstAmt += Number(item.sgstAmt) || 0;
                    const removedQuan = Number(item.maxQuan) - Number(item.quantity);
                    acc.stateStockMap.set(item.stockRefNo, item.unit + "=" + removedQuan);
                    acc.totalQuan += removedQuan;
                }
                return acc;
            },
            { totalQuan: 0, cgstAmt: 0, sgstAmt: 0, stateStockMap: new Map() }
        );
        const cellDataFinalPrice = getFinalPrice(cellData) * cellDataAcc.totalQuan;
        const cellDataSubTotalPrice = cellData.price * cellDataAcc.totalQuan;
        const finalPrice = paymentDetails.total - existFinalPrice + cellDataFinalPrice;
        const subTotal = paymentDetails.subTotal - existSubTotalPrice + cellDataSubTotalPrice;
        const cgstAmt = paymentDetails.cgstAmt + cellDataAcc.cgstAmt - existCgstAmt;
        const sgstAmt = paymentDetails.sgstAmt + cellDataAcc.sgstAmt - existSgstAmt;
        setPaymentDetails((prev) => ({
            ...prev,
            total: finalPrice,
            subTotal: subTotal,
            cgstAmt: cgstAmt,
            sgstAmt: sgstAmt,
            onlinePay: 0.0,
            cashPay: 0.0,
            cashTender: 0.0,
            cashReturn: 0.0,
            isCashPayEnabled: false,
            isOnlinePayEnabled: false,
            onlinePayMedium: "",
            payMedium: PayMedium.NOPAY,
        }));
        let mStatus = 0;
        if (cellDataAcc.totalQuan === cellData.quantity) {
            mStatus = 1; //Full
            setRemoveList((prev) => {
                const exists = prev.find((item) => item.itemIndex === cellData.itemIndex);
                if (exists) {
                    return prev.map((item) =>
                        item.itemIndex === cellData.itemIndex
                            ? { ...item, quantity: cellDataAcc.totalQuan }
                            : item
                    );
                } else {
                    return [...prev, cellData];
                }
            });
        } else if (cellDataAcc.totalQuan > 0 && cellDataAcc.totalQuan < cellData.quantity) {
            mStatus = 2; //Partial
            cellData.quantity = cellDataAcc.totalQuan;
            cellData.stockRefNo = Object.fromEntries(cellDataAcc.stateStockMap);
            cellData.subTotalPrice = cellDataSubTotalPrice;
            cellData.finalPrice = cellDataFinalPrice;
            cellData.cgstAmt = cellDataAcc.cgstAmt;
            cellData.sgstAmt = cellDataAcc.sgstAmt;
            cellData.appliedOfferAmt = cellDataSubTotalPrice - cellDataFinalPrice;
            setRemoveList((prev) => {
                const exists = prev.find((item) => item.itemIndex === cellData.itemIndex);
                if (exists) {
                    return prev.map((item) =>
                        item.itemIndex === cellData.itemIndex
                            ? { ...item, cellData }
                            : item
                    );
                } else {
                    return [...prev, cellData];
                }
            });
        } else {
            //Remove from removed list
            setRemoveList((prev) => prev.filter((item) => item.itemIndex !== cellData.itemIndex));
        }
        setColumnList((prev) => prev.map((item) => item.itemIndex === cellData.itemIndex ? { ...item, status: mStatus, removedQuan: cellDataAcc.totalQuan } : item));
        handleItemDialogClose();
    }

    const doTotalByFull = (row, add) => {
        let finalPrice = 0.0;
        let subTotal = 0.0;
        let cgstAmt = 0.0;
        let sgstAmt = 0.0;
        if (add) {
            finalPrice = row.finalPrice + paymentDetails.total;
            subTotal = row.subTotalPrice + paymentDetails.subTotal;
            cgstAmt = row.cgstAmt + paymentDetails.cgstAmt;
            sgstAmt = row.sgstAmt + paymentDetails.sgstAmt;
        } else {
            finalPrice = paymentDetails.total - row.finalPrice;
            subTotal = paymentDetails.subTotal - row.subTotalPrice;
            cgstAmt = paymentDetails.cgstAmt - row.cgstAmt;
            sgstAmt = paymentDetails.sgstAmt - row.sgstAmt;
        }
        setPaymentDetails((prev) => ({
            ...prev,
            total: finalPrice,
            subTotal: subTotal,
            cgstAmt: cgstAmt,
            sgstAmt: sgstAmt,
            onlinePay: 0.0,
            cashPay: 0.0,
            cashTender: 0.0,
            cashReturn: 0.0,
            isCashPayEnabled: false,
            isOnlinePayEnabled: false,
            onlinePayMedium: "",
            payMedium: PayMedium.NOPAY,
        }));
    };



    const handleItemDialogClose = () => {
        setShowItemDialog(false);
        setItemDetails(INITIAL_ITEM_DETAILS);
        setCellData({});
        setStockInfos([]);
    };

    const fireSnackBarMsg = (msg, mHorizontal = "right", mVertical = "top", mColor = "#008b8b") => {
        setSnpComponent(prev => ({
            ...prev,
            snackBarMsg: msg,
            snackBarOpen: true,
            snackHorizontal: mHorizontal,
            snackVertical: mVertical,
            snackColor: mColor
        }));
    };

    const handleSearch = (searchById) => {
        let mUrl = "";
        if (searchById === SearchOption.SEARCHBYBARC) {
            mUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/get-item-from-barcode?adminId=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&value=${searchText}&billNo=${refBillNo}`;
        } else {
            mUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/get-item-from-batch?adminId=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&value=${searchText}&billNo=${refBillNo}`;
        }
        getItemDetailsFromValue(mUrl);
    };

    const handleCellClick = (cellData) => {
        printDebugLog("handleCellClick", cellData);
        getItemDetailsFromBill(cellData, refBillNo);
    };

    const handleClearEveryThing = () => {
        setColumnList([]);
        setRemoveList([]);
        setBillNo("");
        setRefBillNo("");
        setPaymentDetails(INITIAL_PAYMENT_DETAILS);
        setPrevPaymentDetails(INITIAL_PAYMENT_DETAILS);
        setCustomerData(INITIAL_CUSTOMER_DATA);
        setIsPaid(false);
        setCreatedDate(uiUtilInstance.todayFormattedDate);
        setBillType(InternalBillType.RETURN_BILL);
    }

    const fetchBill = async (mBill) => {
        try {
            setSnpLoader(true);
            const fetchUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/fetch-bill-details`;
            const requestData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    billNo: mBill,
                    shopHash: storeActiveShopHash.id,
                }
            }
            const response = await snpAxios.post(fetchUrl, requestData);
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson;
                const returnBillCreated = payLoad.returnBillCreated;
                if (returnBillCreated) {
                    const returnBillNo = payLoad.returnBillNo;
                    setSnpComponent((prevState) => ({
                        ...prevState,
                        negativeReq: true,
                        negativeAction: "Dismiss",
                        positiveReq: true,
                        positiveAction: "Go to Bill",
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 99,
                        iconContent: <WarningIcon style={{ color: "#ffbd77", fontSize: "40px" }} />,
                        handleSnpSubmit: () => {
                            setSnpComponent(INITIAL_SNP_COMPONENT);
                            fetchBill(returnBillNo);
                        },
                        handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                        title: "Return Bill Already Created",
                        subTitle: "Already Return Bill is created for this bill "+mBill+" with Bill No "+returnBillNo,
                    }));
                } else {
                    const billType = payLoad.billType;
                    if (billType === InternalBillType.RETURN_BILL) {
                        setBillNo(payLoad.billNo);
                        setRefBillNo(payLoad.refBillNo);
                        setPrevPaymentDetails(payLoad.paymentDetailsRef);
                        setIsPaid(payLoad.isPaid);
                        setCreatedDate(payLoad.createdDate);
                        setPaymentDetails(payLoad.paymentDetails);
                        setRemoveList(payLoad.removedList);
                        setBillType(billType);
                    } else {
                        setRefBillNo(payLoad.billNo);
                        setPrevPaymentDetails(payLoad.paymentDetails);
                    }
                    setCustomerData(payLoad.customerData);
                    setColumnList(payLoad.columnList);
                    setCreatedDate(payLoad.createdAt);
                }
            }
            setSnpLoader(false);
        } catch (error) {
            console.error("fetchBill error : ", error);
            setSnpLoader(false);
        }
    }

    const getUpdatedServerData = (serverData, filteredColumns) => {
        return serverData.map((serverItem) => {
            const { stockRefNo, quantity } = serverItem;
            let deductedQuantity = 0;

            filteredColumns.forEach((removeItem) => {
                const usedVal = removeItem.stockRefNo?.[stockRefNo]; // Check if useList has this stockRefNo
                if (usedVal) {
                    const match = usedVal.match(/\d+/); // extract digits
                    if (match) {
                        deductedQuantity += parseFloat(match[0], 10);
                    }
                }
            });

            return {
                ...serverItem,
                quantity: quantity - deductedQuantity,
            };
        });
    };

    const updateItemDetails = (payLoad, mPrdId) => {
        setItemDetails(prev => ({
            ...prev,
            ...payLoad.itemDetailsMap,
        }));
        if (removedList.length > 0) {
            if (isEmpty(mPrdId)) {
                const modifiedStockInfos = getUpdatedServerData(payLoad.stockInfos, removedList);
                printDebugLog("getItemDetailsFromBill", "modifiedStockInfos", modifiedStockInfos);
                setStockInfos(modifiedStockInfos);
            } else {
                const filteredColumns = removedList.filter(col => col.prdId === mPrdId);
                if (filteredColumns.length > 0) {
                    const modifiedStockInfos = getUpdatedServerData(payLoad.stockInfos, filteredColumns);
                    printDebugLog("getItemDetailsFromBill", "modifiedStockInfos", modifiedStockInfos);
                    setStockInfos(modifiedStockInfos);
                } else {
                    setStockInfos(payLoad.stockInfos);
                }
            }
        } else {
            setStockInfos(payLoad.stockInfos);
        }
        setShowItemDialog(true);
    };

    const getItemDetailsFromBill = async (cellData, billNo) => {
        try {
            setColumnLoader(cellData.itemIndex);
            const mUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/get-item-details-from-bill?adminId=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&itemIndex=${cellData.itemIndex}&billNo=${billNo}`;
            const response = await snpAxios.get(mUrl, {});
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson;
                updateItemDetails(payLoad, cellData.prdId);
                setCellData(cellData);
            }
            setColumnLoader(-1);
        } catch (error) {
            console.error("getItemDetailsFromBill error : ", error);
            setColumnLoader(-1);
        }
    };

    const getItemDetailsFromValue = async (mUrl) => {
        try {
            setLinearLoader(true);
            const response = await snpAxios.get(mUrl, {});
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson;
                const itemIndex = payLoad.itemIndex;
                const cellData = getColumnByItemIndex(columnList, itemIndex);
                setCellData(cellData);
                updateItemDetails(payLoad, "");
            }
            setSearchText("");
            setLinearLoader(false);
        } catch (error) {
            console.error("getItemDetailsFromBarCode error : ", error);
            setLinearLoader(false);
        }
    };

    const printBill = async (mShop) => {
        try {
            setSnpLoader(true);
            const billUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/print_bill/${CART_INITIAL_STATE.apiToken}/${billNo}/${storeActiveShopHash.id}/${mShop}`;
            const response = await axios.get(billUrl, {});
            printDebugLog("printBill", mShop, response);
            if (response.data !== '') {
                baseRaiseInvoiceClicked(storeApiUrl.BACKEND_BASE_URL, CART_INITIAL_STATE.apiToken, storeActiveShopHash.id, response.data, mShop === 1 ? "shop" : "cust", isMobile, billNo, fireSnackBarMsg);
            }
            setSnpLoader(false);
        } catch (error) {
            console.error("printBill error : ", error);
            setSnpLoader(false);
        }
    }

    const initiatePayment = async () => {
        if ((!paymentDetails.isCashPayEnabled && !paymentDetails.isOnlinePayEnabled)) {
            fireSnackBarMsg("Please Select any of the Payment method", "right", "top", "#ff954a");
            return;
        }
        if (paymentDetails.isOnlinePayEnabled && isEmpty(paymentDetails.onlinePayMedium)) {
            fireSnackBarMsg("Please Select Online Pay Method", "right", "top", "#ff954a");
            return;
        }
        if (isEmpty(customerData.custMob)) {
            fireSnackBarMsg("Please Add Customer Details", "right", "top", "#ff954a");
            return;
        }
        try {
            setSnpLoader(true);
            const billUrl = `${storeApiUrl.BACKEND_BASE_URL}mart-bill/create-update-return-bill`;
            const today = new Date();
            const time = createdDate + " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const requestData = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    shopHash: storeActiveShopHash.id,
                    removedList: removedList,
                    billType: billType,
                    billNo: billNo,
                    billRefId: refBillNo,
                    createdAt: time,
                    ...paymentDetails,
                    billStatus: BillStatus.PAID,
                }
            }
            const response = await snpAxios.post(billUrl, requestData);
            printDebugLog("initiatePayment", requestData.jcJson, response);
            if (response.data.status === SUCCESS) {
                const payLoad = response.data.jcJson;
                baseRaiseInvoiceClicked(storeApiUrl.BACKEND_BASE_URL, CART_INITIAL_STATE.apiToken, storeActiveShopHash.id, payLoad.custprint, "", isMobile, payLoad.billNo, fireSnackBarMsg);
                setBillNo(payLoad.billNo);
                setIsPaid(true);
            }
            setSnpLoader(false);
        } catch (error) {
            console.error("initiatePayment error : ", error);
            setSnpLoader(false);
        }
    };

    const renderDialogContent = () => {
        return (
            <ReturnDialogContent
                celData={cellData}
                itemDetails={itemDetails}
                stockInfos={stockInfos}
                isPaid={isPaid}
                setStockInfos={setStockInfos}
            />
        );
    };

    const renderItemDialogAction = () => {
        return (
            <>
                <SnpButton variant="contained" onClick={handleUpdateReturnDetails}>
                    Submit
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={handleItemDialogClose}>
                    Dismiss
                </DismissButton>
            </>
        )
    };

    const dataGridComponent = useMemo(() => {
        return (
            <CustomDataGrid
                getRowId={(row) => row.itemIndex}
                rows={columnList}
                columns={columns}
                mDensity="compact"
                getRowClassName={(params) => {
                    if (params.row.status === 1) {
                        return 'row-selected';
                    } else if (params.row.status === 2) {
                        return 'row-partial-selected';
                    }
                    return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
                }}
            />
        );
    }, [columnList]);

    const renderSnpContent = () => {
        return (
            <>
                <MartReturnWasteHeader
                    billNo={billNo}
                    refBillNo={refBillNo}
                    baseUrl={`${storeApiUrl.BACKEND_BASE_URL}${GETBILLBYREGEX}?adminId=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}`}
                    billType={billType}
                    setBillType={setBillType}
                    isPaid={isPaid}
                    selectedDate={createdDate}
                    handleSelect={(type, option) => {
                        printDebugLog("MartReturnWasteHeader", `type: ${type} option: `, option);
                        fetchBill(option.billNo);
                    }}
                    handleClearEveryThing={handleClearEveryThing} />
                {
                    !isEmpty(refBillNo) && !isPaid && (
                        <ReturnSearchChoice
                            searchText={searchText}
                            setSearchText={setSearchText}
                            onSearch={handleSearch}
                            loader={linearLoader} />)
                }

                {dataGridComponent}

                <ReturnPaymentInfo
                    prevPaymentInfo={prevPaymentDetails}
                    paymentDetails={paymentDetails}
                    setPaymentDetails={setPaymentDetails}
                    fireSnackBarMsg={fireSnackBarMsg}
                    isPaid={isPaid} />

                < DialogBox
                    showDialog={showItemDialog}
                    title={"Stock Info"}
                    onClose={handleItemDialogClose}
                    dialogContent={renderDialogContent()}
                    dialogActions={renderItemDialogAction()}
                />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                    <EnterButton disabled={isPaid
                        || snpLoader || (Array.isArray(removedList) && removedList.length === 0) || !isRoleAllowed(SALESRIGHTS)}
                        variant="contained"
                        color="primary"
                        onClick={() => initiatePayment()}>
                        Initiate Payment
                    </EnterButton>
                    <EnterButton disabled={snpLoader
                        || (Array.isArray(removedList) && removedList.length === 0)
                        || !isRoleAllowed(SALESRIGHTS)
                        || isEmpty(billNo)}
                        variant="contained"
                        mbgcolor="#180100"
                        onClick={() => printBill(1)}>
                        Print Shop Bill
                    </EnterButton>
                    <EnterButton disabled={snpLoader
                        || (Array.isArray(removedList) && removedList.length === 0)
                        || !isRoleAllowed(SALESRIGHTS)
                        || isEmpty(billNo)}
                        variant="contained"
                        mbgcolor="#220000"
                        onClick={() => printBill(0)}>
                        Print Customer Bill
                    </EnterButton>
                </div>
            </>
        )
    }

    return (
        <SnpComponent showDialog={snpComponent.showDialog}
            isLoader={snpLoader}
            title={snpComponent.title}
            subTitle={snpComponent.subTitle}
            negativeAction={snpComponent.negativeAction}
            positiveReq={snpComponent.positiveReq}
            positiveAction={snpComponent.positiveAction}
            negativeReq={snpComponent.negativeReq}
            handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
            iconReq={snpComponent.iconReq}
            iconContent={snpComponent.iconContent}
            content={renderSnpContent()}
            onSnackClose={() => setSnpComponent({
                ...snpComponent,
                snackBarOpen: false,
                snackBarMsg: "",
            })}
            cancelExtra={snpComponent.cancelExtra}
            snackBarOpen={snpComponent.snackBarOpen}
            snackBarMsg={snpComponent.snackBarMsg}
            outSideClickDismiss={snpComponent.outSideClickDismiss}
            gui={snpComponent.gui}
            snackHorizontal={snpComponent.snackHorizontal}
            snackVertical={snpComponent.snackVertical}
            snackColor={snpComponent.snackColor}
            onCancel={snpComponent.handleSnpCancel}
            handleSubmit={snpComponent.handleSnpSubmit}
        />
    )
}