import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import StockItemGrid from "./StockItemGrid";
import { CartDets, ChooseItemPrices, isEmpty, isNumberEmpty, printDebugLog, twoDecimal } from "../../../constants/constant";
import uiUtilInstance from "../../../utilities/uiUtil";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { findColumnIndex, getColumnByItemIndex } from "./BillItemAddUseCase";
import SnpButton from "../../global/SnpButton";

const RenderDialogContent = memo((
    { itemDetails,
        management,
        cnvMap,
        stockInfos,
        cartType,
        defChoosePrices,
        defChooseUnit,
        handleItemAdd,
        defSelectedStockMap,
        defItemQuantity,
        quanChangeBlock,
        defColumnList,
        defSelectedItemIndex,
        lastStockSelected,
        defListProduct,
        editClick,
        handleProductSelect,
        updateQuantityChanges,
        fireSnackBarMsg }) => {

    const [choosePrices, setChoosePrice] = useState(defChoosePrices);
    const [chooseUnit, setChooseUnit] = useState(defChooseUnit);
    const [itemQuantity, setItemQuantity] = useState(defItemQuantity);
    const [selectedProduct, setSelectedProduct] = useState("");
    const colQuantityClasses = uiUtilInstance.useStyles();
    const [stateStockMap,setStateStockMap] = useState(new Map(defSelectedStockMap));
    const [deleteStockMap,setDeleteStockMap] = useState(new Map());

    useEffect(() => {
        printDebugLog("RenderDialogContent defChooseUnit", defChooseUnit);
        printDebugLog("RenderDialogContent defChoosePrices", defChoosePrices);
        printDebugLog("RenderDialogContent defItemQuantity", defItemQuantity);
        printDebugLog("RenderDialogContent defSelectedStockMap", defSelectedStockMap);
        printDebugLog("RenderDialogContent quanChangeBlock", quanChangeBlock);
        printDebugLog("RenderDialogContent defColumnList", defColumnList);
        printDebugLog("RenderDialogContent defSelectedItemIndex", defSelectedItemIndex);
        printDebugLog("RenderDialogContent lastStockSelected", lastStockSelected);
        printDebugLog("RenderDialogContent defListProduct", defListProduct);
        printDebugLog("RenderDialogContent editClick", editClick);
        if (defListProduct.length > 1) {
            const matchedItem = defListProduct.find(col => col.id === itemDetails.prdId);
            setSelectedProduct(matchedItem);
        }
    }, [defChooseUnit, defChoosePrices, defItemQuantity, defSelectedStockMap, quanChangeBlock, defColumnList, defSelectedItemIndex, lastStockSelected, defListProduct,editClick])

    useEffect(() => {
        printDebugLog("RenderDialogContent choosePrices", choosePrices);
        printDebugLog("RenderDialogContent chooseUnit", chooseUnit);
        printDebugLog("RenderDialogContent itemQuantity", itemQuantity);
        printDebugLog("RenderDialogContent itemQuantity", stateStockMap);
        printDebugLog("RenderDialogContent isEqual", uiUtilInstance.speciaCaseMapsEqual(stateStockMap,defSelectedStockMap));
    }, [choosePrices, chooseUnit, itemQuantity, stateStockMap]);

    const handleUnSelectItem = useCallback((data,type) => {
        printDebugLog("RenderDialogContent:handleUnSelectItem", data,itemQuantity,type);
        const value = stateStockMap.get(data.stockRefNo);
        let extractedValue = uiUtilInstance.getMatchedQuan(value,0);
        let quan = 0;
        if (type === 0 /*Full Delete */) {
            deleteStockMap.set(data.stockRefNo,value);
            stateStockMap.delete(data.stockRefNo);
            quan = Number(itemQuantity) - Number(extractedValue);
        } else {
            const unitSplitter = value.split("=");
            extractedValue = Number(extractedValue) - 1;
            if (extractedValue < 0) {
                return;
            }
            if (extractedValue ===  0) {
                stateStockMap.delete(data.stockRefNo);
            } else {
                stateStockMap.set(data.stockRefNo,unitSplitter[0]+"="+extractedValue);
            }
            deleteStockMap.set(data.stockRefNo,unitSplitter[0]+"="+extractedValue);
            quan = Number(itemQuantity) - 1;
        }
        setItemQuantity(quan);
        setDeleteStockMap(deleteStockMap);
        setStateStockMap(stateStockMap);
    },[itemQuantity,stateStockMap,deleteStockMap]);

    const handleAddItem = useCallback((data) => {
        printDebugLog("RenderDialogContent:handleAddItem", data,itemQuantity);
        if (unsavedMessage()) {
            return;
        }
        if (isNumberEmpty(itemQuantity)) {
            fireSnackBarMsg("Please add some quantity ", "#e41e2e");
        } else {
            let price = 0.0;
            if (chooseUnit === cnvMap.unitName) {
                price = choosePrices === ChooseItemPrices.Customer_Prices ?
                    management.bigPrice.productmrPrice : data.bigPrice.productmrPrice;
            } else {
                price = choosePrices === ChooseItemPrices.Customer_Prices ?
                    management.smallPrice.productmrPrice : data.smallPrice.productmrPrice;
            }
            const index = findColumnIndex(defColumnList,stockInfos,cnvMap,management,data.stockRefNo,data.prdId,
                choosePrices,chooseUnit,price,defSelectedItemIndex);
            let stockRefMap = null;
            let removeQuan = 0;
            let mQuantity = 0;
            const parsedItemQuantity = Number(itemQuantity) || 1;
            if (index !== -1) {
                const matchedItem = getColumnByItemIndex(defColumnList , index);
                stockRefMap = new Map(Object.entries(matchedItem.stockRefNo));
                mQuantity = matchedItem.quantity;
                let deleteMatched = false;
                //Condition added if in Customer prices/Stock Prices , unit change then change for all stockref attached
                const updateStockMapUnit = chooseUnit !== matchedItem.unit;
                deleteStockMap.forEach((value, key) => {
                    if (stockRefMap.has(key)) {
                        deleteMatched = true;
                        const extractedValue = uiUtilInstance.getMatchedQuan(stockRefMap.get(key),0);
                        const extractedDeletedValue = uiUtilInstance.getMatchedQuan(value,0);
                        if (extractedDeletedValue === extractedValue || extractedDeletedValue === 0) {
                            stockRefMap.delete(key);
                        } else {
                            const unitSplitter = value.split("=");
                            const unitValue = extractedValue - extractedDeletedValue;
                            stockRefMap.set(key, unitSplitter[0]+"="+unitValue);
                        }
                    }
                });
                stockRefMap.forEach((value, key) => {
                    if (key !== data.stockRefNo) {
                        const extractedValue = uiUtilInstance.getMatchedQuan(value, 0);
                        if (updateStockMapUnit) {
                            stockRefMap.set(key, chooseUnit + "=" + extractedValue);
                        }
                        removeQuan += extractedValue;
                    }
                });
                
                mQuantity = (parsedItemQuantity === defItemQuantity || deleteMatched) ? mQuantity + 1 : parsedItemQuantity;
            } else {
                mQuantity = (parsedItemQuantity === defItemQuantity) ? 1 : parsedItemQuantity;
            }
            if (stockRefMap == null) {
                stockRefMap = new Map();
            }
            const currentStockQuantity = mQuantity - removeQuan;
            if (preCheck(currentStockQuantity,data)) {
                return;
            }
            const val = chooseUnit+"="+currentStockQuantity;
            stockRefMap.set(data.stockRefNo, val);
            handleItemAdd(data, choosePrices, chooseUnit, mQuantity,stockRefMap, index);
        }

    }, [choosePrices, chooseUnit, itemQuantity,deleteStockMap, handleItemAdd]);

    const handleUpdateQuantityChanges = useCallback(() => {
        updateQuantityChanges(choosePrices, chooseUnit, itemQuantity,stateStockMap, defSelectedItemIndex);
    },[choosePrices, chooseUnit, itemQuantity,stateStockMap, handleItemAdd]);

    const getSpRateCard = () => {
        const { bigPrice, smallPrice } = management;
        const { unitName, unitRestrictions, smallUnitName } = cnvMap;

        const rate = `₹${twoDecimal(bigPrice.productSellingPrice)}/${unitName}`;
        return unitRestrictions ? rate : `${rate} | ₹${twoDecimal(smallPrice.productSellingPrice)}/${smallUnitName}`;
    };

    const getMrpRateCard = () => {
        const { bigPrice, smallPrice } = management;
        const { unitName, unitRestrictions, smallUnitName } = cnvMap;

        const rate = `₹${twoDecimal(bigPrice.productmrPrice)}/${unitName}`;
        return unitRestrictions ? rate : `${rate} | ₹${twoDecimal(smallPrice.productmrPrice)}/${smallUnitName}`;
    };

    //data cannot be null
    const preCheck = (incQuan,data) => {
        if (data == null) {
            fireSnackBarMsg("Please select any stock then increment/decrement", "#e41e2e");
            return true;
        }
        if (chooseUnit === cnvMap.unitName && incQuan > data.remQuan[0]) {
            fireSnackBarMsg("Exceeds Total Count for  " + cnvMap.unitName + " is " + data.remQuan[0], "#e41e2e");
            return true;
        } else if (chooseUnit === cnvMap.smallUnitName && incQuan > data.remQuan[1]) {
            fireSnackBarMsg("Exceeds Total Count for  " + cnvMap.smallUnitName + " is " + data.remQuan[1], "#e41e2e");
            return true;
        }
        return false;
    }

    const handleIncrement = () => {
        if (unsavedMessage()) {
            return;
        }
        if (quanChangeBlock) {
            fireSnackBarMsg("Cannot increment from here.Click edit to change", "#e41e2e");
            return;
        }
        if (isEmpty(lastStockSelected)) {
            fireSnackBarMsg("Please select any stock then increment/decrement", "#e41e2e");
            return;
        } 
        const pItem = stockInfos.find(col => col.stockRefNo === lastStockSelected);
        const value = stateStockMap.get(lastStockSelected);
        let extractedValue = uiUtilInstance.getMatchedQuan(value, 1);
        if (preCheck(extractedValue,pItem)) {
            return;
        }
        extractedValue = extractedValue + 1;
        const incQuan = Number(itemQuantity) + 1;
        setItemQuantity(incQuan);
    };

    const handleDecrement = () => {
        if (unsavedMessage()) {
            return;
        }
        if (quanChangeBlock) {
            fireSnackBarMsg("Cannot decrement from here.Click edit to change", "#e41e2e");
            return;
        }
        setItemQuantity(prev => (prev > 1 ? parseInt(prev) - 1 : 1));
    };

    const handleColQuantityChange = (event) => {
        if (unsavedMessage()) {
            return;
        }
        const value = event.target.value;
        printDebugLog("handleColQuantityChange", value);
        const regex = cnvMap.decimalSmRestrictions ? /^[0-9]*$/ : /^[0-9]*\.?[0-9]*$/;
        if (regex.test(value)) {
            if (isEmpty(lastStockSelected)) {
                fireSnackBarMsg("Please select any stock then increment/decrement", "#e41e2e");
                return;
            }
            const parsedValue = Number(value) || 0;
            const pItem = stockInfos.find(col => col.stockRefNo === lastStockSelected);
            if (!isNumberEmpty(value) && preCheck(parsedValue, pItem)) {
                return;
            }
            setItemQuantity(value);
        }
    };


    const getItemName = () => {
        return (
            <>
                {
                    defListProduct.length === 1 ?
                        itemDetails.name :
                        <Autocomplete
                            id="search-select"
                            options={defListProduct}
                            autoHighlight
                            variant="filled"
                            size="small"
                            onChange={(_blank,option) => handleProductSelect(option)}
                            value={selectedProduct}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option?.name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label=""
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />
                }
            </>
        )
    };

    const unsavedMessage = () => {
        if (!uiUtilInstance.speciaCaseMapsEqual(stateStockMap, defSelectedStockMap)) {
            fireSnackBarMsg("Please Save first the unsave changes", "#e41e2e");
            return true;
        }
        return false;
    }


    const getProductDetailsUI = () => {
        return (
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#fcfe96",
                    boxShadow: 1,
                    borderRadius: 4,
                    padding: 2,
                }} >
                <div>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Item Name</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{getItemName()}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Type</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.prdType}</span>
                    </Typography>
                    {
                        cartType === CartDets.Pharma &&
                        <Typography
                            variant="p"
                            style={{ fontSize: "14px" }}
                            component="div">
                            <span style={{ color: "blue", fontWeight: "bold" }}>Composition</span>
                            <span style={{ margin: "0 4px" }}>:</span>
                            <span style={{
                                color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>{itemDetails.composition}</span>
                        </Typography>
                    }
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Rack No</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.rack_no}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Available Free Stock</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{management.freeStockCount || "0"}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Available Stock</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{management.liveStockData || "0"}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Customer Price/s</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word', display: "block"
                        }}>{"MRP: " + getMrpRateCard()}</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{"Sp: " + getSpRateCard()}</span>
                    </Typography>
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend"><span style={{ color: "blue", fontWeight: "bold" }}>Choose Price:</span></FormLabel>
                            <RadioGroup
                                aria-label="choosePrices"
                                name="choosePrices"
                                value={Number(choosePrices)}
                                onChange={(e) => !unsavedMessage() && setChoosePrice(Number(e.target.value))}
                                row={true}
                            >
                                {Object.entries(ChooseItemPrices).map(([key, value]) => (
                                    <FormControlLabel
                                        key={value}
                                        value={value}
                                        control={<Radio />}
                                        label={key.replace("_", " ")}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel component="legend"><span style={{ color: "blue", fontWeight: "bold",whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word' }}>Choose Unit: {"("+cnvMap.cnvDetails+")"}</span></FormLabel>
                            <RadioGroup
                                aria-label="chooseUnit"
                                name="chooseUnit"
                                value={chooseUnit}
                                onChange={(e) => {
                                    if (!unsavedMessage()) {
                                        setChooseUnit(e.target.value);
                                        setItemQuantity(defItemQuantity);
                                    }
                                }}
                                row={true}
                            >
                                <FormControlLabel
                                    key="unitName"
                                    value={cnvMap.unitName}
                                    control={<Radio />}
                                    label={cnvMap.unitName}
                                />
                                {
                                    !cnvMap.unitRestrictions &&
                                    <FormControlLabel
                                        key="smallUnitName"
                                        value={cnvMap.smallUnitName}
                                        control={<Radio />}
                                        label={cnvMap.smallUnitName}
                                    />
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <FormLabel component="legend"><span style={{ color: "blue", fontWeight: "bold" }}>Enter Quantity:</span></FormLabel>
                        <TextField
                            id="dialogQuan"
                            className={colQuantityClasses.input}
                            name="dialogQuan"
                            disabled={quanChangeBlock}
                            variant="outlined"
                            size="small"
                            value={itemQuantity}
                            style={{ width: "50%", maxHeight: "20%" }}
                            sx={{
                                justifyContent: "center", alignContent: "center",
                                '& .MuiInputBase-input': {
                                    textAlign: "center",
                                },
                            }}
                            onChange={handleColQuantityChange}
                            InputProps={{
                                inputMode: "decimal",
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                    >
                                        <IconButton onClick={handleIncrement}
                                            sx={{ backgroundColor: "green", padding: 0, "&:hover": { backgroundColor: "darkgreen" } }}>
                                            <AddIcon sx={{ color: "white", fontSize: "2rem" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        <IconButton onClick={handleDecrement}
                                            sx={{ backgroundColor: "red", padding: 0, "&:hover": { backgroundColor: "darkred" } }}>
                                            <RemoveIcon sx={{ color: "white", fontSize: "2rem" }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {quanChangeBlock && <FormLabel component="legend"><span style={{ color: "red", fontWeight: "bold" }}>Cannot change from here.Click edit to change</span></FormLabel>}
                    </div>
                </div>
            </Paper>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {getProductDetailsUI()}
            </Grid>
            {
                !uiUtilInstance.speciaCaseMapsEqual(stateStockMap, defSelectedStockMap)
                && <Grid item xs={12}>
                    <center>
                        <SnpButton variant="contained"
                            mbgcolor="#d73c23"
                            onClick={handleUpdateQuantityChanges}
                        >Update Quantity Changes</SnpButton>
                    </center>
                </Grid>
            }
           
            <Grid item xs={12}>
                {stockInfos && (
                    <StockItemGrid
                        key={choosePrices + chooseUnit + itemQuantity}
                        stockInfos={stockInfos}
                        cnvMap={cnvMap}
                        selectedId={stateStockMap}
                        unsetOperationAllowed={editClick && stateStockMap.size > 1 && !quanChangeBlock}
                        handleItemData={handleAddItem}
                        handleItemDelete={handleUnSelectItem}
                    />
                )}
            </Grid>
            
        </Grid>
    );
});

export default RenderDialogContent;





//Used For Multiple Item different amount increment from Dialog Box
// if (choosePrices === ChooseItemPrices.Stock_Prices) {
//     const val = editData ?  selectedId[data.stockRefNo] : "";
//     if (!isEmpty(val)) {
//         const stockMap = new Map(Object.entries(selectedId));
//         let removeQuan = 0;
//         stockMap.forEach((value, key) => {
//             if (key !== data.stockRefNo) {
//                 const extractedValue = getMatchedQuan(value);
//                 removeQuan += extractedValue;
//             }
//         });
//         const prevsQuan = getMatchedQuan(val);
//         const parsedItemQuan = Number(itemQuantity) - Number(removeQuan);
//         mQuantity = parsedItemQuan === prevsQuan ? parsedItemQuan + 1 : parsedItemQuan;
//     } else {
//         //Condition for new entry with change in quantity
//         if (editData) {
//             const stockMap = new Map(Object.entries(selectedId));
//             let removeQuan = 0;
//             stockMap.forEach((value, key) => {
//                 if (key !== data.stockRefNo) {
//                     const extractedValue = getMatchedQuan(value);
//                     removeQuan += extractedValue;
//                 }
//             });
//             mQuantity = Number(itemQuantity) - Number(removeQuan);
//         } else {
//             mQuantity = Number(itemQuantity);
//         }
//     }
    
// } else {
//     mQuantity = Number(itemQuantity);
//     editData = editData && mQuantity === defItemQuantity;
//     mQuantity = editData ? mQuantity + 1 : mQuantity;
// }