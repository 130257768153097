import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_SNP_COMPONENT } from "../components/billing/billing-ref/RestBillConstants";

const snpGlobalStateDetails = createSlice({
  name: "snpGlobalState",
  initialState: INITIAL_SNP_COMPONENT,
  reducers: {
    setSnpDialog: (state, action) => {
      state.showDialog = action.payload;
    },
    setSnpLoader: (state, action) => {
      state.isLoader = action.payload;
    },
    setSnpComponentDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSnpDialog, setSnpComponentDetails, setSnpLoader } = snpGlobalStateDetails.actions;
export const snpGlobalStatereducer = snpGlobalStateDetails.reducer;
//export default snpGlobalStateDetails.reducer;
