import { useMemo, useState } from "react";
import React from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import BillCustButton from "./BillCustButton";
// import BottomSheet from "../../global/BottomSheet";
// import BillDetailsSection from "./BillDetailsSection";
// import LeftBillScreenButtons from "./LeftBillScreenButtons";
// import HeaderForm from "./HeaderForm";
// import CustomerDetails from "./CustomerDetails";
// import PrintKotDialog from "./PrintKotDialog";
// import CustomerCopyBill from "./CustomerCopyBill";
// import { set } from "lodash";
// import BillItemDelete from "./BillItemDelete";
// import BillOtpDialog from "./BillOtpDialog";
// import DateChangeDialog from "./DateChangeDialog";
// import SnpButton from "../../global/SnpButton";
// import DismissButton from "../../global/DismissButton";

import BillItemList from "./BillItemList";
//import BillDetailsSection from "./BillDetailsSection";

const LeftBillComp = (
  {
    //isLoyaltyAvailable,
    //billDetails,
    // applyRoyaltyPrice,
    // isShowDelItemsAvailable,
    // handleShowDeletedItems,
    // isAddNewCustomerAvailable,
    // handleAddNewCustomer,
    // handleRefereshProductData,
    // createFilteredList,
    // handleProductSelectSinglePrice,
    // clearFilteredData,
    // filteredCatgProductData,
    // clearDues,
    // handleDialogClose,
    // renderClearDues,
    // renderDialogAction,
    // showItemEditDialog,
    // isEditItem,
    // renderEditItem,
    // dataGridComponent,
    // listbillingData,
    // roomDetails,
    // BillStatus,
    // InternalBillType,
    // TRIP_DELIVERED,
    // CART_INITIAL_STATE,
    // isPaid,
    // isSubmitButtonEnb,
    // setkotCopies,
    // colQuantityClasses,
  }
) => {
  // const isLoyaltyPriceAvailable = isLoyaltyAvailable && !isPaid;
  // const isDeleteItemsAvailable = isShowDelItemsAvailable && !isPaid;
  // const isAddNewCustomerAvailable = isAddNewCustomerAvailable && !isPaid;
  // const [printCustomerBill, setPrintCustomerBill] = useState(false);
  const [billDetails, setBillDetails] = useState({
    isGstAllowed: false,
    offerDenom: "No_Offer",
    offerAmt: "",
  });
  const [paymentDetails, setPaymentDetails] = useState({
    deliveryName: "",
    onlinePayMedium: "",
    onlinePay: "",
    cashPay: "",
  });
  const [billingData, setBillingData] = useState({
    homeDeliveryId: "",
    homeDeliveryOtp: "",
  });

  // const handleInputChange = (event) => {
  //   const { name, value, checked, type } = event.target;
  //   const newValue = type === "checkbox" ? checked : value;
  //   // setBillDetails((prevDetails) => ({
  //   //   ...prevDetails,
  //   //   [name]: newValue,
  //   // }));
  // };

  // const handleOnlineSelectChange = (event, newValue) => {
  //   setPaymentDetails((prevDetails) => ({
  //     ...prevDetails,
  //     onlinePayMedium: newValue,
  //   }));
  // };

  const isRoleAllowed = (role) => {
    // Implement your role checking logic here
    return true;
  };

  const SALESRIGHTS = "SALESRIGHTS";
  const OfferType = {
    No_Offer: "0",
    Flat: "1",
    Percentage: "2",
  };
  const ONLINEPAY = "ONLINEPAY";
  const CASHPAY = "CASHPAY";
  const InternalBillType = {
    Home: "Home",
  };
  const CART_INITIAL_STATE = {
    onlinePayMethod: ["Method1", "Method2"],
  };
  const TRIP_DELIVERED = "TRIP_DELIVERED";
  const BillStatus = "BillStatus";
  const roomDetails = {};
  const billUtils = {
    billingType: "Home",
  };


  const totalBillAmount = 1000;
  const taxes = 100;
  const discounts = 50;
  const netPayable = 1050;

  const renderNotesSection = ({
    billingData,
    roomDetails,
    BillStatus,
    InternalBillType,
    TRIP_DELIVERED,
  }) => <div>Notes Section Component</div>;

  // const renderEditItem = () => {
  //   return (
  //     <div>
  //       {dialogLoader && (
  //         <center>
  //           <Loader />
  //         </center>
  //       )}
  //       <Grid container spacing={0}>
  //         <Grid
  //           item
  //           xs={12}
  //           md={12}
  //           sm={12}
  //           lg={12}
  //           style={{ textAlign: "center" }}
  //         >
  //           {stockSingleData.productImage[0] && (
  //             <img
  //               alt=""
  //               style={{ height: "100px", width: "100px", borderRadius: "40%" }}
  //               src={
  //                 storeApiUrl.BASE_IMAGE_URL + stockSingleData.productImage[0]
  //               }
  //             />
  //           )}
  //           <br />
  //           <Typography variant="p">
  //             {"Cateogory : " + stockSingleData.catgName}
  //           </Typography>
  //           <br />
  //           <Typography variant="p" className="shop-details resto-price">
  //             {stockSingleData.ingredients}
  //           </Typography>
  //         </Grid>
  //         <Grid item xs={12} md={12} sm={12} lg={12}>
  //           <FormControl component="fieldset">
  //             <FormLabel component="legend">Rate Card</FormLabel>
  //             {/* <RadioGroup aria-label="rate-select" name="rate-select" value={billStockData.priceIndex} onChange={handleInputChange}>
  //                                                 {[...stockSingleData.price].map((data,index) => (
  //                                                     <div>
  //                                                         <FormControlLabel control={<Radio
  //                                                             value={index}
  //                                                             id={data.sAmt}
  //                                                         />}
  //                                                             label="" />
  //                                                         <Typography variant="p" className="shop-details shop-address" >{billStockData.name + (!isEmpty(data.name) ? "(" + data.name + ")" : "")}</Typography>
  //                                                         <br /> {"       "}
  //                                                         <Typography variant="p" className="shop-details resto-price" >Price : &#8377;{data.sAmt}</Typography>
  //                                                     </div>
  //                                                 ))}
  //                                             </RadioGroup> */}
  //             <RadioGroup
  //               aria-label="rate-select"
  //               name="rate-select"
  //               value={billStockData.priceIndex}
  //               onChange={handleInputChange}
  //             >
  //               <div>
  //                 <FormControlLabel
  //                   control={
  //                     <Radio value={0} id={stockSingleData.price.sAmt} />
  //                   }
  //                   label=""
  //                 />
  //                 <Typography variant="p" className="shop-details shop-address">
  //                   {billStockData.name +
  //                     (!isEmpty(stockSingleData.price.name)
  //                       ? "(" + stockSingleData.price.name + ")"
  //                       : "")}
  //                 </Typography>
  //                 <br /> {"       "}
  //                 <Typography variant="p" className="shop-details resto-price">
  //                   Price : &#8377;{stockSingleData.price.sAmt}
  //                 </Typography>
  //               </div>
  //             </RadioGroup>
  //           </FormControl>
  //         </Grid>
  //       </Grid>
  //       <br />
  //       {itemWiseOffer && (
  //         <div>
  //           <FormControl component="fieldset">
  //             <FormLabel component="legend">Any Offer</FormLabel>
  //             <RadioGroup
  //               row
  //               aria-label="offerDenom"
  //               name="offerDenom"
  //               value={billStockData.offerDenom.toString()}
  //               onChange={handleInputChange}
  //             >
  //               <FormControlLabel
  //                 value={OfferType.Flat.toString()}
  //                 control={<Radio />}
  //                 label="Discount In Flat"
  //               />
  //               <FormControlLabel
  //                 value={OfferType.Percentage.toString()}
  //                 control={<Radio />}
  //                 label="Discount In Percentage"
  //               />
  //               <FormControlLabel
  //                 value={OfferType.No_Offer.toString()}
  //                 control={<Radio />}
  //                 label="No Offer"
  //               />
  //             </RadioGroup>
  //           </FormControl>
  //           <br />
  //           <br />
  //         </div>
  //       )}

  //       <div style={{ paddingBottom: "3px" }}>
  //         <Grid container spacing={3}>
  //           {itemWiseOffer && billStockData.offerDenom > 1 && (
  //             <Grid item xs={12} md={6} sm={6} lg={6}>
  //               <TextField
  //                 id="offerAmt"
  //                 name="offerAmt"
  //                 variant="filled"
  //                 size="small"
  //                 label="Enter Offer Value"
  //                 value={billStockData.offerAmt.toString()}
  //                 onChange={handleInputChange}
  //                 style={{ width: "100%" }}
  //               />
  //             </Grid>
  //           )}
  //           <Grid item xs={12} md={6} sm={6} lg={6}>
  //             <TextField
  //               id="quantity"
  //               name="quantity"
  //               className={colQuantityClasses.input}
  //               type="number"
  //               inputMode="numeric"
  //               value={billStockData.quantity}
  //               variant="standard"
  //               size="small"
  //               sx={{
  //                 width: "100%",
  //                 justifyContent: "center",
  //                 alignContent: "center",
  //                 "& .MuiInputBase-input": {
  //                   textAlign: "center",
  //                 },
  //               }}
  //               label="Enter Quantity"
  //               onChange={handleInputChange}
  //               InputProps={{
  //                 min: "0",
  //                 startAdornment: (
  //                   <InputAdornment position="start">
  //                     <IconButton
  //                       onClick={() => {
  //                         let data = billStockData.quantity + 1;
  //                         if (Number(stockSingleData.unitCount) > 0) {
  //                           if (data > stockSingleData.unitCount) {
  //                             setSnpComponent({
  //                               ...snpComponent,
  //                               snackBarOpen: true,
  //                               snackBarMsg:
  //                                 "Exceeds Total Count i.e " +
  //                                 stockSingleData.liveStockCount,
  //                             });
  //                           } else {
  //                             updateStockPrice(
  //                               stockSingleData.price.sAmt,
  //                               billStockData.offerDenom,
  //                               billStockData.offerAmt,
  //                               data,
  //                               billStockData.priceIndex
  //                             );
  //                           }
  //                         } else {
  //                           updateStockPrice(
  //                             stockSingleData.price.sAmt,
  //                             billStockData.offerDenom,
  //                             billStockData.offerAmt,
  //                             data,
  //                             billStockData.priceIndex
  //                           );
  //                         }
  //                       }}
  //                     >
  //                       <AddIcon
  //                         color="success"
  //                         sx={{
  //                           fontSize: "2rem",
  //                         }}
  //                       />
  //                     </IconButton>
  //                   </InputAdornment>
  //                 ),
  //                 endAdornment: (
  //                   <InputAdornment position="end">
  //                     <IconButton
  //                       onClick={() => {
  //                         if (billStockData.quantity > 0) {
  //                           let data = billStockData.quantity - 1;
  //                           if (data < 0) data = 0;
  //                           if (Number(stockSingleData.unitCount) > 0) {
  //                             if (data > stockSingleData.unitCount) {
  //                               setSnpComponent({
  //                                 ...snpComponent,
  //                                 snackBarOpen: true,
  //                                 snackBarMsg:
  //                                   "Exceeds Total Count i.e " +
  //                                   stockSingleData.liveStockCount,
  //                               });
  //                             } else {
  //                               updateStockPrice(
  //                                 stockSingleData.price.sAmt,
  //                                 billStockData.offerDenom,
  //                                 billStockData.offerAmt,
  //                                 data,
  //                                 billStockData.priceIndex
  //                               );
  //                             }
  //                           } else {
  //                             updateStockPrice(
  //                               stockSingleData.price.sAmt,
  //                               billStockData.offerDenom,
  //                               billStockData.offerAmt,
  //                               data,
  //                               billStockData.priceIndex
  //                             );
  //                           }
  //                         }
  //                       }}
  //                     >
  //                       <RemoveIcon
  //                         color="warning"
  //                         sx={{
  //                           fontSize: "2rem",
  //                         }}
  //                       />
  //                     </IconButton>
  //                   </InputAdornment>
  //                 ),
  //               }}
  //             />
  //           </Grid>
  //         </Grid>
  //       </div>
  //       <BrownTextTypography variant="p" sx={{ color: "#00333E", pb: 1 }}>
  //         Selling Price : {billStockData.quantity}
  //         {parseInt(billStockData.offerDenom) === OfferType.No_Offer
  //           ? " x " + billStockData.price + " = "
  //           : parseInt(billStockData.offerDenom) === OfferType.Flat
  //           ? " x (" +
  //             billStockData.price +
  //             " - Flat " +
  //             billStockData.offerAmt +
  //             ") = "
  //           : " x (" +
  //             billStockData.price +
  //             " - " +
  //             billStockData.offerAmt +
  //             "%) = "}{" "}
  //         &#8377; {Number(billStockData.finalprice).toFixed(2)}
  //         <br />
  //         {"(Excluded GST@" +
  //           (parseFloat(stockSingleData.sgst) +
  //             parseFloat(stockSingleData.cgst)) +
  //           "%)"}
  //       </BrownTextTypography>
  //       <Grid container spacing={0}>
  //         <Grid item md={12} xs={12} lg={12} sm={12}>
  //           <TextField
  //             id="note"
  //             name="note"
  //             variant="filled"
  //             size="small"
  //             rows={3}
  //             multiline
  //             onChange={handleInputChange}
  //             label="Note(If Any)"
  //             value={billStockData.note}
  //             sx={{ width: "100%" }}
  //           />
  //         </Grid>
  //       </Grid>
  //     </div>
  //   );
  // };

  // const renderClearDues = () => {
  //   return (
  //     <>
  //       <Grid
  //         container
  //         spacing={1}
  //         justifyContent="center"
  //         alignItems="center"
  //         sx={{
  //           padding: 0,
  //           margin: 0,
  //         }}
  //       >
  //         <Grid item md={7} xs={12} sm={12}>
  //           <Typography variant="p" color="#02056e" fontWeight="bold">
  //             Remaining Amt To Paid ₹{billDetails.pendingAmt}
  //           </Typography>
  //         </Grid>
  //         <Grid item md={4} sm={12} xs={12}>
  //           <TextField
  //             style={{ width: "180px" }}
  //             value={partialPay.partialAmt}
  //             id="partialAmt"
  //             name="partialAmt"
  //             disabled={
  //               listbillingData.billStatus === BillStatus.PAID ||
  //               listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY ||
  //               listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY
  //             }
  //             label="Enter Amount Paid"
  //             variant="filled"
  //             size="small"
  //             onChange={handlePartialPayChange}
  //           />
  //         </Grid>
  //         <Grid item md={4} xs={12} sm={12}>
  //           <center>
  //             <Typography variant="p" color="#3e0109" fontWeight="bold">
  //               Current Remaining Dues ₹{billDetails.currDues}
  //             </Typography>
  //           </center>
  //         </Grid>

  //         <Grid item md={12} xs={12} sm={12}>
  //           <FormLabel id="paymentMethod">
  //             {" "}
  //             <p>
  //               <u>Payment Methods:</u>{" "}
  //             </p>
  //           </FormLabel>
  //         </Grid>
  //         <Grid item xs={12} md={2} sm={6}>
  //           <FormControlLabel
  //             disabled={listbillingData.billStatus === BillStatus.PAID}
  //             name="onlinePay"
  //             value={ONLINEPAY}
  //             checked={!isEmpty(partialPay.onlineBillPaidThrough)}
  //             control={<Checkbox />}
  //             label="Online"
  //             onChange={handlePartialPayChange}
  //           />
  //         </Grid>
  //         <Grid item xs={12} md={5} sm={6}>
  //           <Autocomplete
  //             id="pOnline-select"
  //             options={CART_INITIAL_STATE.onlinePayMethod}
  //             autoHighlight
  //             variant="filled"
  //             size="small"
  //             fullWidth
  //             disabled={
  //               listbillingData.billStatus === BillStatus.PAID ||
  //               isEmpty(partialPay.onlineBillPaidThrough)
  //             }
  //             onChange={handleSelectChange}
  //             isOptionEqualToValue={(option, value) => {
  //               if (!option) return false;
  //               if (!value) return false;
  //               return option === value;
  //             }}
  //             getOptionLabel={(option) => option || ""}
  //             value={partialPay.onlineBillPaidThrough}
  //             defaultValue={partialPay.onlineBillPaidThrough}
  //             renderOption={(props, option) => (
  //               <Box component="li" {...props} key={`${option}`}>
  //                 {option}
  //               </Box>
  //             )}
  //             renderInput={(params) => (
  //               <TextField
  //                 {...params}
  //                 label="Search Online Pay Method"
  //                 inputProps={{
  //                   ...params.inputProps,
  //                 }}
  //               />
  //             )}
  //           />
  //         </Grid>
  //         <Grid item xs={12} md={5} sm={6}>
  //           <TextField
  //             id="onlinePayAmt"
  //             name="onlinePayAmt"
  //             variant="filled"
  //             size="small"
  //             fullWidth
  //             disabled={enableDisableOnlineCashPay()}
  //             label="Enter Online Amount"
  //             value={partialPay.onlinePay.toString()}
  //             onChange={handlePartialPayChange}
  //           />
  //         </Grid>
  //         <Grid item xs={12} md={2} sm={6}>
  //           <FormControlLabel
  //             name="cashPay"
  //             disabled={listbillingData.billStatus === BillStatus.PAID}
  //             value={CASHPAY}
  //             checked={partialPay.cashBillPaidThrough === CASHPAY}
  //             control={<Checkbox />}
  //             label="Cash"
  //             onChange={handlePartialPayChange}
  //           />
  //         </Grid>
  //         <Grid item xs={12} md={5} sm={6}>
  //           <TextField
  //             id="cashPayAmt"
  //             name="cashPayAmt"
  //             variant="filled"
  //             size="small"
  //             fullWidth
  //             disabled={enableDisableOnlineCashPay()}
  //             label="Enter Cash Amount"
  //             value={partialPay.cashPay.toString()}
  //             onChange={handlePartialPayChange}
  //           />
  //         </Grid>
  //         <Grid item xs={12} md={5} sm={6}>
  //           <TextField
  //             id="cashTender"
  //             name="cashTender"
  //             variant="filled"
  //             size="small"
  //             fullWidth
  //             disabled={
  //               listbillingData.billStatus === BillStatus.PAID ||
  //               isEmpty(partialPay.cashBillPaidThrough)
  //             }
  //             label="Enter Cash Tender"
  //             value={partialPay.cashTendered}
  //             onChange={handlePartialPayChange}
  //           />
  //         </Grid>
  //         <Grid item xs={12} md={5} sm={6}>
  //           {partialPay.cashReturn > 0.0 && (
  //             <Typography
  //               variant="subtitle4"
  //               component="div"
  //               style={{ color: "#A52A2A" }}
  //             >
  //               Cash Return: ₹{partialPay.cashReturn}
  //             </Typography>
  //           )}
  //         </Grid>
  //       </Grid>
  //       {billDetails.lpatialAmt.length > 0 && (
  //         <TableContainer component={Paper}>
  //           <Table aria-label="simple table" className="rate_card">
  //             <TableHead>
  //               <TableRow>
  //                 <TableCell align="center">Paid On</TableCell>
  //                 <TableCell align="center">Accept By</TableCell>
  //                 <TableCell align="center">Amount</TableCell>
  //                 <TableCell align="center">Paid Through</TableCell>
  //               </TableRow>
  //             </TableHead>
  //             <TableBody>
  //               {billDetails.lpatialAmt.map((val, index) => {
  //                 return (
  //                   <TableRow
  //                     key={index}
  //                     className="rate_card--row"
  //                     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  //                   >
  //                     <TableCell align="center">
  //                       <p
  //                         style={{
  //                           whiteSpace: "pre-wrap",
  //                           overflowWrap: "break-word",
  //                         }}
  //                       >
  //                         {val.paidOn}
  //                       </p>
  //                     </TableCell>
  //                     <TableCell align="center">{val.paidAcceptBy}</TableCell>
  //                     <TableCell align="center">{val.partialAmt}</TableCell>
  //                     <TableCell align="center">
  //                       <p
  //                         style={{
  //                           whiteSpace: "pre-wrap",
  //                           overflowWrap: "break-word",
  //                         }}
  //                       >
  //                         {getPartialPaidThrough(val)}
  //                       </p>
  //                     </TableCell>
  //                   </TableRow>
  //                 );
  //               })}
  //             </TableBody>
  //           </Table>
  //         </TableContainer>
  //       )}
  //     </>
  //   );
  // };

  // const renderDialogAction = () => {
  //   return (
  //     <>
  //       {clearDues && (
  //         <SnpButton
  //           disabled={listbillingData.billStatus === BillStatus.PAID}
  //           variant="contained"
  //           color="primary"
  //           onClick={updatePartial}
  //         >
  //           Submit
  //         </SnpButton>
  //       )}
  //       {showItemEditDialog && !isPaid && (
  //         <SnpButton
  //           variant="contained"
  //           color="primary"
  //           onClick={handleDialogSubmit}
  //         >
  //           {isEditItem ? "Update" : "Add"}
  //         </SnpButton>
  //       )}
  //       <DismissButton
  //         variant="contained"
  //         color="primary"
  //         onClick={handleDialogClose}
  //       >
  //         Cancel
  //       </DismissButton>
  //     </>
  //   );
  // };

  // const renderBillDetailsSection = () => {
  //   return (
  //     <div>
  //       <BillDetailsSection
  //         // Props for BillOptionsSection
  //         isPaid={false}
  //         isRoleAllowed={isRoleAllowed}
  //         salesRights={SALESRIGHTS}
  //         billDetails={billDetails}
  //         handleInputChange={handleInputChange}
  //         offerType={OfferType}
  //         renderHomeDeliveryAutocomplete={renderHomeDeliveryAutocomplete}
  //         isOtherHomeDelivery={false}
  //         deliveryNameOther=""
  //         paymentDetails={paymentDetails}
  //         deliveryCharge=""
  //         hotelDeliveryTransaction={false}
  //         billingData={billingData}
  //         setBillingData={setBillingData}
  //         renderTripStatusAutoComplete={renderTripStatusAutoComplete}
  //         renderRiderSelectAutocomplete={renderRiderSelectAutocomplete}
  //         onlinePay={ONLINEPAY}
  //         isOnlinePayEnabled={true}
  //         handleOnlineSelectChange={handleOnlineSelectChange}
  //         cashPay={CASHPAY}
  //         isCashPayEnabled={true}
  //         roomDetails={roomDetails}
  //         billStatus={BillStatus}
  //         tripDelivered={TRIP_DELIVERED}
  //         cartInitialState={CART_INITIAL_STATE}
  //         billUtils={billUtils}
  //         internalBillType={InternalBillType}
  //         // Props for BillSummary
  //         totalBillAmount={totalBillAmount}
  //         taxes={taxes}
  //         discounts={discounts}
  //         netPayable={netPayable}
  //         otherSummaryProps={
  //           {
  //             /* Add any other props for BillSummary here */
  //           }
  //         }
  //       />
  //     </div>
  //   );
  // };
  
  
  
  return (
    <>
      {/* <DateChangeDialog showDialog={dateChange} setShowDialog={setDateChange} />
      <BillOtpDialog
        showDialog={showGenOtpDialog}
        setShowDialog={setShowGenOtpDialog}
      />
      <BillItemDelete
        showDialog={showDeleteItemDialog}
        setShowDialog={setShowDeleteItemDialog}
        productId={productId}
      />
      <PrintKotDialog />
      <HeaderForm />
      <CustomerDetails />
      <CustomerCopyBill
        showDialog={printCustomerBill}
        // billNo={billNo}
        // printDetails={printDetails}
        setShowDialog={setPrintCustomerBill}
      />
      <BillCustButton
        isLoyaltyAvailable={isLoyaltyAvailable}
        billDetails={billDetails}
        applyRoyaltyPrice={applyRoyaltyPrice}
        isShowDelItemsAvailable={isShowDelItemsAvailable}
        handleShowDeletedItems={handleShowDeletedItems}
        isAddNewCustomerAvailable={isAddNewCustomerAvailable}
        handleAddNewCustomer={handleAddNewCustomer}
      /> */}

      {/* <BottomSheet
        handleRefereshProductData={handleRefereshProductData}
        createFilteredList={createFilteredList}
        handleProductSelectSinglePrice={handleProductSelectSinglePrice}
        handleDialogClose={clearFilteredData}
        filteredCatgProductData={filteredCatgProductData}
      /> */}

      {/* <DialogBox
        showDialog={clearDues}
        title="Partial Bill Update"
        onClose={handleDialogClose}
        dialogContent={renderClearDues}
        dialogActions={renderDialogAction}
      />

      <DialogBox
        showDialog={showItemEditDialog}
        title={isEditItem ? "Update Item" : "Add Item"}
        onClose={handleDialogClose}
        dialogContent={renderEditItem}
        dialogActions={renderDialogAction}
      /> */}

      <div>
        <BillItemList />
      </div>

        <div>
      {/* <BillDetailsSection
          isPaid={false}
          isRoleAllowed={isRoleAllowed}
          salesRights={SALESRIGHTS}
          billDetails={billDetails}
          handleInputChange={handleInputChange}
          offerType={OfferType}
          renderHomeDeliveryAutocomplete={renderHomeDeliveryAutocomplete}
          isOtherHomeDelivery={false}
          deliveryNameOther=""
          paymentDetails={paymentDetails}
          deliveryCharge=""
          hotelDeliveryTransaction={false}
          billingData={billingData}
          setBillingData={setBillingData}
          renderTripStatusAutoComplete={renderTripStatusAutoComplete}
          renderRiderSelectAutocomplete={renderRiderSelectAutocomplete}
          onlinePay={ONLINEPAY}
          isOnlinePayEnabled={true}
          handleOnlineSelectChange={handleOnlineSelectChange}
          cashPay={CASHPAY}
          isCashPayEnabled={true}
          roomDetails={roomDetails}
          billStatus={BillStatus}
          tripDelivered={TRIP_DELIVERED}
          cartInitialState={CART_INITIAL_STATE}
          billUtils={billUtils}
          internalBillType={InternalBillType}
          Props for BillSummary
          totalBillAmount={totalBillAmount}
          taxes={taxes}
          discounts={discounts}
          netPayable={netPayable}
          
      /> */}
        </div>
      {/* {!isPaid && CART_INITIAL_STATE.kotCopiesAllowed && (
        <div>
          <center>
            <TextField
              id="dkotCopies"
              name="dkotCopies"
              type="number"
              inputMode="numeric"
              disabled
              value={listbillingData.kotCopies || 1}
              variant="outlined"
              helperText="KOT Copies"
              size="small"
              sx={{
                justifyContent: "center",
                alignContent: "center",
                "& .MuiInputBase-input": { textAlign: "center" },
                width: { xs: "60%", sm: "30%", md: "20%", lg: "20%" },
                padding: { xs: "5px", sm: "5px", md: "5px", lg: "5px" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setkotCopies(true)}>
                      <AddIcon color="success" sx={{ fontSize: "2rem" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setkotCopies(false)}>
                      <RemoveIcon color="warning" sx={{ fontSize: "2rem" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </center>
        </div>
      )} */}

      <div>
        {/* <LeftBillScreenButtons
          billingData={billingData}
          setBillingData={setBillingData}
          setIsPaid={setIsPaid}
          handleRefereshProductData={handleRefereshProductData}
          handleGenerateBill={handleGenerateBill}
        /> */}
      </div>
    </>
  );
};

export default LeftBillComp;
