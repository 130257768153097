import React, { useLayoutEffect, useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import {
  Grid,
  TextField,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
  ListItemButton,
  Typography,
  Paper,
  Card,
} from "@mui/material";
import List from "@mui/material/List";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListIcon from '@mui/icons-material/List';
import InventoryIcon from '@mui/icons-material/Inventory';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {
  GET_CONVERSIONTYPE_DATA,
  GET_CATEOGORY_DATA,
  GET_PRODUCT_DATA,
  SET_PRODUCT_DATA,
  getPreUrlAccordingToCart,
  SUCCESS,
  ItemStatus,
  CART_INITIAL_STATE, isRoleAllowed, INVENTORYCREATE, CartDets, DELETEINVENTORY, FAILED, SESSIONEXPIRED,
  printDebugLog,
  printInfoLog,
  isFeatureAllowed,
  STOCKMGMT,
  STOCKVIEW,
  isEmpty,
  SizeType,
  InventoryType,
  PersonType,
  isNumberEmpty,
  CUSTOMERAPP,
  GETPRODDETAILS
} from "../../constants/constant";
import ImageUploadService from "../../service/imageUploadService";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import { useSelector } from "react-redux";
import "./Product.css";
import { snpAxios } from '../global/api';
import SnpComponent from "../global/SnpComponent";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import { useNavigate } from "react-router-dom";
import ThreeDText from "../global/ThreeDText";
import EnterButton from "../global/EnterButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../global/loader/Loader";
import uiUtilInstance from "../../utilities/uiUtil";

const INITIAL_CATEOGORY = {
  catgId: "",
  catgName: "",
};
export default function ProductEntry() {
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const navigate = useNavigate();
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEditProduct, setEditProduct] = useState(false);
  const [preDialog, setPreDialog] = useState(false);
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [imageSelectedError, setImageSelectedError] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCategory, setSelectedcategory] = useState(INITIAL_CATEOGORY);
  const [selectedImage, setSelectedImage] = useState([]);
  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: false,
    iconContent: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: null,
    handleSnpCancel: null,
    gui: "",
  };

  const INITIAL_SINGLE_PRODUCT = {
    catgId: "",
    catgName: "",
    itemStatus: ItemStatus.ACTIVE,
    prdName: "",
    composition: [],
    stockUsage: [],
    stockSideEffects: [],
    stockStorage: "",
    brandName: "",
    prdId: "",
    cnvId: "",
    hsnCode: "",
    rack_no: "",
    unitName: "",
    unitCount: 1,
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 0,
    productImage: [],
    allowedDelete: false,
    itemStatusStr: "",
    itemShortCode: "",
    sizeTypeStr: "",
    sizeType: SizeType.None,
    inventoryTypeStr: "",
    inventoryType: InventoryType.Groceries,
    personTypeStr: "",
    personType: PersonType.None,
    aboutItem:"",
  };

  const INITIAL_CONVERSION_SINGLE_DATA = {
    id: "",
    unitName: "",
    unitRestrictions: false,
    smallUnitName: "",
    smallUnitCount: 1,
    cnvDetails: "",
    decimalSmRestrictions: false,
  };

  const INITIAL_COLOR_MODEL = {
    colorId:"",
    colorDetails:"",
  }

  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
  const [productSingleData, setSingleProductData] = useState(INITIAL_SINGLE_PRODUCT);
  const [createUnit, setCreateUnit] = useState(false);
  const [dialogLoader, setDialogLoader] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [conversionSingleData, setConversionSingleData] = useState(INITIAL_CONVERSION_SINGLE_DATA);
  const [conversionTypeData, setConversionTypeData] = useState([]);
  const [listComposition, setListComposition] = useState([]);

  const [inputUsageValue, setInputUsageValues] = useState('');
  const [listStockUsage, setListStockUsage] = useState([]);
  const [inputSideEffects, setInputSideEffects] = useState('');
  const [listSideEffects, setListSideEffects] = useState([]);
  const [listColor,setListColor] = useState([]);
  const [color,setColor] = useState(INITIAL_COLOR_MODEL);

  const [suggestedItem, setSuggestedItem] = useState([]);
  const [suggestedItemDeleted, setSuggestedItemDeleted] = useState([]);
  const [suggestionAvail, setSuggAvail] = useState(false);
  const [itemData, setItemData] = useState([]);

  const handleConversionChange = (event, option) => {
    if (option != null) {
      // console.log("handleConversionChange", option);
      if (option.id === "cr") {
        setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
        setCreateUnit(true);
      } else {
        if (createUnit)
          setCreateUnit(false);
        setConversionSingleData(option);
      }
    } else {
      setCreateUnit(false);
      setConversionSingleData(option);
    }
  };

  // const getCustomUnitDetails = (option) => {
  //   let data = "";
  //   if (option.id === 'cr')
  //     data = option.unitName;
  //   else {
  //     if (option.unitName) {
  //       if (option.unitRestrictions) {
  //         if (option.smallUnitCount > 0 && option.smallUnitName)
  //           data = "Only " + option.unitName + "(" + option.smallUnitCount + " " + option.smallUnitName + ")";
  //         else
  //           data = "Only " + option.unitName;
  //       } else {
  //         data = option.unitName + "=" + option.smallUnitCount + " " + option.smallUnitName;
  //       }
  //     }
  //   }
  //   return data;
  // };
  const handleCoversionUpdate = (event) => {
    const { name, value } = event.target;
    //console.log("handleCoversionUpdate", name, value);
    if (name === 'unitRestrictions') {
      const previousCheckedValue = conversionSingleData.unitRestrictions;
      setConversionSingleData({
        ...conversionSingleData,
        unitRestrictions: !previousCheckedValue,
      });
    } else if (name === 'decimalSmRestrictions') {
      const previousCheckedValue = conversionSingleData.decimalSmRestrictions;
      setConversionSingleData({
        ...conversionSingleData,
        decimalSmRestrictions: !previousCheckedValue,
      });
    } else if (name === 'smallUnitCount') {
      if (/^[0-9]*\.?[0-9]*$/.test(value)) {
        setConversionSingleData({
          ...conversionSingleData,
          smallUnitCount: value,
        });
      }
    } else {
      if (value === "" || /^[a-zA-Z]*$/.test(value)) {
        setConversionSingleData({
          ...conversionSingleData,
          [name]: value,
        });
      }
    }
  };

  const handleSelectColorChange = (event, colorModel) => {
    //console.log("handleSelectColorChange", catg);
    if (colorModel != null) {
      setColor(colorModel);
    } else {
      setColor(INITIAL_COLOR_MODEL)
    }
  };

  const handleSelectChange = (event, catg) => {
    //console.log("handleSelectChange", catg);
    if (catg != null) {
      setSelectedcategory(catg);
    } else {
      setSelectedcategory(INITIAL_CATEOGORY)
    }
  };
  const handleInputChange = (event) => {
    //console.log("handleInputChange",event);
    const { name, value } = event.target;

    if (name === "imgPath") {
      const imageList = event.target.files;
      printDebugLog("imgPath=>", imageList);
    
      if (imageList && imageList.length > 0) {
        setImageSelectedError(false);
    
        const newImages = [];
        const newDocuments = [];
    
        for (let i = 0; i < imageList.length; i++) {
          const reader = new FileReader();
          const file = imageList[i];
    
          newDocuments.push(file); // Store file references for Uploaded Documents
    
          reader.onloadend = function () {
            newImages.push(reader.result);
    
            // Ensure all images are loaded before updating state
            if (newImages.length === imageList.length) {
              setSelectedImage((prev) => [...(prev || []), ...newImages]);
              setUploadedDocument((prev) => [...(prev || []), ...newDocuments]);
            }
          };
    
          reader.readAsDataURL(file);
        }
      } else {
        setImageSelectedError(true);
      }
    }
     else {
      setSingleProductData({
        ...productSingleData,
        [name]: value,
      });
    }
    //console.log("setSingleProductData-> ",productSingleData);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value.length >= 3) {
      getProductItemDetails(value);
    }
  };

  const handleInputChangeValue = (event) => {
    if (event) {
      //printDebugLog("handleInputChangeValue",event.target.value)
      setInputValue(event.target.value);
    }
  }

  

  const handleImageUpload = async () => {
    setFormSubmitted(true);
    printDebugLog("handleImageUpload", productSingleData);
    if (isEmpty(conversionSingleData.id) && !createUnit) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Custom Unit Type cannot be null",
        snackBarOpen: true,
      })
      return;
    }
    if (!isValid()) {
      return;
    }
    let isError = false;
    // if (!uploadedDocument || uploadedDocument.size > 5242880 || !(uploadedDocument.type.includes("image"))) {    //5248256
    //   setImageSelectedError(true);
    //   isError = true;
    // }
    if (!uploadedDocument || uploadedDocument.length === 0) {
      isError = true;
    }
    if (!isError) {
      setDataLoaded(false);
      setShowDialog(false);
      setFormSubmitted(false);
      for (let i = 0; i < uploadedDocument.length; i++) {
        let formData = new FormData();
        formData.append('fnm', CART_INITIAL_STATE.apiToken);
        formData.append("file", uploadedDocument[i]);
        await ImageUploadService.upload(storeApiUrl.BACKEND_BASE_URL, getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
          if (response.data.folderName) {
            printDebugLog("folderName", response.data.folderName);
            if (!productSingleData.productImage)
              productSingleData.productImage = [];
            productSingleData.productImage.push(response.data.folderName);
            if (i === uploadedDocument.length - 1) {
              handleProductEntry(ItemStatus.ACTIVE);
            }
          } else {
            printInfoLog("response.data", response.data);
          }
        }
        ).catch(error => {
          handleProductEntry(ItemStatus.ACTIVE);
          printInfoLog("handleImageUpload = > ", error);
          // window.location.href = "#/error";
        });
      }
    } else {
      handleProductEntry(ItemStatus.ACTIVE);
    }
  };

  // const getColumnsCoversion = (val) => {
  //   if ((val.unitCount === val.smallUnitCount) && (val.unitName && val.smallUnitName)) {
  //     return val.unitName;
  //   } else {
  //     return val.unitName + "(" + val.smallUnitCount + " " + val.smallUnitName + ")";
  //   }
  // }

  const columns = [
    {
      field: "edit",
      headerName: "",
      width: 75,
      renderCell: (cellValues) => {
        return (
          <SnpButton variant="contained"
            mbgcolor="#538796"
            mpadding="1px"
            onClick={(event) => handleCellClick(event, cellValues)}
          >Edit</SnpButton>
        );
      }
    },
    {
      field: "productImage",
      headerName: "Icon",
      width: 100,
      renderCell: (rowData) => (
        <div>
          {
            isEmpty(rowData.row.productImage) ?
              <ImageNotSupportedIcon style={{ height: "80px", width: "80px", borderRadius: "20%" }} /> : <img
                alt=""
                style={{ height: "80px", width: "80px", borderRadius: "20%" }}
                src={rowData.row.productImage && storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
              ></img>
          }

        </div>
      ),
    },
    {
      field: 'catgName', headerName: 'Category', width: 130, valueGetter: (params) => params.row.catgName, renderCell: (params) => (
        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
      ),
    },
    { field: 'inventoryTypeStr', headerName: 'Type', width: 140 },
    {
      field: 'prdName', headerName: 'Item', width: 150, valueGetter: (params) => params.row.prdName, renderCell: (params) => (
        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
      ),
    },
    {
      field: 'brandName', headerName: 'Brand', width: 200
    },
    {
      field: 'cnvDetails', headerName: 'Unit Details', width: 250,
    },
    {
      field: 'itemStatusStr', headerName: 'Status', width: 120, valueGetter: (params) => params.row.itemStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    }
  ];

  const compositionColumns = [
    {
      field: "edit",
      headerName: "",
      width: 75,
      renderCell: (cellValues) => {
        return (
          <SnpButton variant="contained"
            mbgcolor="#538796"
            mpadding="1px"
            onClick={(event) => handleCellClick(event, cellValues)}
          >Edit</SnpButton>
        );
      }
    },
    {
      field: "productImage",
      headerName: "Icon",
      width: 100,
      renderCell: (rowData) => (
        <div>
          {
            isEmpty(rowData.row.productImage) ?
              "-" : <img
                alt=""
                style={{ height: "80px", width: "80px", borderRadius: "20%" }}
                src={rowData.row.productImage && storeApiUrl.BACKEND_BASE_URL + "files/" + rowData.row.productImage[0]}
              ></img>
          }

        </div>
      ),
    },
    {
      field: 'catgName', headerName: 'Category', width: 130, valueGetter: (params) => params.row.catgName, renderCell: (params) => (
        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
      ),
    },
    { field: 'inventoryTypeStr', headerName: 'Type', width: 110 },
    {
      field: 'prdName', headerName: 'Item', width: 200, valueGetter: (params) => params.row.prdName, renderCell: (params) => (
        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{params.value}</p>
      ),
    },
    {
      field: 'brandName', headerName: 'Brand', width: 200, valueGetter: (params) => params.row.brandName, renderCell: (params) => (
        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: "break-word" }}>{params.value}</p>
      ),
    },
    {
      field: 'compositionStr', headerName: 'Composition', width: 220, valueGetter: (params) => params.row.compositionStr, renderCell: (params) => (
        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', wordBreak: "break-word" }}>{params.value}</p>
      ),
    },
    {
      field: 'cnvDetails', headerName: 'Unit Details', width: 250,
    },
    {
      field: 'itemStatusStr', headerName: 'Status', width: 120, valueGetter: (params) => params.row.itemStatusStr, renderCell: (params) => (
        <span style={{ color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
      ),
    }
  ];


  const handleCellClick = (param, event) => {
    printDebugLog("setSingleproductData : ", event.row);
    setSelectedcategory(prev=> ({
      catgId: event.row.catgId,
      catgName: event.row.catgName
    }));
    setColor(prev=> ({
      ...prev,colorId:event.row.colorId,
      colorDetails:event.row.colorDetails,
    }))
    setConversionSingleData(prev=> ({
      ...conversionSingleData,
      id: event.row.cnvId,
      unitName: event.row.unitName,
      unitCount: event.row.unitCount,
      unitRestrictions: event.row.unitRestrictions,
      smallUnitName: event.row.smallUnitName,
      smallUnitCount: event.row.smallUnitCount,
      cnvDetails: event.row.cnvDetails,
    }));
    setSingleProductData({
      ...productSingleData,
      itemStatus: event.row.itemStatus,
      prdName: event.row.prdName,
      brandName: event.row.brandName,
      prdId: event.row.prdId,
      productImage: event.row.productImage,
      catgId: event.row.catgId,
      catgName: event.row.catgName,
      cnvId: event.row.id,
      aboutItem:event.row.aboutItem,
      hsnCode: event.row.hsnCode,
      rack_no: event.row.rack_no,
      unitName: event.row.unitName,
      unitCount: event.row.unitCount,
      unitRestrictions: event.row.unitRestrictions,
      smallUnitName: event.row.smallUnitName,
      smallUnitCount: event.row.smallUnitCount,
      composition: isEmpty(event.row.composition) ? [] : event.row.composition,
      allowedDelete: event.row.allowedDelete,
      itemShortCode: event.row.itemShortCode,
      inventoryType: event.row.inventoryType,
      sizeType: event.row.sizeType,
      personType: event.row.personType,
      stockSideEffects: isEmpty(event.row.stockSideEffects) ? [] : event.row.stockSideEffects,
      stockStorage: event.row.stockStorage,
      stockUsage: isEmpty(event.row.stockUsage) ? [] : event.row.stockUsage,
    })
    if (event.row.listItemSuggestion.length > 0) {
      setSuggAvail(true);
      setSuggestedItem(event.row.listItemSuggestion);
    }
    setPreDialog(true);
    setShowDialog(true);
    setEditProduct(true);
  };


  const clearProductData = () => {
    setCreateUnit(false);
    setConversionSingleData(INITIAL_CONVERSION_SINGLE_DATA);
    setSelectedcategory(INITIAL_CATEOGORY)
    setSingleProductData(INITIAL_SINGLE_PRODUCT);
    setSelectedImage([]);
    setUploadedDocument([]);
    clearSuggestion();
  }

  const handleDeleteInternalImage = (index) => {
    printDebugLog("handleDeleteInternalImage",index);
    setSelectedImage((prev) => prev.filter((_, i) => i !== index));
    setUploadedDocument((prev) => prev.filter((_, i) => i !== index));
  };

  const getConversionData = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CONVERSIONTYPE_DATA;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        createNew: true,
      }
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        if (response.data.status === SUCCESS) {
          setConversionTypeData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        printInfoLog("error : ", error);
        //window.location.href = "#/error";
      });
  };

  const handleDeleteImage = (index) => {
    let path = productSingleData.productImage[index];
    let cUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}delete-image-mart?productHash=${productSingleData.prdId}&path=${path}`;
    let data = {};
    setDialogLoader(true);
    printDebugLog("handleDeleteImage<=", cUrl);
    axios.get(
      cUrl,
      data
    )
      .then((response) => {
        printDebugLog("handleDeleteImage=>", response.data);
        setDialogLoader(false);
        if (response.data.status === SUCCESS) {
          // If item is found, create a new array without it
          if (index !== -1) {
            const images = productSingleData.productImage;
            const updatedItems = [...images.slice(0, index), ...images.slice(index + 1)];
            setSingleProductData({
              ...productSingleData,
              productImage: updatedItems,
            })
            setSnpComponent({
              ...snpComponent, snackBarMsg: "Image removed successfull ... Updating Items",
              snackBarOpen: true,
            })
            //setShowDialog(false);
            getProductDetails();

          }
        }
      }).catch((error) => {
        setDialogLoader(false);
        printInfoLog("error : ", error);
        //window.location.href = "#/error";
      });
  }

  const handleProductEntry = async (productStatus) => {
    if (isEmpty(conversionSingleData.id) && !createUnit) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Custom Unit Type Cannot be empty",
        snackBarOpen: true,
      })
      return;
    }
    if (createUnit && !conversionSingleData.unitRestrictions && isNumberEmpty(conversionSingleData.smallUnitCount)) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Empty Small Count for Custom Unit",
        snackBarOpen: true,
      })
      return;
    }
    if (isEmpty(selectedCategory.catgId)) {
      setSnpComponent({
        ...snpComponent, snackBarMsg: "Cateogory cannot be empty",
        snackBarOpen: true,
      })
      return;
    }
    setDataLoaded(false);
    setShowDialog(false);
    setSelectedImage([]);
    setFormSubmitted(false);
    let suggestionData = "";
    if (!isEmpty(suggestedItem)) {
      if (!isEmpty(suggestedItem)) {
        suggestionData = suggestedItem.map(item => item.id
        );
      }
    }
    const catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SET_PRODUCT_DATA;
    const filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        catgId: selectedCategory.catgId,
        catgName: selectedCategory.catgName,
        composition: productSingleData.composition,
        itemStatus: Number(productStatus),
        prdName: productSingleData.prdName,
        brandName: productSingleData.brandName,
        hsnCode: productSingleData.hsnCode,
        rack_no: productSingleData.rack_no,
        productImage: productSingleData.productImage,
        prdId: productSingleData.prdId,
        cnvId: conversionSingleData.id,
        colorId:color.colorId,
        aboutItem:productSingleData.aboutItem,
        unitName: conversionSingleData.unitName,
        smallUnitCount: parseFloat(conversionSingleData.smallUnitCount),
        unitCount: Number(conversionSingleData.unitCount),
        smallUnitName: conversionSingleData.smallUnitName,
        unitRestrictions: conversionSingleData.unitRestrictions,
        decimalSmRestrictions: conversionSingleData.decimalSmRestrictions,
        inventoryType: productSingleData.inventoryType,
        sizeType: productSingleData.sizeType,
        personType: productSingleData.personType,
        itemShortCode: productSingleData.itemShortCode,
        stockSideEffects: productSingleData.stockSideEffects,
        stockStorage: productSingleData.stockStorage,
        stockUsage: productSingleData.stockUsage,
        listItemSuggestion: suggestionData,
        delListItemSuggestion: suggestedItemDeleted,
      }
    };
    //console.log("handleProductEntry => ", filledData);
    snpAxios.post(
      catgUrl,
      filledData
    ).then(response => {
      clearProductData();
      printDebugLog("responses => ", response.data);
      if (response.data.status === SUCCESS) {
        getConversionData();
        getProductDetails();
      } else {
        setDataLoaded(true);
      }
    }).catch(error => {
      printInfoLog("error : ", error);
      // window.location.href = "#/error";
      setDataLoaded(true);
    });
  };

  const deleteInventory = async (prdId) => {
    handleDialogClose();
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + DELETEINVENTORY + "?deleteId=" + prdId + "&token=" + CART_INITIAL_STATE.apiToken
      + "&shopHash=" + storeActiveShopHash.id;
    printDebugLog("deleteInventory => ", catgUrl);
    setDataLoaded(false);
    let data = {
    };
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        setDataLoaded(true);
        printDebugLog("response deleteInventory = > " + response.data);
        if (response.data.status === SUCCESS) {
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: "Successfully Deleted ",
          });
        } else {
          let msg = "";
          if (response.data.status === FAILED)
            msg = "Inventory Exist Cannot Delete!";
          else if (response.data.status === SESSIONEXPIRED)
            msg = "Session Expired! Login Again";
          else
            msg = "Failed to Delete (Error:" + response.data.status + ")";
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: msg,
          });
        }
        getProductDetails();
      }).catch((error) => {
        console.log("deleteInventory ", error);
        setDataLoaded(true);
      });
  };

  const getProductDetails = async (mStock) => {
    const cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_PRODUCT_DATA;
    const data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        stock: isEmpty(mStock) ?  "" : mStock,
      }
    };
    //  console.log("getProductDetails  => ",data);
    snpAxios.post(
      cartUrl,
      data
    )
      .then((response) => {
        printDebugLog("response  => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          setProductData(response.data.jcJson.value_2);
        }
      }).catch((error) => {
        setDataLoaded(true);
        console.log("getProductDetails errr ", error);
        //window.location.href = "#/error";
      });
  };

  const getProductItemDetails = async (stock) => {
    try {
      const productDetails = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETPRODDETAILS}?adminId=${CART_INITIAL_STATE.apiToken}&stock=${stock}&shopHash=${storeActiveShopHash.id}`;
      const response = await axios.get(productDetails, {});
      if (response.data.status === SUCCESS) {
        setItemData(response.data.jcJson.value_2);
      } else {
        setItemData([]);
      }
    } catch (error) {
      console.error("getProductItemDetails error : ", error);
    }
  };

  const handleSearchComposition = (event) => {
    const { name, value } = event.target;
    printDebugLog("handleSearchComposition", name);
    if (value.length >= 3) {
      fetchAutoCompleteDetails(name, value);
    }
  };

  const handleSearchColor = (event) => {
    const { name, value } = event.target;
    printDebugLog("handleSearchColor", name);
    if (value.length >= 3) {
      fetchAutoCompleteDetails(name,value);
    }
  }


  const fetchAutoCompleteDetails = async (id, regextStr) => {
    const cartTypeStr = getPreUrlAccordingToCart(storeActiveShopHash.cartType);
    let urlType = "";
    if (id === 'composearch') {
      urlType = `search-composition?q=${regextStr}&m=${storeActiveShopHash.cartType}`;
    } else if (id === 'usagesearch') {
      urlType = `search-stock-usage?q=${regextStr}&m=${storeActiveShopHash.cartType}`;
    } else if (id === 'effectsearch') {
      urlType = `search-side-effects?q=${regextStr}&m=${storeActiveShopHash.cartType}`;
    } else if (id === 'search-color') {
      urlType = `fetch-color?color=${regextStr}`;
    }
    try {
      const fetchBillUrl = `${storeApiUrl.BACKEND_BASE_URL}${cartTypeStr}${urlType}`;
      const response = await axios.get(fetchBillUrl, {});
      printDebugLog("fetchAutoCompleteDetails   => ", regextStr, response.data);
      if (!isEmpty(response.data)) {
        if (id === 'composearch') {
          setListComposition(response.data);
        } else if (id === 'usagesearch') {
          setListStockUsage(response.data);
        } else if (id === 'effectsearch') {
          setListSideEffects(response.data);
        } else if (id === 'search-color') {
          if (response.data.status === SUCCESS) {
            setListColor(response.data.jcJson.snpColorModelList);
          } else {
            setListColor([]);
          }
        }

      } else {
        if (id === 'composearch') {
          setListComposition([]);
        } else if (id === 'usagesearch') {
          setListStockUsage([]);
        } else if (id === 'effectsearch') {
          setListSideEffects([]);
        } else if (id === 'search-color') {
          setListColor([]);
        }
      }

    } catch (error) {
      console.log("fetchAutoCompleteDetails errr ", error);
      if (id === 'composearch') {
        setListComposition([]);
      } else if (id === 'usagesearch') {
        setListStockUsage([]);
      } else if (id === 'effectsearch') {
        setListSideEffects([]);
      } else if (id === 'search-color') {
        setListColor([]);
      }
    }
  };

  const isValid = () => {
    let isProductSingleDataValid = true;
    if (!productSingleData.prdName || !productSingleData.brandName) {
      isProductSingleDataValid = false;
    }
    return isProductSingleDataValid;
  }

  const getCateogoryDetails = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CATEOGORY_DATA;

    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      }
    };
    //   console.log("verifyCredentials => ",data);
    snpAxios.post(
      catgUrl,
      data
    )
      .then((response) => {
        if (response.data.status === SUCCESS) {
          //setCategoryData(response.data.jcJson.value_2);
          let resp = response.data.jcJson.value_2.map((catg) => {
            // categoryData.push({
            //   label: catg.catgName,
            //   value: catg.id
            // })

            let catgName = catg.details
            let catgId = catg.id
            return { catgName, catgId }
          })
          //console.log("Resut : ", resp);
          setCategoryData(resp);
          //console.log(categoryData);
        }
      }).catch((error) => {
        console.log("getCateogoryDetails ", error);
      });
  };
  useLayoutEffect(() => {
    //console.log('Printing Shop Details : ',storeActiveShopHash);
    setCreateUnit(false);
    getCateogoryDetails();
    getConversionData();
    getProductDetails();
  }, [storeActiveShopHash, storeApiUrl]);

  const handleKeyDownComposition = (evt) => {
    printDebugLog("handleKeyDownComposition key", evt?.key);
    if (["Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      printDebugLog("handleKeyDownComposition inputValue", inputValue);
      if (!isEmpty(inputValue)) {
        setListComposition([]);
        const list = productSingleData.composition === undefined ? [inputValue] : [...productSingleData.composition, inputValue];
        setSingleProductData({
          ...productSingleData,
          composition: list,
        });
        setInputValue('');
      }
    }
  };

  const handleSelectComposition = (event, newValue, reason, detail) => {
    printDebugLog("Autocomplete", event.key, newValue, reason, detail);
    setInputValue('');
    if (reason === 'removeOption') {
      handleDelete(detail.option);
    } else if (reason === 'selectOption' || reason === 'createOption') {
      setSingleProductData((prev) => ({
        ...prev,
        composition: newValue,
      }));
    } else if (reason === 'clear') {
      setSingleProductData((prev) => ({
        ...prev,
        composition: [],
      }));
    }
  };

  const handleKeyDownUsage = (evt) => {
    printDebugLog("handleKeyDownUsage", evt?.key);
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      printDebugLog("handleKeyDownUsage inputValue", inputUsageValue);
      if (!isEmpty(inputUsageValue)) {
        setListStockUsage([]);
        setSingleProductData(prevs => ({
          ...prevs,
          stockUsage: prevs.stockUsage === undefined ? [inputUsageValue] : [...prevs.stockUsage, inputUsageValue],
        }));
        setInputUsageValues('');
      }
    }
  };

  const handleKeyDownSideEffect = (evt) => {
    printDebugLog("handleKeyDownSideEffect", evt?.key);
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      printDebugLog("handleKeyDownSideEffect inputValue", inputSideEffects);
      if (!isEmpty(inputSideEffects)) {
        setListSideEffects([]);
        setSingleProductData(prevs => ({
          ...prevs,
          stockSideEffects: prevs.stockSideEffects === undefined ? [inputSideEffects] : [...prevs.stockSideEffects, inputSideEffects],
        }));
        setInputSideEffects('');
      }
    }
  };

  const handleDelete = (item) => {
    setSingleProductData({
      ...productSingleData,
      composition: productSingleData.composition.filter(i => i !== item),
    });
  };

  const handleItemSearchChange = (event) => {
    if (event) {
      if (event.target.name === 'composearch') {
        setInputValue(event.target.value);
      } else if (event.target.name === 'usagesearch') {
        setInputUsageValues(event.target.value);
      } else if (event.target.name === 'effectsearch') {
        setInputSideEffects(event.target.value);
      }
    }

  }



  const handleSelectUsage = (event, newValue, reason, detail) => {
    printDebugLog("Autocomplete", newValue, reason, detail);
    setInputUsageValues('');
    if (reason === 'removeOption') {
      setSingleProductData(prev=> ({
        ...prev,
        stockUsage: prev.stockUsage.filter(i => i !== detail.option),
      }));
    } else if (reason === 'selectOption' || reason === 'createOption') {
      setSingleProductData((prev) => ({
        ...prev,
        stockUsage: newValue,
      }));
    } else if (reason === 'clear') {
      setSingleProductData((prev) => ({
        ...prev,
        stockUsage: [],
      }));
    }
  };

  const handleSelectSideEffect = (event, newValue, reason, detail) => {
    printDebugLog("Autocomplete", newValue, reason, detail);
    setInputSideEffects('');
    if (reason === 'removeOption') {
      setSingleProductData(prev => ({
        ...prev,
        stockSideEffects: prev.stockSideEffects.filter(i => i !== detail.option),
      }));
    } else if (reason === 'selectOption' || reason === 'createOption') {
      setSingleProductData(prev => (
        {
          ...prev,
          stockSideEffects: newValue,
        }
      ));
    } else if (reason === 'clear') {
      setSingleProductData(prev => (
        {
          ...prev,
          stockSideEffects: [],
        }
      ));
    }
  };


  const renderListDialogContent = () => {
    return (
      <React.Fragment>

        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            if (isFeatureAllowed(STOCKMGMT) && isRoleAllowed(STOCKVIEW)) {
              navigate("/dashboard/stockentry", { state: { prdId: productSingleData.prdId, isEditStock: 0 } });
            } else {
              //console.log("Here");
              setShowDialog(false);
              setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: false,
                positiveReq: true,
                positiveAction: "Ok Got it",
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 99,
                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                handleSnpSubmit: () => handleDialogClose(),
              });
            }
          }}>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Check All Live Stock Entry" />
          </ListItemButton>
        </List>
        <Divider />
        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            if (isFeatureAllowed(STOCKMGMT) && isRoleAllowed(STOCKVIEW)) {
              navigate("/dashboard/stockentry", { state: { prdId: productSingleData.prdId, isEditStock: 1 } });
            } else {
              //console.log("Here");
              setShowDialog(false);
              setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: false,
                positiveReq: true,
                positiveAction: "Ok Got it",
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 99,
                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                handleSnpSubmit: () => handleDialogClose(),
              });
            }
          }}>
            <ListItemIcon>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary="Create New Stock Entry" />
          </ListItemButton>
        </List>
        <Divider />
        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            if (isRoleAllowed(INVENTORYCREATE)) {
              if (productSingleData.itemStatus === ItemStatus.ACTIVE) {
                handleProductEntry(ItemStatus.INACTIVE);
              } else {
                handleProductEntry(ItemStatus.ACTIVE);
              }
            } else {
              setShowDialog(false);
              setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: false,
                positiveReq: true,
                positiveAction: "Ok Got it",
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 99,
                subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                handleSnpSubmit: () => handleDialogClose(),
              });
            }
          }}>
            <ListItemIcon>
              {productSingleData.itemStatus === ItemStatus.ACTIVE ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </ListItemIcon>
            <ListItemText primary={"Make " + productSingleData.prdName + "-" + productSingleData.brandName + (productSingleData.itemStatus === ItemStatus.ACTIVE ? " Inactive" : " Active")} />
          </ListItemButton>
        </List>
        <Divider />
        <List id="parent-list-tags">
          <ListItemButton sx={{
            "&:hover": {
              backgroundColor: "#00333e",
              color: "white",
              "& .MuiListItemIcon-root": {
                minWidth: '64px',
                color: "white"
              }
            },
          }} onClick={() => {
            setPreDialog(false);
          }}>
            <ListItemIcon>
              <BorderColorIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Product Details" />
          </ListItemButton>
        </List>
      </React.Fragment>
    )
  };




  const renderProductEntryDialogContent = () => {
    return (
      <div>
        <Grid container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end">
          <Grid item md={6} sm={6} lg={6} xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                accept="image/*"
                id="imgPath"
                name="imgPath"
                label=""
                type="file"
                multiple={5}
                placeholder="Restaurant Image(Image should be less than 5Mb.)"
                onChange={handleInputChange}
                style={{ border: "1px solid lightgray", marginRight: "2px", padding: "3px 1px" }}
              />
            </div>
          </Grid>
          {
            selectedImage && selectedImage.length > 0 &&
            selectedImage.map((item,index) => (
              <Card orientation="horizontal" size="md" key={"Image_" + index} variant="outlined">
                <img
                  alt={"Image_" + index}
                  style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                  // src={storeApiUrl.BACKEND_BASE_URL + "files/" + imgv}
                  src={item}
                />
                <DeleteIcon
                    onClick={() => handleDeleteInternalImage(index)} />

              </Card>
              
            ))
          }
          {
            productSingleData.productImage.length > 0 && productSingleData.productImage.map((item, index) => (
              <Card orientation="horizontal" size="md" key={"Image_" + index} variant="outlined">
                <img
                  style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                  srcSet={`${storeApiUrl.BACKEND_BASE_URL + "files/" + item}`}
                  src={`${storeApiUrl.BACKEND_BASE_URL + "files/" + item}`}
                  alt={"Image_" + index}
                />
                {
                  productSingleData.productImage.length > 1 && <DeleteIcon
                    onClick={() => handleDeleteImage(index)} />
                }

              </Card>

            ))
          }
        </Grid>
        {imageSelectedError &&
          <Grid container sx={{
            mb: 1,
          }}>
            <Grid item xs zeroMinWidth>
              <Typography variant="p" color="#EE4B2B" noWrap>Please select the Image (image format) having size below 5Mb</Typography>
              <br />
              <Typography variant="p" color="#EE4B2B" noWrap>before uploading.</Typography>
            </Grid></Grid>

        }
        {
          dialogLoader && <Grid item xs={12}>
            <center>
              <Loader /></center>
          </Grid>
        }
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#e9d9d696",
            boxShadow: 1,
            borderRadius: 4,
            m: 2,
            padding: 2,
          }} ><Grid container spacing={1} justifyContent="center"
            alignItems="center" sx={{
              padding: 0.1,
            }}>
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <ThreeDText
                text="Product Information"
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left" />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                required
                id="prdName"
                label="Enter Item Name"
                name="prdName"
                variant="filled"
                size="small"
                defaultValue={productSingleData.prdName}
                onChange={handleInputChange}
                fullWidth
                error={formSubmitted && productSingleData.prdName === ""}
                helperText={getErrorMessage('prdName')}
              />
            </Grid>

            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="hsnCode"
                name="hsnCode"
                variant="filled"
                size="small"
                label="Enter HSN Code"
                value={productSingleData.hsnCode}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} sm={6} lg={6} xs={12}>
              <TextField
                required
                id="brand-Name"
                label="Enter Brand Name"
                fullWidth
                size="small"
                name="brandName"
                variant="filled"
                defaultValue={productSingleData.brandName}
                onChange={handleInputChange}
                error={formSubmitted && productSingleData.brandName === ""}
                helperText={getErrorMessage('brandName')}
              />
            </Grid>
            <Grid item md={6} sm={6} lg={6} xs={12} >
              <Autocomplete
                id="cateogory-select"
                options={categoryData}
                autoHighlight
                onChange={handleSelectChange}
                size="small"
                variant="filled"
                fullWidth
                getOptionLabel={(option) => option.catgName}
                defaultValue={selectedCategory}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option.catgName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a category"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} lg={6} xs={12}>
              <Autocomplete id="quick-select"
                multiple
                freeSolo
                options={listComposition}
                autoHighlight
                size="small"
                variant="filled"
                fullWidth
                value={productSingleData.composition}
                onChange={handleSelectComposition}
                onBlur={(e) => setListComposition([])}
                getOptionLabel={(option) => option || ''}
                inputValue={inputValue}
                onInputChange={handleItemSearchChange}
                onKeyDown={handleKeyDownComposition}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="composearch"
                    label={storeActiveShopHash.cartType === CartDets.Pharma ? "Enter Composition" : "Enter SubCateogory/Tags"}
                    onChange={handleSearchComposition}
                    helperText="Use Enter/Tab/Comma(,) to add"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} sm={6} lg={6} xs={12}>
              < Autocomplete
                id="cnv-select"
                size="small"
                disabled={isEditProduct}
                options={conversionTypeData}
                onChange={handleConversionChange}
                fullWidth
                getOptionLabel={(option) => option?.cnvDetails || ''}
                defaultValue={conversionSingleData}
                renderOption={(props, option) => (
                  <Box
                    component="li"

                    {...props}
                  >
                    {option?.cnvDetails}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Custom Unit Type"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item md={12} sm={12} lg={12} xs={12}>
            {productSingleData.composition && (productSingleData.composition.map(item => (
              <div className="tag-item" key={item}>
                {item}
                <button
                  type="button"
                  className="button"
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </button>
              </div>
            )))}
          </Grid> */}
          </Grid>
        </Paper>
        {
          createUnit &&
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#d9d2e9",
              boxShadow: 1,
              borderRadius: 4,
              m: 2,
              padding: 2
            }} >
            <Grid container spacing={1} justifyContent="center"
              alignItems="center">
              <Grid item md={12} sm={12} lg={12} xs={12}>
                <ThreeDText
                  text="Enter Unit Details"
                  display=""
                  className="three-d-text-container"
                  justifyContent="left"
                  alignItems="left" />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  required
                  id="unitName"
                  label="Enter Unit Name"
                  name="unitName"
                  variant="filled"
                  size="small"
                  value={conversionSingleData.unitName}
                  onChange={handleCoversionUpdate}
                />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <FormControlLabel control={<Checkbox checked={conversionSingleData.unitRestrictions}
                  onChange={handleCoversionUpdate} id="unitRestrictions" />} name="unitRestrictions" label="Unit Restrictions" />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  required
                  id="smallUnitCount"
                  variant="filled"
                  size="small"
                  label="Enter Small Unit Count"
                  name="smallUnitCount"
                  value={conversionSingleData.smallUnitCount}
                  onChange={handleCoversionUpdate}
                />
              </Grid>
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  required
                  variant="filled"
                  size="small"
                  id="smallUnitName"
                  label="Enter Small Unit Name"
                  name="smallUnitName"
                  value={conversionSingleData.smallUnitName}
                  onChange={handleCoversionUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel control={<Checkbox checked={conversionSingleData.decimalSmRestrictions}
                  onChange={handleCoversionUpdate} id="decimalSmRestrictions" />} name="decimalSmRestrictions"
                  label=
                  {<p>Disable Fraction{"(Eg."} <span style={{ textDecoration: "line-through" }}> 0.5 {conversionSingleData.unitName}</span>{" 5 " + conversionSingleData.smallUnitName + " )"}</p>}

                />
              </Grid>
            </Grid>
          </Paper>
        }
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#e37c0f2b",
            boxShadow: 1,
            borderRadius: 4,
            m: 2,
            padding: 2
          }} >
          <Grid container spacing={1} justifyContent="center"
            alignItems="center" sx={{
              padding: 0.1,
            }}>
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <ThreeDText
                text="Advance Configuration"
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left"
              />
            </Grid>
            {
              storeActiveShopHash.cartType === CartDets.Pharma
              &&
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <Autocomplete name="effect-select"
                  multiple
                  freeSolo
                  options={listSideEffects}
                  autoHighlight
                  size="small"
                  variant="filled"
                  fullWidth
                  value={productSingleData.stockSideEffects}
                  onChange={handleSelectSideEffect}
                  onBlur={(e) => setListSideEffects([])}
                  getOptionLabel={(option) => option || ''}
                  inputValue={inputSideEffects}
                  onInputChange={handleItemSearchChange}
                  onKeyDown={handleKeyDownSideEffect}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="effectsearch"
                      label={"Enter Side Effect"}
                      onChange={handleSearchComposition}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            }
            {
              storeActiveShopHash.cartType === CartDets.Pharma
              && <Grid item md={6} sm={6} lg={6} xs={12}>
                <Autocomplete name="usage-select"
                  multiple
                  freeSolo
                  options={listStockUsage}
                  autoHighlight
                  size="small"
                  variant="filled"
                  fullWidth
                  value={productSingleData.stockUsage}
                  onChange={handleSelectUsage}
                  onBlur={(e) => setListStockUsage([])}
                  getOptionLabel={(option) => option || ''}
                  inputValue={inputUsageValue}
                  onInputChange={handleItemSearchChange}
                  onKeyDown={handleKeyDownUsage}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Usage"
                      name="usagesearch"
                      onChange={handleSearchComposition}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            }
            {
              storeActiveShopHash.cartType === CartDets.Pharma &&
              <Grid item md={6} sm={6} lg={6} xs={12}>
                <TextField
                  id="storage"
                  name="stockStorage"
                  variant="filled"
                  size="small"
                  label="Enter Therapeutic Class"
                  value={productSingleData.stockStorage}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            }
            <Grid item md={6} sm={6} lg={6} xs={12}>
              <TextField
                id="rack_no"
                name="rack_no"
                variant="filled"
                size="small"
                label="Enter Rack No(Location)"
                value={productSingleData.rack_no}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            
            <Grid item md={6} sm={6} lg={6} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select below</FormLabel>
                <RadioGroup
                  aria-label="personType"
                  name="personType"
                  value={productSingleData.personType}
                  onChange={handleInputChange}
                  row={true}
                >
                  {Object.entries(PersonType).map(([key, value]) => (
                    <FormControlLabel
                      key={key}
                      value={value}
                      control={<Radio />}
                      label={uiUtilInstance.formatLabel(value)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Size</FormLabel>
                <RadioGroup
                  aria-label="sizeType"
                  name="sizeType"
                  value={productSingleData.sizeType}
                  onChange={handleInputChange}
                  row={true}
                >
                  {Object.entries(SizeType).map(([key, value]) => (
                    <FormControlLabel
                      key={key}
                      value={value}
                      control={<Radio />}
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="search-color"
                options={listColor}
                autoHighlight
                variant="filled"
                size="small"
                fullWidth
                value={color}
                onChange={handleSelectColorChange}
                onBlur={(e) => setListColor([])}
                isOptionEqualToValue={(option, value) => option?.colorId === value?.colorId}
                getOptionLabel={(option) => option?.colorDetails || ''}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: option.colorDetails.split(" ")[0],
                        border: "1px solid #ccc",
                        borderRadius: 4,
                      }}
                    />
                    {option.colorDetails}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Color"
                    name="search-color"
                    onChange={handleSearchColor}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="aboutItem"
                name="aboutItem"
                variant="filled"
                size="small"
                onChange={handleInputChange}
                multiline={true}
                rows={3}
                maxRows={5}
                label="Enter About Product"
                value={productSingleData.aboutItem}
                fullWidth
              />
            </Grid>
            {
              isFeatureAllowed(CUSTOMERAPP)
              && <Grid item xs={12}>
                <Autocomplete id="suggestion-select"
                  multiple
                  freeSolo
                  options={itemData}
                  limitTags={2}
                  autoHighlight
                  size="small"
                  variant="filled"
                  fullWidth
                  value={suggestedItem}
                  onChange={(event, newValue, reason, detail) => {
                    printDebugLog("Autocomplete", newValue, reason);
                    setInputValue('');
                    if (suggestionAvail) {
                      if (reason === 'removeOption') {
                        const updatedItems = [...suggestedItemDeleted, detail.option.id];
                        setSuggestedItemDeleted(updatedItems);
                      } else if (reason === 'selectOption') {
                        if (suggestedItemDeleted.length > 0) {
                          const idToDelete = detail.option.id;
                          const indexToDelete = suggestedItemDeleted.findIndex(item => item === idToDelete);
                          // If item is found, create a new array without it
                          if (indexToDelete !== -1) {
                            const updatedItems = [...suggestedItemDeleted.slice(0, indexToDelete), ...suggestedItemDeleted.slice(indexToDelete + 1)];
                            setSuggestedItemDeleted(updatedItems);
                          }
                        }
                      } else if (reason === 'clear') {
                        suggestedItem.forEach(element => {
                          const updatedItems = [...suggestedItemDeleted, element.id];
                          setSuggestedItemDeleted(updatedItems);
                        });
                      }
                    }
                    setSuggestedItem(newValue);
                  }}
                  onBlur={(e) => setItemData([])}
                  getOptionLabel={(option) => option?.details || ''}
                  inputValue={inputValue}
                  onInputChange={handleInputChangeValue}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      option.details?.toLowerCase().includes(inputValue.toLowerCase()) ||
                      option.prdType?.toLowerCase().includes(inputValue.toLowerCase()) ||
                      option.tags?.toLowerCase().includes(inputValue.toLowerCase()) ||
                      option.catgName?.toLowerCase().includes(inputValue.toLowerCase())
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      {uiUtilInstance.getStockOptionLabel(option)}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Item Suggestion"
                      placeholder="Search"
                      onChange={handleSearch}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            }
            
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Item Status</FormLabel>
                <RadioGroup aria-label="itemStatus" name="itemStatus" value={productSingleData.itemStatus.toString()} onChange={handleInputChange} row={true}>

                  <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                  <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />

                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        {
          productSingleData.allowedDelete && <Grid item md={4} xs={6}>
            <center>
              <SnpButton variant="contained" color="bRed" onClick={() => deleteInventory(productSingleData.prdId)}>
                Delete Product
              </SnpButton>
            </center>
          </Grid>
        }

      </div>
    )
  };

  const renderDialogContent = () => {
    return (preDialog ? renderListDialogContent() : renderProductEntryDialogContent());
  }

  const renderDialogAction = () => {
    return (
      <React.Fragment>
        <SnpButton variant="contained" color="primary" onClick={handleImageUpload}>
          {isEditProduct ? "Update" : "Submit"}
        </SnpButton>

        <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
          Cancel
        </DismissButton>
      </React.Fragment>
    );
  }

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;

    } else if (!productSingleData[field]) {
      return 'Field is required';
      // } else if (!selectedCategory.catgId) {
      //   return 'Field is required';
    }
  }

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setInputValue('');
    setShowDialog(false);
    clearProductData();
    setEditProduct(false);
    setPreDialog(false);
    setFormSubmitted(false);
    setSnpComponent(INITIAL_SNP_COMPONENT);
    setColor(INITIAL_COLOR_MODEL);
    clearSuggestion();
  };

  const clearSuggestion = () => {
    if (isFeatureAllowed(CUSTOMERAPP)) {
      setSuggestedItem([]);
      setSuggAvail(false);
      setSuggestedItemDeleted([]);
    }
  }

  // useEffect(() => {
  //   if (listComposition.length === 0 && productSingleData.composition) {
  //     setListComposition(productSingleData.composition);
  //   }
  // }, [listComposition, productSingleData.composition]);



  const renderSnpComponent = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sm={6} lg={6}>
          <div style={{ padding: 10 }}>
              <EnterButton
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={(event) => {
                  if (isRoleAllowed(INVENTORYCREATE)) {
                    setShowDialog(true);
                  } else {
                    setSnpComponent({
                      ...snpComponent,
                      isLoader: false,
                      negativeReq: false,
                      positiveReq: true,
                      positiveAction: "Ok Got it",
                      showDialog: true,
                      outSideClickDismiss: true,
                      cancelExtra: true,
                      requestType: 99,
                      subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                      handleSnpSubmit: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                    });
                  }
                }}>
                Add New Product
              </EnterButton>
            </div>
          </Grid>
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <TextField
              required
              id="searchProduct"
              label="Search Product"
              name="searchProduct"
              variant="filled"
              size="small"
              onChange={(e) => {
                getProductDetails(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDataGrid
              getRowId={row => row.prdId}
              rows={productData}
              loading={!isDataLoaded}
              columns={storeActiveShopHash.cartType === CartDets.Pharma ? compositionColumns : columns}
              mDensity="compact"
            />
          </Grid>
        </Grid>
        <DialogBox
          showDialog={showDialog}
          title={preDialog ? "" : isEditProduct ? "View Product" : "Add New Product"}
          onClose={handleDialogClose}
          dialogContent={renderDialogContent()}
          dialogActions={!preDialog && renderDialogAction()}
        ></DialogBox>
      </React.Fragment>
    )
  };

  const handleSnackClose = () => {
    setSnpComponent({
      ...snpComponent,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };

  return (
    <React.Fragment>
      <SnpComponent showDialog={snpComponent.showDialog}
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
        iconReq={snpComponent.iconReq}
        iconContent={snpComponent.iconContent}
        content={renderSnpComponent()}
        onSnackClose={handleSnackClose}
        cancelExtra={snpComponent.cancelExtra}
        onCancel={snpComponent.handleSnpCancel}
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss}
        handleSubmit={snpComponent.handleSnpSubmit}
        gui={snpComponent.gui}
      />
    </React.Fragment>
  );
}