import {
  CART_INITIAL_STATE,
  DashboardComponent,
  GET_BILL_CATEOGORY_DATA,
  GET_BILL_PRD_DATA,
  getPreUrlAccordingToCart,
  printDebugLog,
  SUCCESS,
} from "../../../constants/constant";
import RenderCateogoryCard from "../resto/RenderCateogoryCard";
// import LeftBillComp from "./LeftBillComp";
import { addDataIndb, deleteItemForDb } from "../../global/indexedDB";
import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { snpAxios } from "../../global/api";
import {
  INITIAL_BILL_STOCK_DATA,
  INITIAL_SNP_COMPONENT,
} from "./RestBillConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  setSnpComponentDetails,
  setSnpLoader,
} from "../../../store/snpGlobalSLice";
import { setBaseCateogoryData, setBaseProductData } from "../../../actions";
import { debounce, isEmpty } from "lodash";
import BottomSheet from "../../global/BottomSheet";
import { is } from "date-fns/locale";
import LeftBillComp from "./LeftBillComp";

const SnpBillComp = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [catgProductData, setCatgProductData] = useState([]);
  const [filteredCatgProductData, setFilteredCatgProductData] = useState([]);

  const dispatch = useDispatch();
  const { activeShopHash } = useSelector(
    (state) => state.setActiveShopHashReducer
  );
  // const { storeApiUrl } = useSelector((state) => state.setApiUrlReducer);
  const snpComponent = useSelector((state) => state.snpGlobalState);

  const storeActiveShopHash = useSelector(
    (state) => state.setActiveShopHashReducer.activeShopHash
  );
  const storeApiUrl = useSelector(
    (state) => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL
  );
  const storeCateogoryData = useSelector(
    (state) => state.setCateogoryReducer.cateogoryData
  );
  const storeProductData = useSelector(
    (state) => state.setProductWithCatgReducer.productData
  );


  const getCateogoryData = async () => {
    dispatch(setSnpLoader(true));
    const catgUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(
      storeActiveShopHash.cartType
    )}${GET_BILL_CATEOGORY_DATA}`;
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
      },
    };
    // //printLog("getCateogoryData => ",data);
    await snpAxios
      .post(catgUrl, data)
      .then((response) => {
        printDebugLog("getCateogoryData => ", response.data);
        if (response.data.status === SUCCESS) {
          dispatch(setSnpLoader(false));
          setCategoryData(response.data.jcJson.value_2);
          addDataIndb({
            id: storeActiveShopHash.id,
            value: [...response.data.jcJson.value_2],
          });
          // dispatch(setBaseCateogoryData(response.data.jcJson.value_2));
        } else {
          dispatch(
            setSnpComponentDetails({
              ...snpComponent,
              isLoader: false,
              negativeReq: true,
              negativeAction: "Home",
              positiveAction: "Add Cateogory",
              positiveReq: true,
              showDialog: true,
              outSideClickDismiss: false,
              cancelExtra: true,
              requestType: DashboardComponent.CATEOGORY,
              subTitle: "No Cateogory is available! Please add",
            })
          );
          dispatch(setSnpLoader(false));
        }
      })
      .catch((error) => {
        console.log("getCateogoryData err", error);
        dispatch(
          setSnpComponentDetails({
            ...snpComponent,
            isLoader: false,
            negativeReq: true,
            negativeAction: "Home",
            showDialog: true,
            outSideClickDismiss: false,
            cancelExtra: true,
            requestType: 1,
            subTitle: "Internet Connection Lost",
          })
        );
        dispatch(setSnpLoader(true));
        //printLog("Error : ", error);
        //window.location.href = "#/error";
      });
  };
  const updateCatgPrdData = useCallback((updatedData) => {
    const debouncedUpdate = debounce(() => {
      printDebugLog("updateCatgPrdData", updatedData);
      setCatgProductData(updatedData);
    }, 100); // Set the debounce delay (100ms)

    debouncedUpdate(); // Invoke the debounced function
  }, []);

  const handleCateogoryClick = useCallback((opt) => {
    //setDialogLoader(true);
    printDebugLog("handleCateogoryClick init", new Date().toISOString());
    if (storeProductData.hasOwnProperty(opt.id)) {
      const value = storeProductData[opt.id];
      printDebugLog(
        "handleCateogoryClick dialog false",
        value,
        new Date().toISOString()
      );
      if (value !== undefined) {
        setFilteredCatgProductData(value);
        updateCatgPrdData(value);
      } else getProductDataByCatgId(opt.id);
    } else getProductDataByCatgId(opt.id);
  }, []);

  const createFilteredList = useCallback(
    (e) => {
      let searchChar = e.target.value;
      if (isEmpty(searchChar)) {
        setFilteredCatgProductData([...catgProductData]);
      } else {
        const filteredList = catgProductData.filter((item) =>
          item.name.toLowerCase().includes(searchChar.toLowerCase())
        );
        if (filteredList && filteredList.length > 0) {
          setFilteredCatgProductData(filteredList);
        } else {
          dispatch(
            setSnpComponentDetails({
              ...snpComponent,
              snackBarMsg: searchChar + " is not available in list",
              snackBarOpen: true,
              snackHorizontal: "right",
              snackVertical: "top",
              snackColor: "#ff9966",
            })
          );
        }
      }
    },
    [catgProductData, snpComponent]
  );

  const getProductDataByCatgId = async (catgId) => {
    dispatch(setSnpLoader(true));
    let catgUrl =
      storeApiUrl.BACKEND_BASE_URL +
      getPreUrlAccordingToCart(storeActiveShopHash.cartType) +
      GET_BILL_PRD_DATA;
    let data = {
      jcJson: {
        value: CART_INITIAL_STATE.apiToken,
        value_1: catgId,
        shopHash: storeActiveShopHash.id,
      },
    };
    //printLog("getProductDataByCatgId => ",data);
    snpAxios
      .post(catgUrl, data)
      .then((response) => {
        printDebugLog("getProductDataByCatgId => ", response.data);
        dispatch(setSnpLoader(false));
        if (response.data.status === SUCCESS) {
          setFilteredCatgProductData([...response.data.jcJson.value_2]);
          updateCatgPrdDataReceiveFrom(catgId, [
            ...response.data.jcJson.value_2,
          ]);
        } else {
          dispatch(
            setSnpComponentDetails({
              ...snpComponent,
              isLoader: false,
              negativeReq: true,
              negativeAction: "Home",
              positiveAction: "Add Food Item",
              positiveReq: true,
              showDialog: true,
              outSideClickDismiss: true,
              cancelExtra: true,
              requestType: DashboardComponent.PRODUCTENTRY,
              subTitle: "No Food Item is available! Please add",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(setSnpLoader(false));
        dispatch(
          setSnpComponentDetails({
            ...snpComponent,
            isLoader: false,
            negativeReq: true,
            negativeAction: "Dismiss",
            showDialog: true,
            outSideClickDismiss: true,
            cancelExtra: true,
            requestType: 99,
            handleSnpCancel: () =>
              dispatch(setSnpComponentDetails(INITIAL_SNP_COMPONENT)),
            subTitle: "Internet Connection Lost",
          })
        );
        //printLog("Error : ", error);
        //window.location.href = "#/error";
      });
  };
  const updateCatgPrdDataReceiveFrom = useCallback((catgId, updatedData) => {
    const debouncedUpdate = debounce(() => {
      printDebugLog("updateCatgPrdDataReceiveFrom", catgId, updatedData);
      setCatgProductData(updatedData);
      addDataIndb({ id: catgId, value: updatedData });
      //const dataMap = storeProductData;
      //dataMap.set(catgId, updatedData);
      // dispatch(setBaseProductData({catgId : updatedData}));
    }, 100); // Set the debounce delay (100ms)

    debouncedUpdate(); // Invoke the debounced function
  }, []);

  const handleRefereshProductData = async () => {
    const firstProduct = filteredCatgProductData[0];
    const catgId = firstProduct?.catgId;

    if (filteredCatgProductData.length && catgId) {
      // handleDialogClose();
      await deleteItemForDb(catgId);
      getProductDataByCatgId(catgId);
      return;
    }

    dispatch(
      setSnpComponentDetails((prev) => ({
        ...prev,
        snackBarOpen: true,
        snackBarMsg: "Some Internal Problem",
      }))
    );
  };
  const clearFilteredData = useCallback(
    debounce(() => {
      setFilteredCatgProductData([]);
    }, 100), // Adjust the delay (100ms) as needed
    []
  );
  //what does this single price in method mean?
  // const handleProductSelectSinglePrice = (cellData, selectMultipleItemOpt) => {
  //   printDebugLog(
  //     "handleProductSelectSinglePrice =>",
  //     cellData,
  //     selectMultipleItemOpt
  //   );
  //   const isPaid = false;
  //   if (isPaid) {
  //     dispatch(setSnpComponentDetails({
  //       ...snpComponent,
  //       snackBarMsg: "This Bill is Already Paid",
  //       snackBarOpen: true,
  //     }));
  //   } else {
  //     let index = listbillingData.value_2.findIndex(
  //       (singleValue) => singleValue.prdId === cellData.id
  //     );
  //     if (parseInt(index) === -1) {
  //       var billObj = INITIAL_BILL_STOCK_DATA;
  //       billObj.cgst = cellData.cgst;
  //       billObj.prdId = cellData.id;
  //       billObj.name = cellData.name;
  //       billObj.sgst = cellData.sgst;
  //       billObj.price = cellData.price.sAmt;
  //       billObj.foodQuantityName = cellData.price.name;
  //       billObj.finalprice = cellData.price.sAmt;
  //       billObj.note = "";
  //       billObj.priceIndex = 0;
  //       billObj.quantity = 1;
  //       billObj.offerDenom = cellData.offertype;
  //       billObj.offerAmt = cellData.offerAmt;
  //       billObj.print = false;
  //       billObj.combo = cellData.catgId === "snp_combo_id";
  //       // Uncomment it once the its reducer is ready
  //       // setBillingData((prvsState) => ({
  //       //   ...prvsState,
  //       //   value_2: [...prvsState.value_2, { ...billObj }],
  //       // }));
  //       // setOnlinePayEnb(false);
  //       // setCashPayEnb(false);

  //       // doTotal(
  //       //   0.0,
  //       //   cellData.price.sAmt,
  //       //   parseFloat(cellData.cgst),
  //       //   parseFloat(cellData.sgst),
  //       //   parseFloat(listbillingData.pointRedeem),
  //       //   billDetails.isGstAllowed,
  //       //   delvCharge,
  //       //   billDetails.offerDenom,
  //       //   billDetails.offerAmt
  //       // );

  //       handleChangeInSelectedProduct("", false, cellData);
  //     } else {
  //       //printLog("Already Added => ", cellData);
  //       // setSnpComponent({
  //       //     ...snpComponent,
  //       //     snackBarOpen: true,
  //       //     snackBarMsg: cellData.name + " is already added"
  //       // })
  //       let opt = listbillingData.value_2[index];
  //       setItemDetailsOnEdit(opt, cellData);
  //     }

  //     if (!selectMultipleItemOpt) {
  //       clearFilteredData();
  //     }
  //   }
  // };

  //why do we need this method??
  // const setItemDetailsOnEdit = (opt, prvsVal) => {
  //     printDebugLog("setItemDetailsOnEdit", opt, prvsVal);
  //     if (!isEmpty(prvsVal)) {
  //         if (!isEmpty(opt.foodQuantityName)) {
  //             if (isNumberEmpty(opt.priceIndex) || opt.priceIndex < -1) {
  //                 let index = prvsVal.price.findIndex(singleValue => singleValue.name === opt.foodQuantityName);
  //                 if (index > -1)
  //                     opt.priceIndex = index;
  //             }
  //         }
  //         setStockSingleData({
  //             ...stockSingleData,
  //             id: prvsVal.id,
  //             catgId: prvsVal.catgId,
  //             catgName: prvsVal.catgName,
  //             name: prvsVal.name,
  //             productImage: prvsVal.productImage,
  //             spicy: prvsVal.spicy,
  //             foodType: prvsVal.foodType,
  //             sgst: prvsVal.sgst,
  //             cgst: prvsVal.cgst,
  //             ingredients: prvsVal.ingredients,
  //             outOfStock: prvsVal.outOfStock,
  //             offertype: prvsVal.offertype,
  //             offerAmt: prvsVal.offerAmt,
  //             price: prvsVal.price,
  //             liveStockCount: prvsVal.liveStockCount,
  //             unitCount: prvsVal.unitCount,
  //             smallUnitCount: prvsVal.smallUnitCount,
  //             unitRestrictions: prvsVal.unitRestrictions,
  //         });
  //     }
  //     setBillStockData({
  //         ...billStockData, ...opt
  //     });
  //     setIsEditItem(true);
  //     setShowItemEditDialog(true);
  // };

  // const handleChangeInSelectedProduct = (mPrdId, isDel, opt) => {
  //   printDebugLog("handleChangeInSelectedProduct", mPrdId, isDel, opt);
  //   //printLog("selectedProductData ",selectedProductData);
  //   if (isDel) {
  //     var array = [...selectedProductData];
  //     var index = array.findIndex((singleValue) => singleValue.id === mPrdId);
  //     if (index !== -1) {
  //       array.splice(index, 1);
  //       setSelectedProductData(array);
  //     }
  //   } else {
  //     if (opt != null) {
  //       // //printLog("opt ",opt);
  //       // all fields look same  then why reassigning????
  //       stockSingleData.id = opt.id;
  //       stockSingleData.catgId = opt.catgId;
  //       stockSingleData.catgName = opt.catgName;
  //       stockSingleData.name = opt.name;
  //       stockSingleData.productImage = opt.productImage;
  //       stockSingleData.spicy = opt.spicy;
  //       stockSingleData.foodType = opt.foodType;
  //       stockSingleData.sgst = opt.sgst;
  //       stockSingleData.cgst = opt.cgst;
  //       stockSingleData.ingredients = opt.ingredients;
  //       stockSingleData.outOfStock = opt.outOfStock;
  //       stockSingleData.offertype = opt.offertype;
  //       stockSingleData.offerAmt = opt.offerAmt;
  //       stockSingleData.price = opt.price;
  //       stockSingleData.liveStockCount = opt.liveStockCount;
  //       stockSingleData.unitCount = opt.unitCount;
  //       stockSingleData.smallUnitCount = opt.smallUnitCount;
  //       stockSingleData.unitRestrictions = opt.unitRestrictions;
  //       // //printLog("stockSingleData opt wala ",stockSingleData);
  //     }

  //     // what are we checking here??
  //     let index = selectedProductData.findIndex(
  //       (singleValue) => singleValue.id === stockSingleData.id
  //     );
  //     // //printLog("selectedProductData index",index);
  //     // are we adding again?
  //     if (parseInt(index) === -1) {
  //       setSelectedProductData((oldArray) => [...oldArray, stockSingleData]);
  //     } else {
  //       var selPrdArray = [...selectedProductData];
  //       selPrdArray[index] = stockSingleData;
  //       setSelectedProductData(selPrdArray);
  //     }
  //   }
  //  // setStockSingleData(INITIAL_STOCK_DATA);
  // };

  const handleItem = () => {};
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          flex: 1,
          order: { xs: 2, md: 1 },
        }}
      >
        <LeftBillComp />
      </Box>
      <Box
        sx={{
          flex: "0 0 300px",
          order: { xs: 1, md: 2 },
        }}
      >
        <>{console.log("Render ")}</>
        <RenderCateogoryCard
          categoryData={categoryData}
          handleCateogoryClick={handleCateogoryClick}
          getCateogoryData={getCateogoryData}
        />
      </Box>
      <BottomSheet
        handleRefereshProductData={handleRefereshProductData}
        createFilteredList={createFilteredList}
        // handleProductSelectSinglePrice={handleProductSelectSinglePrice}
        handleDialogClose={clearFilteredData}
        filteredCatgProductData={filteredCatgProductData}
      />
    </Box>
  );
};

export default SnpBillComp;
