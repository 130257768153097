import { configureStore } from "@reduxjs/toolkit";
import { billDetailsReducer } from "./billDetailsSlice";
import setCartTypeReducer from '../reducers/setCartTypeReducer';
import setActiveShopHashReducer from '../reducers/setActiveShopHashReducer';
import setApiUrlReducer from '../reducers/setApiUrlReducer';
import setCateogoryReducer from '../reducers/setCateogoryReducer'
import setProductWithCatgReducer from '../reducers/setProductWithCatgReducer';
import { snpGlobalStatereducer } from "./snpGlobalSLice";
const store  = configureStore({
    reducer: {
        bill: billDetailsReducer,
        snpGlobalState : snpGlobalStatereducer,
        setCartTypeReducer,setActiveShopHashReducer,setApiUrlReducer,setCateogoryReducer,setProductWithCatgReducer
    }
})

export default store;