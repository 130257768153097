import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { updateBillItem } from "../../../store/billDetailsSlice";
import SnpButton from "../../global/SnpButton";
import DismissButton from "../../global/DismissButton";
import { isEmpty } from "lodash";
import DialogBox from "../../global/DialogBox";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { OfferType } from "../../../constants/constant";
import { INITIAL_BILL_STOCK_DATA, INITIAL_STOCK_DATA } from "./RestBillConstants";

const BillItemEdit = ({
  productDetails=INITIAL_STOCK_DATA,
  billItemDetails = INITIAL_BILL_STOCK_DATA,
  doTotal,
  showEditDailog,
  setShowEditDialog,
  isPaid = false,
}) => {
  const [itemWiseOffer, setItemWiseOffer] = useState(false);
  const dispatch = useDispatch();
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const [localQuantity, setLocalQuantity] = useState(billItemDetails.quantity);
  const [billNote, setBillNote] = useState("");
  useEffect( ()=>{
    console.log("comp loaded: ", productDetails, billItemDetails);
    
  },[showEditDailog])

  useEffect( ()=> {
    setLocalQuantity(billItemDetails.quantity);
    setBillNote(billItemDetails.note);
  }, [billItemDetails.quantity]);


  const handleInputChange = (event) => {
    const {name, value} = event.target;
    if(name === "note"){
        setBillNote(value);
    }
  };

  const handleBillItemEdit = () => {
   
      const oldPrice = 0;
      const deliveryCharge = 0;
      const updatedTotalPrice = Number(billItemDetails.price.sAmt)* localQuantity ;
      dispatch(
        updateBillItem({
          ...billItemDetails,
          quantity: localQuantity,
          note: billNote,
          finalPrice: updatedTotalPrice
        })
      );
      doTotal(oldPrice, updatedTotalPrice, parseFloat(billItemDetails.cgst), parseFloat(billItemDetails.sgst), parseFloat(billItemDetails.pointRedeem), billItemDetails.isGstAllowed, deliveryCharge, billItemDetails.offerDenom, billItemDetails.offerAmt);
      setShowEditDialog(false);
    }

  const renderDialogAction = () => {
    return (
      <>
        <SnpButton
          disabled={isPaid}
          variant="contained"
          color="primary"
          onClick={handleBillItemEdit}
        >
          Update
        </SnpButton>

        <DismissButton
          variant="contained"
          color="primary"
          onClick={() => setShowEditDialog(false)}
        >
          Cancel
        </DismissButton>
      </>
    );
  };
  const BrownTextTypography = withStyles({
    root: {
      color: "#A52A2A",
    },
  })(Typography);

  const renderEditItem = () => {
    console.log("render Edit", billItemDetails.quantity);
    
    return (
      <div>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            style={{ textAlign: "center" }}
          >
            {productDetails.productImage[0] && (
              <img
                alt=""
                style={{ height: "100px", width: "100px", borderRadius: "40%" }}
                src={
                  storeApiUrl.BASE_IMAGE_URL + productDetails.productImage[0]
                }
              />
            )}
            <br />
            <Typography variant="p">
              {"Cateogory : " + productDetails.catgName}
            </Typography>
            <br />
            <Typography variant="p" className="shop-details resto-price">
              {productDetails.ingredients}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Rate Card</FormLabel>
              {/* <RadioGroup aria-label="rate-select" name="rate-select" value={billItemDetails.priceIndex} onChange={handleInputChange}>
                                                  {[...productDetails.price].map((data,index) => (
                                                      <div>
                                                          <FormControlLabel control={<Radio
                                                              value={index}
                                                              id={data.sAmt}
                                                          />}
                                                              label="" />
                                                          <Typography variant="p" className="shop-details shop-address" >{billItemDetails.name + (!isEmpty(data.name) ? "(" + data.name + ")" : "")}</Typography>
                                                          <br /> {"       "}
                                                          <Typography variant="p" className="shop-details resto-price" >Price : &#8377;{data.sAmt}</Typography>
                                                      </div>
                                                  ))}
                                              </RadioGroup> */}
              <RadioGroup
                aria-label="rate-select"
                name="rate-select"
                value={billItemDetails.priceIndex}
                onChange={handleInputChange}
              >
                <div>
                  <FormControlLabel
                    control={<Radio value={0} id={productDetails.price.sAmt} />}
                    label=""
                  />
                  <Typography variant="p" className="shop-details shop-address">
                    {billItemDetails.name +
                      (!isEmpty(productDetails.price.name)
                        ? "(" + productDetails.price.name + ")"
                        : "")}
                  </Typography>
                  <br /> {"       "}
                  <Typography variant="p" className="shop-details resto-price">
                    Price : &#8377;{productDetails.price.sAmt}
                  </Typography>
                </div>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        {itemWiseOffer && (
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">Any Offer</FormLabel>
              <RadioGroup
                row
                aria-label="offerDenom"
                name="offerDenom"
                value={billItemDetails.offerDenom.toString()}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={OfferType.Flat.toString()}
                  control={<Radio />}
                  label="Discount In Flat"
                />

                <FormControlLabel
                  value={OfferType.Percentage.toString()}
                  control={<Radio />}
                  label="Discount In Percentage"
                />
                <FormControlLabel
                  value={OfferType.No_Offer.toString()}
                  control={<Radio />}
                  label="No Offer"
                />
              </RadioGroup>
            </FormControl>
            <br />
            <br />
          </div>
        )}

        <div style={{ paddingBottom: "3px" }}>
          <Grid container spacing={3}>
            {itemWiseOffer && billItemDetails.offerDenom > 1 && (
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="offerAmt"
                  name="offerAmt"
                  variant="filled"
                  size="small"
                  label="Enter Offer Value"
                  value={billItemDetails.offerAmt.toString()}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextField
                id="quantity"
                name="quantity"
                // className={colQuantityClasses.input}
                type="number"
                inputMode="numeric"
                value={localQuantity}
                variant="standard"
                size="small"
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
                label="Enter Quantity"
                onChange={handleInputChange}
                InputProps={{
                  min: "0",
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => {
                            console.log("incrementing quqntity:", localQuantity);
                            
                          const incrementedQuantity =
                            Number(localQuantity) + 1;
                            setLocalQuantity(incrementedQuantity);
                          if (Number(productDetails.unitCount) > 0) {
                            if (
                              incrementedQuantity > productDetails.unitCount
                            ) {
                              //   setSnpComponent({
                              //     ...snpComponent,
                              //     snackBarOpen: true,
                              //     snackBarMsg:
                              //       "Exceeds Total Count i.e " +
                              //       productDetails.liveStockCount,
                              //   });
                            } else {
                              dispatch(
                                updateBillItem({
                                  ...billItemDetails,
                                  quantity: incrementedQuantity,
                                })
                              );
                            }
                          } else {
                            // why ? there is no stock ??
                            dispatch(
                                updateBillItem({
                                  ...billItemDetails,
                                  quantity: incrementedQuantity,
                                })
                              );
                          }
                        }}
                      >
                        <AddCircle
                          color="success"
                          sx={{
                            fontSize: "2rem",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          if (localQuantity > 0) {
                            let decrementedQuantity =
                              localQuantity - 1;
                              setLocalQuantity(decrementedQuantity)
                             if (decrementedQuantity < 0) decrementedQuantity = 0; // ???
                            if (Number(productDetails.unitCount) > 0) {
                              if (
                                decrementedQuantity > productDetails.unitCount // checking.unitCOunt and comparing with stockCount??
                              ) {
                                // setSnpComponent({
                                //   ...snpComponent,
                                //   snackBarOpen: true,
                                //   snackBarMsg:
                                //     "Exceeds Total Count i.e " +
                                //     productDetails.liveStockCount,
                                // });
                              } else {
                                dispatch(
                                  updateBillItem({
                                    ...billItemDetails,
                                    quantity: decrementedQuantity,
                                  })
                                );
                              }
                            } else {
                              // why??
                              dispatch(
                                updateBillItem({
                                  ...billItemDetails,
                                  quantity: decrementedQuantity,
                                })
                              );
                            }
                          }
                        }}
                      >
                        <RemoveCircle
                          color="warning"
                          sx={{
                            fontSize: "2rem",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>

        <BrownTextTypography variant="p" sx={{ color: "#00333E", pb: 1 }}>
          Selling Price : {billItemDetails.quantity}
          {parseInt(billItemDetails.offerDenom) === OfferType.No_Offer
            ? " x " + billItemDetails.price.sAmt + " = "
            : parseInt(billItemDetails.offerDenom) === OfferType.Flat
            ? " x (" +
              billItemDetails.price.sAmt +
              " - Flat " +
              billItemDetails.offerAmt +
              ") = "
            : " x (" +
              billItemDetails.price.sAmt +
              " - " +
              billItemDetails.offerAmt +
              "%) = "}{" "}
          &#8377; {Number(billItemDetails.finalPrice).toFixed(2)}
          <br />
          {"(Excluded GST@" +
            (parseFloat(productDetails.sgst) +
              parseFloat(productDetails.cgst)) +
            "%)"}
        </BrownTextTypography>
        <Grid container spacing={0}>
          <Grid item md={12} xs={12} lg={12} sm={12}>
            <TextField
              id="note"
              name="note"
              variant="filled"
              size="small"
              rows={3}
              multiline
              onChange={handleInputChange}
              label="Note(If Any)"
              value={billNote}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        showDialog={showEditDailog}
        title={"Update Item"}
        //onClose={handleDialogClose}
        dialogContent={renderEditItem()}
        dialogActions={renderDialogAction()}
      />
    </div>
  );
};

export default BillItemEdit;
