import React, { memo, useEffect } from "react";
import { Autocomplete, Box, Checkbox, 
    FormControlLabel, 
    FormLabel, 
    Grid, 
    Paper, 
    Radio, 
    RadioGroup, 
    TextField, 
    Typography } from "@mui/material";
import ThreeDText from "../../global/ThreeDText";
import { CART_INITIAL_STATE, getBasePayMedium, isNumberEmpty, isRoleAllowed, OfferType, PayMedium, printDebugLog, SALESRIGHTS, twoDecimal } from "../../../constants/constant";

const RenderBillInfo = memo(({ isPaid, paymentDetails, setPaymentDetails,useAssociateBenefit, fireSnackBarMsg }) => {
    
    useEffect(()=> {
        printDebugLog("RenderBillInfo:paymentDetails",paymentDetails);
    },[paymentDetails]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        printDebugLog("handleInputChange", name, value);
        if (name === 'offerDenom') {
            doTotalByOffer(parseInt(value), paymentDetails.offerAmt);
        } else if (name === 'offerAmt') {
            const m_OfferAmt = parseFloat(value) || ''
            doTotalByOffer(paymentDetails.offerDenom, m_OfferAmt);
        } else if (name === 'walletPay') {
            const rt = parseFloat(paymentDetails.total) || 0.0;
            setPaymentDetails(prev => (
                {
                    ...prev,
                    isOnlinePayEnabled: false,
                    onlinePayMedium: "",
                    payMedium: prev.payMedium === PayMedium.ASSOCIATE_WALLET ? PayMedium.NOPAY : PayMedium.ASSOCIATE_WALLET,
                    onlinePay: 0.0,
                    cashPay: 0.0,
                    walletAmt:prev.payMedium === PayMedium.ASSOCIATE_WALLET ? 0.0 : rt ,
                }
            ));
        } else if (name === 'onlinePay') {
            const val = !paymentDetails.isOnlinePayEnabled;
            const rt = parseFloat(paymentDetails.total) || 0.0;
            setPaymentDetails(prev => (
                {
                    ...prev,
                    isOnlinePayEnabled: val,
                    onlinePayMedium: val ? CART_INITIAL_STATE.onlinePayMethod[0] : "",
                    payMedium: getBasePayMedium(prev.isCashPayEnabled, val),
                    onlinePay: !val ? 0.0 : prev.isCashPayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                    cashPay: !prev.isCashPayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                    walletAmt:0.0,
                }
            ));
        } else if (name === 'cashPay') {
            const val = !paymentDetails.isCashPayEnabled;
            const rt = parseFloat(paymentDetails.total) || 0.0;
            setPaymentDetails(prev => (
                {
                    ...prev,
                    isCashPayEnabled: val,
                    cashPay: !val ? 0.0 : prev.isOnlinePayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                    onlinePay: !prev.isOnlinePayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                    payMedium: getBasePayMedium(val, prev.isOnlinePayEnabled),
                    walletAmt:0.0,
                }
            ));
        } else if (name === 'cashTender') {
            if (paymentDetails.total > 0.0) {
                let tot = 0.0;
                if (paymentDetails.isOnlinePayEnabled)
                    tot = paymentDetails.cashPay;
                else
                    tot = paymentDetails.total;
                const rt = (parseFloat(value) || 0.0) - tot;
                if (rt >= 0.0) {
                    const mCashTender = (parseFloat(value) || 0.0) || 0.0;
                    setPaymentDetails(prev => ({
                        ...prev,
                        cashTender: mCashTender,
                        cashReturn: rt,
                    }));
                } else {
                    setPaymentDetails(prev => ({
                        ...prev,
                        cashTender: value,
                        cashReturn: 0.0,
                    }));
                    if (rt < 0.0) {
                        fireSnackBarMsg("Cash Tender should be more or same as Total Amount i.e " + tot);
                    }
                }
            } else {
                fireSnackBarMsg("Please add item to start Bill process");
                setPaymentDetails(prev => ({
                    ...prev,
                    cashTender: value,
                    cashReturn: 0.0,
                }));
            }
        } else if (name === 'onlinePayAmt') {
            const rt = parseFloat(paymentDetails.total) || 0.0;
            if (isNumberEmpty(value)) {
                setPaymentDetails(prev=> (
                    {
                        ...prev,
                        onlinePay: 0.0,
                        cashPay: paymentDetails.isCashPayEnabled ? rt : 0.0,
                        cashTender: 0.0,
                        cashReturn: 0.0,
                        payMedium: getBasePayMedium(paymentDetails.isCashPayEnabled, true),
                    }
                ));
            } else {
                if (parseFloat(value) > rt) {
                    fireSnackBarMsg("Exceeds Total Amount i.e " + rt);
                } else {
                    const mOnlinePay = Number(value);
                    const mCashPay = paymentDetails.isCashPayEnabled ? (rt - mOnlinePay) : 0.0;
                    const mOnlinePayEnb = (mOnlinePay !== 0.0);
                    const mCashPayEnb = (mCashPay !== 0.0);
                    setPaymentDetails(prev=> ({
                        ...prev,
                        onlinePay: mOnlinePay,
                        cashPay: mCashPay,
                        onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                        cashTender: 0.0,
                        cashReturn: 0.0,
                        payMedium: getBasePayMedium(mCashPayEnb, mOnlinePayEnb),
                    }));
                }
            }
        } else if (name === 'cashPayAmt') {
            const rt = parseFloat(paymentDetails.total) || 0.0;
            if (isNumberEmpty(value)) {
                setPaymentDetails(prev=> (
                    {
                        ...prev,
                        cashPay: 0.0,
                        onlinePay: paymentDetails.isOnlinePayEnabled ? rt : 0.0,
                        cashTender: value,
                        cashReturn: 0.0,
                        payMedium: getBasePayMedium(true, paymentDetails.isOnlinePayEnabled),
                    }
                ));
            } else {
                if (parseFloat(value) > rt) {
                    fireSnackBarMsg("Exceeds Total Amount i.e " + rt);
                } else {
                    const mCashPay = Number(value);
                    const mOnlinePay = paymentDetails.isOnlinePayEnabled ? (rt - mCashPay) : 0.0;
                    const mOnlinePayEnb = (mOnlinePay !== 0.0);
                    const mCashPayEnb = (mCashPay !== 0.0);
                    setPaymentDetails(prev => (
                        {
                            ...prev,
                            cashPay: mCashPay,
                            onlinePay: mOnlinePay,
                            onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                            cashTender: 0.0,
                            cashReturn: 0.0,
                            payMedium: getBasePayMedium(mCashPayEnb, mOnlinePayEnb),
                        }
                    ));
                }
            }
        }
    };

    const handleOnlineSelectChange = (event, payMethod) => {
        //printLog("handleOnlineSelectChange", payMethod);
        if (payMethod != null) {
            setPaymentDetails({
                ...paymentDetails,
                onlinePayMedium: payMethod,
            });
        } else {
            setPaymentDetails({
                ...paymentDetails,
                onlinePayMedium: "",
            });
        }
    };

    const doTotalByOffer = (
        m_OfferDenom = OfferType.No_Offer,m_OfferAmt = 0.0 
    ) => {
        let parsedOfferAmt = parseFloat(m_OfferAmt) || 0.0;
        let totalPrice = parseFloat(paymentDetails.totalBeforeOffer);
        switch (parseInt(m_OfferDenom)) {
            case OfferType.Flat:
                totalPrice -= parsedOfferAmt;
                break;
            case OfferType.Percentage:
                parsedOfferAmt = (totalPrice * parsedOfferAmt) / 100;
                totalPrice -= parsedOfferAmt;
                break;
            default:
                parsedOfferAmt = 0.0;
                break;
        }
        const mTotalOfferApplied = Number(paymentDetails.totalOfferApplied) + Number(parsedOfferAmt) - Number(paymentDetails.externalTotalOffer);
        const mRoundOff = totalPrice.toFixed() - totalPrice;
        totalPrice = totalPrice + mRoundOff;
        setPaymentDetails(prev=> ({
            ...prev,
            offerAmt:m_OfferDenom === OfferType.No_Offer ? 0 : m_OfferAmt,
            offerDenom:m_OfferDenom,
            totalOfferApplied:mTotalOfferApplied,
            total:totalPrice,
            cashPay:0.0,
            isCashPayEnabled:false,
            isOnlinePayEnabled:false,
            onlinePay:0.0,
            cashTender:0.0,
            cashReturn:0.0,
            onlinePayMedium:"",
            roundOff:mRoundOff,
            externalTotalOffer:parsedOfferAmt,
        }))
    };

    return (
        <Paper elevation={0}
            autoHeight
            sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "rgb(197, 191, 248)",
                boxShadow: 1,
                borderRadius: 5,
                padding: 1,
            }} >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ThreeDText
                        text="Bill Information"
                        display=""
                        className="three-d-text-container"
                        justifyContent="left"
                        alignItems="left" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={9} sm={6} lg={9}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormLabel id="billOffer">Extra/Additional Offer :</FormLabel>
                                    <RadioGroup row aria-label="bofferDenom" disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="offerDenom" value={paymentDetails.offerDenom} onChange={handleInputChange}>
                                        <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                                        <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.Flat.toString()} control={<Radio />} label="Flat" />
                                        <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.Percentage.toString()} control={<Radio />} label="In Percentage" />
                                    </RadioGroup>
                                </Grid>
                                {
                                    (paymentDetails.offerDenom > 1) &&
                                    <Grid item xs={12}>
                                        <TextField
                                            id="offerAmt"
                                            name="offerAmt"
                                            variant="filled"
                                            size="small"
                                            disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                            label="Enter Offer Value"
                                            value={paymentDetails.offerAmt}
                                            onChange={handleInputChange}
                                            style={{ width: "60%" }}
                                        />
                                    </Grid>
                                }
                                {
                                    useAssociateBenefit && <Grid item xs={12} md={6} sm={6}>
                                        <FormControlLabel disabled
                                            name="walletPay"
                                            checked={paymentDetails.payMedium === PayMedium.ASSOCIATE_WALLET}
                                            control={<Checkbox />} label="Pay Via Urban Wallet" onChange={handleInputChange} />
                                    </Grid>
                                }
                                
                                {
                                    useAssociateBenefit &&
                                    <Grid item xs={12} md={6} sm={6}>
                                        <TextField
                                            id="walletAmt"
                                            name="walletAmt"
                                            variant="filled"
                                            size="small"
                                            style={{ width: "70%" }}
                                            disabled
                                            label="Wallet Amount"
                                            value={paymentDetails.walletAmt}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={2} sm={12}>
                                    <FormControlLabel disabled={paymentDetails.payMedium === PayMedium.ASSOCIATE_WALLET || isPaid || !isRoleAllowed(SALESRIGHTS)}
                                        name="onlinePay"
                                        checked={paymentDetails.isOnlinePayEnabled} control={<Checkbox />} label="Online" onChange={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} md={5} sm={12}>
                                    <Autocomplete
                                        id="online-select"
                                        options={CART_INITIAL_STATE.onlinePayMethod}
                                        autoHighlight
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        isOptionEqualToValue={(option, value) => {
                                            if (!option) return false;
                                            if (!value) return false;
                                            return (option === value);
                                        }}
                                        disabled={isPaid || !paymentDetails.isOnlinePayEnabled || !isRoleAllowed(SALESRIGHTS)}
                                        style={{ width: "70%", maxHeight: "20%" }}
                                        onChange={handleOnlineSelectChange}
                                        getOptionLabel={(option) => option ?? ''}
                                        value={paymentDetails.onlinePayMedium}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                            >
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Online Pay Method"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5} sm={12}>
                                    <TextField
                                        id="onlinePayAmt"
                                        name="onlinePayAmt"
                                        variant="filled"
                                        size="small"
                                        disabled={(isPaid || !paymentDetails.isOnlinePayEnabled
                                            || !paymentDetails.isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                        label="Enter Online Amount"
                                        value={paymentDetails.onlinePay}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} sm={6}>
                                    <FormControlLabel disabled={paymentDetails.payMedium === PayMedium.ASSOCIATE_WALLET || isPaid || !isRoleAllowed(SALESRIGHTS)}
                                        name="cashPay"
                                        checked={paymentDetails.isCashPayEnabled} control={<Checkbox />} label="Cash" onChange={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} md={5} sm={6}>
                                    <TextField
                                        id="cashPayAmt"
                                        name="cashPayAmt"
                                        variant="filled"
                                        size="small"
                                        style={{ width: "70%" }}
                                        disabled={(isPaid || !paymentDetails.isOnlinePayEnabled || !paymentDetails.isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                        label="Enter Cash Amount"
                                        value={paymentDetails.cashPay}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6} lg={3}>
                            {/* <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Total Item: {paymentDetails.totalItem}</Typography> */}
                            <Typography variant="subtitle4" component="div" style={{
                                color: "rgba(3, 56, 136, 0.88)", fontFamily: "monospace"
                                , whiteSpace: 'pre-wrap'
                                , overflowWrap: 'break-word'
                            }} >Sub Total: ₹{twoDecimal(paymentDetails.subTotal)}</Typography>
                            {
                                (paymentDetails.offerDenom === OfferType.Flat) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Additional Offer {"(- Flat )"} {twoDecimal(paymentDetails.offerAmt)}</Typography>
                            }
                            {
                                (paymentDetails.offerDenom === OfferType.Percentage) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Additional Offer {"(-)"} {twoDecimal(paymentDetails.offerAmt) + " %"}</Typography>
                            }
                            {
                                paymentDetails.totalOfferApplied > 0.0 && !isNumberEmpty(paymentDetails.totalOfferApplied) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "rgb(207, 130, 78)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Total Offer ₹{twoDecimal(paymentDetails.totalOfferApplied)}</Typography>
                            }
                            <Typography variant="subtitle4" component="div" style={{ color: "rgba(3, 56, 136, 0.88)", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word',fontSize:"14px" }} >CGST: ₹{twoDecimal(paymentDetails.cgstAmt)}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "rgba(3, 56, 136, 0.88)", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word',fontSize:"14px" }} >SGST: ₹{twoDecimal(paymentDetails.sgstAmt)}</Typography>
                            {
                                (paymentDetails.roundOff !== 0.0 || paymentDetails.roundOff !== -0.0) && !isNumberEmpty(paymentDetails.roundOff) &&
                                <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >RoundOff{parseFloat(paymentDetails.roundOff) > 0.0 ? "(+)" : "(-)"} {parseFloat(paymentDetails.roundOff).toFixed(2)}</Typography>
                            }
                            <Typography variant="subtitle4" component="div" style={{ color: "rgba(4, 63, 34, 0.88)", fontWeight: "bold", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Grand Total: ₹{twoDecimal(paymentDetails.total)}</Typography>
                            <TextField
                                id="cashTender"
                                name="cashTender"
                                variant="filled"
                                size="small"
                                style={{ width: "70%" }}
                                label="Enter Cash Tender"
                                onChange={handleInputChange}
                                value={paymentDetails.cashTender}
                                disabled={isPaid}
                                sx={{
                                    marginTop: 2,
                                }}

                            />
                            {
                                paymentDetails.cashReturn > 0.0 &&
                                <Typography variant="subtitle4" component="div" style={{ color: "rgb(141, 143, 9)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Cash Return: ₹{twoDecimal(paymentDetails.cashReturn)}</Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default RenderBillInfo;
