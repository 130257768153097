import { FormLabel, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import PriceTag from "../../global/PriceTag";
import { isEmpty, printDebugLog } from "../../../constants/constant";
import uiUtilInstance from "../../../utilities/uiUtil";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const ReturnDialogContent = ({ celData, itemDetails, stockInfos , setStockInfos,isPaid }) => {

    const colQuantityClasses = uiUtilInstance.useStyles();

    const handleIncrement = (row) => {
        printDebugLog("Incrementing quantity for", row);
        setStockInfos((prev) =>
            prev.map((item) =>
              item.stockRefNo === row.stockRefNo
                ? { ...item, quantity: item.quantity < item.maxQuan ?  item.quantity + 1 : item.maxQuan }
                : item
            )
          );
    };

    const handleDecrement = (row) => {
        printDebugLog("Decrementing quantity for", row);
        setStockInfos((prev) =>
            prev.map((item) =>
              item.stockRefNo === row.stockRefNo
                ? { ...item,  quantity: item.quantity > 0 ? item.quantity - 1 : 0 }
                : item
            )
          );
    };


    const getProductDetailsUI = () => {
        return (
            <Paper elevation={10}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#9db5ab",
                    boxShadow: 1,
                    borderRadius: 4,
                    padding: 2,
                }} >
                <div>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Item Name</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{celData.name}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Type</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.prdType}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Rack No</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.rack_no}</span>
                    </Typography>
                    <Typography
                        variant="p"
                        style={{ fontSize: "14px" }}
                        component="div">
                        <span style={{ color: "blue", fontWeight: "bold" }}>Unit Details</span>
                        <span style={{ margin: "0 4px" }}>:</span>
                        <span style={{
                            color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{itemDetails.cnvDetails}</span>
                    </Typography>
                    <PriceTag price={Number(celData.price) || 0.0} finalPrice={Number(celData.finalPrice) || 0.0} offerAmt={Number(celData.appliedOfferAmt) || 0.0} />
                </div>
            </Paper>
        );
    };

    const getStockDetailsUI = (index, info) => {
        return (
            <Paper elevation={10} key={index}
                autoHeight
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "#c0d6e4",
                    boxShadow: 1,
                    borderRadius: 4,
                    padding: 2,
                }} >
                <Grid container spacing={1} key={index}>
                    <Grid item xs={6}>
                        <Typography
                            variant="p"
                            style={{
                                color: "#480404",
                                fontWeight: "bold",
                                fontSize: "14px", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>{info.stockName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant="p"
                            style={{
                                fontSize: "14px", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>T.Quan: {`${info.maxQuan} ${info.unit}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={isEmpty(info.batchNo) ? 12 : 6}>
                        <Typography
                            variant="p"
                            style={{
                                color: "#480404",
                                fontSize: "14px", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>BarCode: {info.barCode}
                        </Typography>
                    </Grid>
                    <Grid item xs={isEmpty(info.barCode) ? 12 : 6}>
                        <Typography
                            variant="p"
                            style={{
                                color: "#480404",
                                fontSize: "14px", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word'
                            }}>BatchNum: {info.batchNo}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ marginTop: "5px" }}>
                            <FormLabel component="legend"><span style={{ color: "#480404", fontWeight: "bold" }}>Quantity:</span></FormLabel>
                            <TextField
                                id="dialogQuan"
                                className={colQuantityClasses.input}
                                name="dialogQuan"
                                variant="outlined"
                                size="small"
                                disabled
                                value={Number(info.quantity) || 0}
                                style={{ width: "30%", maxHeight: "20%" }}
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                }}
                                InputProps={{
                                    inputMode: "decimal",
                                    startAdornment: (
                                            Number(info.quantity) === Number(info.maxQuan) && !isPaid
                                            ? "" :
                                            <InputAdornment
                                            position="start"
                                        >
                                            <IconButton onClick={()=> handleIncrement(info)}
                                                sx={{ backgroundColor: "green", padding: 0, "&:hover": { backgroundColor: "darkgreen" } }}>
                                                <AddIcon sx={{ color: "white", fontSize: "2rem" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        Number(info.quantity) === 0 && !isPaid
                                        ? "" :
                                        <InputAdornment
                                            position="end"
                                        >
                                            <IconButton onClick={()=> handleDecrement(info)}
                                                sx={{ backgroundColor: "red", padding: 0, "&:hover": { backgroundColor: "darkred" } }}>
                                                <RemoveIcon sx={{ color: "white", fontSize: "2rem" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {getProductDetailsUI()}
            </Grid>
            <Grid item xs={8}>
                {
                    stockInfos && stockInfos.map((info, index) => (
                        getStockDetailsUI(index, info)
                    ))
                }
            </Grid>
        </Grid>
    );
}
export default ReturnDialogContent;