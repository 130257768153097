import React, { useState } from "react";
import CustomDataGrid from "../global/CustomDataGrid";
import { Grid, TextField, Checkbox, Autocomplete, Box, Tooltip, InputLabel, MenuItem, Select } from "@mui/material";
import Loader from "../global/loader/Loader";
import Paper from "@mui/material/Paper";
import "../common.css";
import {
  GET_SINGLE_PRODUCT_DATA,
  SET_MART_STOCK_DATA,
  SET_PHARMA_STOCK_DATA,
  SET_PIPE_STOCK,
  GET_SUPPLIER_FEW_DATA,
  GET_STOCK_DATA,
  GETSTOCKBARCODE,
  DELETESTOCK,
  getPreUrlAccordingToCart,
  SUCCESS,
  UPDATE_CUSTOMER_PRICES,
  CART_INITIAL_STATE,
  isEmpty,
  CartDets,
  OfferType,
  BillStatus, DEBIT, AccountingPaidMode, isRoleAllowed,
  INVENTORYUPDATEPRICING, NODATA, ACCESSDENIED, SESSIONEXPIRED, FAILED, STOCK_PRIORITY,
  printDebugLog,
  printInfoLog,
  martRateCardTypes,
  isNumberEmpty,
  STOCKCREATE,
  STOCKUPDATE,
  local_test_url,
  GETPRODDETAILS,
  twoDecimal,
  SupplierSundryStock,
} from "../../constants/constant";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import {
  FormControl, RadioGroup, FormControlLabel,
  FormLabel, Radio, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { snpAxios } from '../global/api';
import SnpComponent from "../global/SnpComponent";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import { useLocation, useNavigate } from "react-router-dom";
import ThreeDText from "../global/ThreeDText";
import EnterButton from "../global/EnterButton";
import uiUtilInstance from "../../utilities/uiUtil";
export default function StockEntry() {

  const SLA_TIME_DENOM = ["hours","days","month"];

  const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: false,
    iconContent: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: null,
    handleSnpCancel: null,
    gui: "",
  };
  const INITIAL_ITEM_DETAILS = {
    prd: "",
    prdId: "",
    catgName: "",
    hsnCode: "",
    productImage: "",
    cnvDetails: "",
    unitName: "",
    smallUnitName: "",
    smallUnitCount: 0,
    unitRestrictions: false,
    unitMultiplierName: "",
    unitMultiplierCount: 0,
    smallUnitRestrictions: false,
    rack_no: "",
    composition: [],
  };
  const BIG_UNIT_PRICE = {
    productmrPrice: 0.0,
    offerDenom: 1,
    offerAmt: 0.0,
    productSellingPrice: 0.0,
    productWholeSalePrice: 0.0,
  };
  const SMALL_UNIT_PRICE = {
    sproductmrPrice: 0.0,
    sofferDenom: 1,
    sofferAmt: 0.0,
    sproductSellingPrice: 0.0,
    sproductWholeSalePrice: 0.0,
  };
  const INITIAL_STOCK_MGMT = {
    liveStockData: "",
    liveStockOnlineData: "",
    expiryLiveStockData: "0",
    saleSGst: 0.0,
    saleCgst: 0.0,
    saleIgst: 0.0,
    purchaseIGst: 0.0,
    purchaseCgst: 0.0,
    purchaseSgst: 0.0,
    sbcCessTax: 0.0,
    custGstDisabled: false,
    buyerGstTaken: true,
    taxMethod: true,
    taxMethodWs: false,
    bigUnitPriceMap: [],
    smallUnitPriceMap: [],
    stockThresHold:0,
  };
  const INITIAL_STOCK_SINGLE_DATA = {
    prdId: "",
    stockRefNo: "",
    billNo: "",
    batchNo: "",
    barcodeNo: "",
    mfgDate: "",
    expiryDate: "",
    nearByExpiry: false,
    isExpired: false,
    expirySla: "",
    expirySlaTimeDenom:"days",
    quantity: 0,
    sQuantity: 0,
    mQuantity: 0,
    notGoodQuality: false,
    supplierId: "",
    supplierName: "",
    saleSGst: 0.0,
    saleCgst: 0.0,
    saleIgst: 0.0,
    purchaseIGst: 0.0,
    purchaseCgst: 0.0,
    purchaseSgst: 0.0,
    sbcCessTax: 0.0,
    custGstDisabled: false,
    buyerGstTaken: true,
    outOfStock: false,
    taxMethod: true,
    taxMethodWs: false,
    columnDetails: "",
    status: 1,
    productmrPrice: 0.0,
    offerDenom: 1,
    offerAmt: 0.0,
    productSellingPrice: 0.0,
    productWholeSalePrice: 0.0,
    sproductmrPrice: 0.0,
    sofferDenom: 1,
    sofferAmt: 0.0,
    sproductSellingPrice: 0.0,
    sproductWholeSalePrice: 0.0,
    mproductmrPrice: 0.0,
    mofferDenom: 1,
    mofferAmt: 0.0,
    mproductSellingPrice: 0.0,
    mproductWholeSalePrice: 0.0,
    purchasePrice: 0.0,
    priorityHighinSell: false,
    containsFreeStock: false,
    ruleMain: -1,
    ruleFree: -1,
    combo: false,
  };

  const [supplierSingleData, setSupplierSingleData] = useState({
    supplierId: "",
    supplierName: "",
  });

  const [account, setAccount] = useState({
    accStatus: BillStatus.NO_ENTRY,
    accAmt: 0.0,
    partialAmt: 0.0,
  });


  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
  const location = useLocation();
  const navigate = useNavigate();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isEditProduct, setEditProduct] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [itemDets, setItemDets] = useState(INITIAL_ITEM_DETAILS);
  const [stockManagement, setStockManagement] = useState(INITIAL_STOCK_MGMT);
  const [supplierData, setSupplierData] = useState([]);
  const [stockSingleData, setSingleStockData] = useState(INITIAL_STOCK_SINGLE_DATA);
  const [bigUnitePriceMap, setBigUnitPriceMap] = useState(BIG_UNIT_PRICE);
  const [smallUnitPriceMap, setSmallUnitPriceMap] = useState(SMALL_UNIT_PRICE);
  //const [stockManagement, setStockManagement] = useState(INITIAL_STOCK_MGMT);
  const [hideCustomerPrice, setHideCustomerPrice] = useState(true);
  const [rateCards, setRateCards] = useState(martRateCardTypes[0]);
  const [listStock, setListStock] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [supplierSundryAllowed,setSupplierSundryAllowed] = useState(false);
  const [supplierSundryDenom,setSupplierSundryDenom] = useState(SupplierSundryStock.Not_Paid);
  const [listSundryBillRef, setSundryBillRef] = useState([]);
  const columns = [
    {
      field: 'stockRefNo', headerName: 'Stock Ref No', width: 200,
      renderCell: (val) => (
        <Tooltip title={val.row.stockRefNo} >
          <span className="table-cell-trucate">{val.row.stockRefNo}</span>
        </Tooltip>
      ),
    },
    { field: 'billNo', headerName: 'Bill No', width: 140 },

    {
      field: 'columnDetails', headerName: 'Added Quan', width: 200
    },
    {
      field: 'remQuanStr', headerName: 'Rem. Quan', width: 200
    },
    {
      field: 'expiry', headerName: 'Expired On', width: 140,
      renderCell: (val) => {
        return (val.row.isExpired) ? val.row.expiryDate + "(Expired)" : val.row.expiryDate;
      }
    },
    {
      field: 'outOfStock', headerName: 'Out Of Stock', width: 110,
      renderCell: (val) => {
        return (val.row.outOfStock ? "Yes" : "No");
      }
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 75,
      renderCell: (cellValues) => {
        return (
          <SnpButton variant="contained"
            mbgcolor="#538796"
            mpadding="1px"
            onClick={(event) => {
              if (isRoleAllowed(STOCKUPDATE)) {
                handleCellClick(event, cellValues);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                  handleSnpSubmit: () => handleDialogClose(),
                });
              }
            }}
          >Edit</SnpButton>
        );
      }
    },
    {
      field: "del",
      headerName: "Del",
      width: 90,
      renderCell: (cellValues) => {
        return (
          !cellValues.row.cantDelete &&
          <SnpButton variant="contained"
            mbgcolor="#ef2a28"
            mpadding="1px"
            onClick={(event) => {
              handleCellClick(event, cellValues);
            }}
          >Delete</SnpButton>
        );
      }
    },
    {
      field: 'priorityHighinSell', headerName: 'Priority', width: 110,
      renderCell: (val) => {
        return (val.row.priorityHighinSell ? "Yes" : "No");
      }
    },
  ];

  const handleCellClick = (param, event) => {
    printDebugLog("setSingleStockData : ", event.row);
    if (event.field === 'edit') {
      setSupplierSingleData({
        ...supplierSingleData,
        supplierId: event.row.supplierId,
        supplierName: event.row.supplierName,
      });

      setSingleStockData({
        ...stockSingleData, ...event.row,
      });
      setShowDialog(true);
      setEditProduct(true);
    } else if (event.field === 'del') {
      deleteStock(event.row.stockRefNo);
    }
  };

  const handleSnackClose = () => {
    setSnpComponent({
      ...snpComponent,
      snackBarOpen: false,
      snackBarMsg: "",
    });
  };

  const getErrorMessage = (field) => {
    if (!formSubmitted) {
      return;
    } else if (!stockSingleData[field]) {
      return 'Field is required';
    }
  }

  const isValid = () => {
    let isStockDataValid = true;
    if (!stockSingleData.billNo || !stockSingleData.supplierId) {
      isStockDataValid = false;
    }
    return isStockDataValid;
  };

  function getPersistUrl() {
    if (storeCartType === CartDets.Pharma)
      return SET_PHARMA_STOCK_DATA;
    else if (storeCartType === CartDets.Kirana)
      return SET_MART_STOCK_DATA;
    else if (storeCartType === CartDets.Pipe)
      return SET_PIPE_STOCK;
    return "";
  }

  const clearstockData = () => {
    setSupplierSingleData({
      ...supplierSingleData,
      supplierId: "",
      supplierName: "",
    });
    setAccount({
      ...account,
      accStatus: BillStatus.NO_ENTRY,
      accAmt: 0.0,
      partialAmt: 0.0,
    })

    setSingleStockData(INITIAL_STOCK_SINGLE_DATA);
  };

  const handleDialogClose = () => {
    // console.log("handleDialogClose");
    setShowDialog(false);
    clearstockData();
    setEditProduct(false);
    setFormSubmitted(false);
  };

  const handleHideChange = () => {
    setHideCustomerPrice(!hideCustomerPrice);
  };


  const getTaxTag = (taxMethod) => {
    return taxMethod ? "Inc. GST" : "Exl. GST";
  };

  const getTaxPercentage = (cgst, sgst) => {
    const mTotal = (parseFloat(cgst) || 0.0) + (parseFloat(sgst) || 0.0);
    if (mTotal > 0.0) {
      return mTotal + "%";
    }
    return "";
  }



  const calculatePurchasePriceBasedOnTaxMethod = (mMethod, cgst, sgst) => {
    const parsedCgst = parseFloat(cgst) || 0.0;
    const parsedSgst = parseFloat(sgst) || 0.0;
    let parsedPrice = parseFloat(stockSingleData.productWholeSalePrice) || 0.0;
    let parsedSmallPrice = parseFloat(stockSingleData.sproductWholeSalePrice) || 0.0;
    printDebugLog("calculatePurchasePriceBasedOnTaxMethod", mMethod, parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice);
    const calculatePrice = (price, factor) => (price !== 0.0 ? (price / factor).toFixed(2) : price);

    if (!mMethod) {
      //Exclude GST
      const denominator = 100 + parsedCgst + parsedSgst;
      parsedSmallPrice = calculatePrice(parsedSmallPrice, denominator / 100);
      parsedPrice = calculatePrice(parsedPrice, denominator / 100);
    } else {
      //Include GST
      const multiplier = 100 / (100 + parsedCgst + parsedSgst);
      parsedSmallPrice = calculatePrice(parsedSmallPrice, multiplier);
      parsedPrice = calculatePrice(parsedPrice, multiplier);
    }
    return { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice };
  };

  const calculateRegularPriceBasedOnTaxMethod = (mMethod, cgst, sgst,
    mOfferDenom, mOfferAmt, mSofferDenom, mSofferAmt, mPrice, mSmallPrice) => {
    const parsedCgst = parseFloat(cgst) || 0.0;
    const parsedSgst = parseFloat(sgst) || 0.0;
    let parsedOfferAmt = parseFloat(mOfferAmt) || 0.0;
    const parsedOfferDenom = Number(mOfferDenom) || OfferType.No_Offer;
    let parsedSOfferAmt = parseFloat(mSofferAmt) || 0.0;
    const parsedSOfferDenom = Number(mSofferDenom) || OfferType.No_Offer;
    let parsedPrice = parseFloat(mPrice) || 0.0;
    let parsedSmallPrice = parseFloat(mSmallPrice) || 0.0;

    const calculatePrice = (price, factor) => (price !== 0.0 ? (price / factor).toFixed(2) : price);
    let parsedSp = getOfferTag(parsedOfferDenom, parsedOfferAmt, parsedPrice);//without GST always
    let parsedSmallSp = getOfferTag(parsedSOfferDenom, parsedSOfferAmt, parsedSmallPrice);

    if (!mMethod) {
      const denominator = 100 + parsedCgst + parsedSgst;
      parsedSmallPrice = calculatePrice(parsedSmallPrice, denominator / 100);
      parsedPrice = calculatePrice(parsedPrice, denominator / 100);
      if (parsedOfferDenom !== OfferType.Percentage) {
        parsedOfferAmt = calculatePrice(parsedOfferAmt, denominator / 100);
        parsedSOfferAmt = calculatePrice(parsedSOfferAmt, denominator / 100);
      }
      parsedSp = calculatePrice(parsedSp, denominator / 100);
      parsedSmallSp = calculatePrice(parsedSmallSp, denominator / 100);
    } else {
      const multiplier = 100 / (100 + parsedCgst + parsedSgst);
      parsedSmallPrice = calculatePrice(parsedSmallPrice, multiplier);
      parsedPrice = calculatePrice(parsedPrice, multiplier);
      if (parsedOfferDenom !== OfferType.Percentage) {
        parsedOfferAmt = calculatePrice(parsedOfferAmt, multiplier);
        parsedSOfferAmt = calculatePrice(parsedSOfferAmt, multiplier);
      }
      parsedSp = calculatePrice(parsedSp, multiplier);
      parsedSmallSp = calculatePrice(parsedSmallSp, multiplier);
    }

    return { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice, parsedSp, parsedSmallSp, parsedOfferAmt, parsedSOfferAmt };
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    printDebugLog("handleCheckboxChange", name, checked);
    if (name === 'buyerGstTaken') {
      const taxMethodWs = checked ? stockSingleData.taxMethodWs : false;
      const { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice } =
        calculatePurchasePriceBasedOnTaxMethod(taxMethodWs,
          checked ? stockSingleData.purchaseCgst : 0.0,
          checked ? stockSingleData.purchaseSgst : 0.0);
      setSingleStockData(prev => (
        {
          ...prev,
          buyerGstTaken: checked,
          productWholeSalePrice: parsedPrice,
          sproductWholeSalePrice: parsedSmallPrice,
          purchaseCgst: parsedCgst,
          purchaseSgst: parsedSgst,
          taxMethodWs: taxMethodWs,
        }
      ));
    } else if (name === 'custGstDisabled') {
      const taxMethod = checked ? stockSingleData.taxMethod : false;
      const { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice, parsedSp, parsedSmallSp, parsedOfferAmt, parsedSOfferAmt } =
        calculateRegularPriceBasedOnTaxMethod(taxMethod
          , checked ? stockSingleData.saleCgst : 0.0
          , checked ? stockSingleData.saleSGst : 0.0
          , stockSingleData.offerDenom, stockSingleData.offerAmt,
          stockSingleData.sofferDenom, stockSingleData.sofferAmt,
          stockSingleData.productmrPrice, stockSingleData.sproductmrPrice);

      setSingleStockData(prev => (
        {
          ...prev,
          custGstDisabled: checked,
          productmrPrice: parsedPrice,
          sproductmrPrice: parsedSmallPrice,
          productSellingPrice: parsedSp,
          sproductSellingPrice: parsedSmallSp,
          saleCgst: parsedCgst,
          saleSGst: parsedSgst,
          taxMethod: taxMethod,
          offerAmt: parsedOfferAmt,
          sofferAmt: parsedSOfferAmt,
        }
      ));
    } else if (name === 'containsFreeStock') {
      let previousCheckedValue = stockSingleData.containsFreeStock;
      setSingleStockData({
        ...stockSingleData,
        containsFreeStock: !previousCheckedValue,
      });
    }
  };


  const handleNumberChange = (name, value) => {
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setSingleStockData({
        ...stockSingleData,
        [name]: value,
      });
    }
  }


  const handleUpdateBigPrice = () => {
    let quantity = 0;
    let productWholeSalePrice = 0.0;
    let productmrPrice = 0.0;
    let productSellingPrice = 0.0;
    if (!itemDets.unitRestrictions) {
      const actualSQuantity = parseInt(stockSingleData.sQuantity) || 0;
      const actualSProductWholeSalePrice = parseFloat(stockSingleData.sproductWholeSalePrice) || 0.0;
      const actualSProductmrPrice = parseFloat(stockSingleData.sproductmrPrice) || 0.0;
      quantity = actualSQuantity / itemDets.smallUnitCount;
      quantity = Math.floor(Number(quantity));
      productWholeSalePrice = Number(stockSingleData.productWholeSalePrice) > 0.0 
            ? parseFloat(stockSingleData.productWholeSalePrice) : actualSProductWholeSalePrice * itemDets.smallUnitCount;
      productmrPrice = Number(stockSingleData.productmrPrice) > 0.0  ? parseFloat(stockSingleData.productmrPrice) : actualSProductmrPrice * itemDets.smallUnitCount;
      productSellingPrice = getOfferTag(stockSingleData.offerDenom, stockSingleData.offerAmt, productmrPrice);
    }
    return { quantity, productWholeSalePrice, productmrPrice, productSellingPrice };
  }

  const handleUpdateSmallPrice = () => {
    let sQuantity = 0;
    let sproductWholeSalePrice = 0.0;
    let sproductmrPrice = 0.0;
    let sproductSellingPrice = 0.0;
    if (!itemDets.unitRestrictions) {
      const actualQuantity = parseInt(stockSingleData.quantity) || 0;
      const actualProductWholeSalePrice = parseFloat(stockSingleData.productWholeSalePrice) || 0.0;
      const actualProductmrPrice = parseFloat(stockSingleData.productmrPrice) || 0.0;
      sQuantity = actualQuantity * itemDets.smallUnitCount;
      sQuantity = Number(sQuantity);
      if (Number(stockSingleData.sQuantity) > sQuantity) 
        sQuantity = Number(stockSingleData.sQuantity);
      sproductWholeSalePrice = Number(stockSingleData.sproductWholeSalePrice) > 0.0 ? Number(stockSingleData.sproductWholeSalePrice) : actualProductWholeSalePrice / itemDets.smallUnitCount;
      sproductmrPrice = Number(stockSingleData.sproductmrPrice) > 0.0 ? Number(stockSingleData.sproductmrPrice) : actualProductmrPrice / itemDets.smallUnitCount;
      sproductSellingPrice = getOfferTag(stockSingleData.sofferDenom, stockSingleData.sofferAmt, sproductmrPrice);
    }
    return { sQuantity, sproductWholeSalePrice, sproductmrPrice, sproductSellingPrice };
  }

  const handleStockAiQuantityPrices = () => {
    let quantity = 0;
    let productWholeSalePrice = 0.0;
    let productmrPrice = 0.0;
    let productSellingPrice = 0.0;
    let sQuantity = 0;
    let sproductWholeSalePrice = 0.0;
    let sproductmrPrice = 0.0;
    let sproductSellingPrice = 0.0;
    //Exist Constant
    const actualQuantity = parseInt(stockSingleData.quantity) || 0;
    const actualProductWholeSalePrice = parseFloat(stockSingleData.productWholeSalePrice) || 0.0;
    const actualProductmrPrice = parseFloat(stockSingleData.productmrPrice) || 0.0;
    const actualSQuantity = parseInt(stockSingleData.sQuantity) || 0;
    const actualSProductWholeSalePrice = parseFloat(stockSingleData.sproductWholeSalePrice) || 0.0;
    const actualSProductmrPrice = parseFloat(stockSingleData.sproductmrPrice) || 0.0;
    if (!itemDets.unitRestrictions) {
      const FACTOR = Number(itemDets.smallUnitCount);
      // Adjust quantities
      if (actualQuantity > 0 && actualSQuantity > 0) {
        const tempSmallQuan = actualQuantity * FACTOR;
        if (tempSmallQuan > actualSQuantity) {
          //Case 11 & 106
          quantity = actualQuantity;
          sQuantity = tempSmallQuan;
        } else if (actualSQuantity > tempSmallQuan) {
          //Case 10 & 111
          quantity = actualSQuantity / FACTOR;
          quantity = Math.floor(Number(quantity));
          sQuantity = actualSQuantity;
        } else {
          quantity = actualQuantity;
          sQuantity = actualSQuantity;
        }
      } else if (actualQuantity > 0) {
        sQuantity = actualQuantity * FACTOR;
        quantity = actualQuantity;
      } else if (actualSQuantity > 0) {
        quantity = actualSQuantity / FACTOR;
        quantity = Math.floor(Number(quantity));
        sQuantity = actualSQuantity;
      } else {
        quantity = actualQuantity;
        sQuantity = actualSQuantity;
      }
      // Adjust wholesale prices
      if (actualSProductWholeSalePrice > 0 && actualProductWholeSalePrice <= 0) {
        productWholeSalePrice = actualSProductWholeSalePrice * FACTOR;
        sproductWholeSalePrice = actualSProductWholeSalePrice;
      } else if (actualSProductWholeSalePrice <= 0 && actualProductWholeSalePrice > 0) {
        productWholeSalePrice = actualProductWholeSalePrice;
        sproductWholeSalePrice = actualProductWholeSalePrice / FACTOR;
      } else {
        productWholeSalePrice = actualProductWholeSalePrice;
        sproductWholeSalePrice = actualSProductWholeSalePrice;
      }

      // Adjust MRP & Selling prices
      if (actualSProductmrPrice > 0 && actualProductmrPrice <= 0) {
        productmrPrice = actualSProductmrPrice * FACTOR;
        sproductmrPrice = actualSProductmrPrice;
      } else if (actualSProductmrPrice <= 0 && actualProductmrPrice > 0) {
        productmrPrice = actualProductmrPrice;
        sproductmrPrice = actualProductmrPrice / FACTOR;
      } else {
        productmrPrice = actualProductmrPrice;
        sproductmrPrice = actualSProductmrPrice;
      }

      sproductSellingPrice = getOfferTag(stockSingleData.sofferDenom, stockSingleData.sofferAmt, sproductmrPrice);
    } else {
      quantity = actualQuantity;
      productWholeSalePrice = actualProductWholeSalePrice;
      productmrPrice = actualProductmrPrice;
    }

    productSellingPrice = getOfferTag(stockSingleData.offerDenom, stockSingleData.offerAmt, productmrPrice);
    return {
      quantity, productWholeSalePrice, productmrPrice
      , productSellingPrice, sQuantity, sproductWholeSalePrice, sproductmrPrice, sproductSellingPrice
    };
  };

  const handleSelectBillRef = (ev, jcJson) => {
    printDebugLog("handleSelectBillRef",jcJson);
    if (jcJson !== null) {
      setSupplierSingleData(prev=> ({
        ...prev,supplierId:jcJson.supplierId,supplierName:jcJson.supplierName,
      }));
      setSingleStockData(prev=> ({
        ...prev,billNo:jcJson.billNo,purchasePrice:jcJson.purchasePrice,
        supplierId: jcJson.supplierId,
        supplierName: jcJson.supplierName
      }))
    } else {
      setSupplierSingleData(prev=> ({
        ...prev,supplierId:'',supplierName:'',
      }));
      setSingleStockData(prev=> ({
        ...prev,billNo:'',purchasePrice:'',
        supplierId: '',
        supplierName: ''
      }))
    }
  };

  const handleSundryBillSearch = (event) => {
    const mBillRef = event.target.value;
    printDebugLog("handleSundryBillSearch",mBillRef);
      if (mBillRef.length > 3) {
        getSundryBillRef(mBillRef);
      }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    printDebugLog("handleInputChange", name, value);
    if (name === 'productWholeSalePrice'
      || name === 'sproductWholeSalePrice'
      || name === 'mproductWholeSalePrice') {
      handleNumberChange(name, value);
    } else if (name === 'productmrPrice') {
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        //const mrp = parseFloat(value) || 0.0; Remove because decimal not supported
        const price = getOfferTag(stockSingleData.offerDenom, stockSingleData.offerAmt, value);
        setSingleStockData({
          ...stockSingleData,
          productmrPrice: value,
          productSellingPrice: price,
        });
      }
    } else if (name === 'sproductmrPrice') {
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        //const mrp = parseFloat(value) || 0.0;
        const price = getOfferTag(stockSingleData.sofferDenom, stockSingleData.sofferAmt, value);
        setSingleStockData({
          ...stockSingleData,
          sproductmrPrice: value,
          sproductSellingPrice: price,
        });
      }
    } else if (name === 'mproductmrPrice') {
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        //const mrp = parseFloat(value) || 0.0;
        const price = getOfferTag(stockSingleData.mofferDenom, stockSingleData.mofferAmt, value);
        setSingleStockData({
          ...stockSingleData,
          mproductmrPrice: value,
          mproductSellingPrice: price,
        });
      }
    } else if (name === 'offerDenom') {
      let price = stockSingleData.productSellingPrice;
      if (stockSingleData.productmrPrice > 0.0) {
        if (Number(value) === OfferType.No_Offer) {
          price = stockSingleData.productmrPrice;
        } else if (Number(value) === OfferType.Flat) {
          price = stockSingleData.productmrPrice - stockSingleData.offerAmt;
        } else if (Number(value) === OfferType.Percentage) {
          price = stockSingleData.productmrPrice
            - ((stockSingleData.productmrPrice * stockSingleData.offerAmt) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        offerDenom: value,
        productSellingPrice: price,
      });
    } else if (name === 'sofferDenom') {
      let price = stockSingleData.sproductSellingPrice;
      if (stockSingleData.sproductmrPrice > 0.0) {
        if (parseInt(value) === OfferType.No_Offer) {
          price = stockSingleData.sproductmrPrice;
        } else if (parseInt(value) === OfferType.Flat) {
          price = stockSingleData.sproductmrPrice - stockSingleData.sofferAmt;
        } else if (parseInt(value) === OfferType.Percentage) {
          price = stockSingleData.sproductmrPrice
            - ((stockSingleData.sproductmrPrice * stockSingleData.sofferAmt) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        sofferDenom: value,
        sproductSellingPrice: price,
      });
    } else if (name === 'mofferDenom') {
      let price = stockSingleData.mproductSellingPrice;
      if (stockSingleData.mproductmrPrice > 0.0) {
        if (parseInt(value) === OfferType.No_Offer) {
          price = stockSingleData.mproductmrPrice;
        } else if (parseInt(value) === OfferType.Flat) {
          price = stockSingleData.mproductmrPrice - stockSingleData.mofferAmt;
        } else if (parseInt(value) === OfferType.Percentage) {
          price = stockSingleData.mproductmrPrice
            - ((stockSingleData.mproductmrPrice * stockSingleData.mofferAmt) / 100);
        }
      }
      setSingleStockData({
        ...stockSingleData,
        mofferDenom: value,
        mproductSellingPrice: price,
      });
    } else if (name === 'offerAmt') {
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        const parsedOfferAmt = parseFloat(value) || 0;
        let price = stockSingleData.productSellingPrice;
        if (stockSingleData.productmrPrice > 0.0) {
          if (parseInt(stockSingleData.offerDenom) === OfferType.No_Offer) {
            price = stockSingleData.productmrPrice;
          } else if (parseInt(stockSingleData.offerDenom) === OfferType.Flat) {
            price = stockSingleData.productmrPrice - parsedOfferAmt;
          } else if (parseInt(stockSingleData.offerDenom) === OfferType.Percentage) {
            price = stockSingleData.productmrPrice
              - ((stockSingleData.productmrPrice * parsedOfferAmt) / 100);
          }
        }
        setSingleStockData({
          ...stockSingleData,
          offerAmt: value,
          productSellingPrice: price,
        });
      }
    } else if (name === 'sofferAmt') {
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        let price = stockSingleData.sproductSellingPrice;
        const parsedOfferAmt = parseFloat(value) || 0;
        if (stockSingleData.sproductmrPrice > 0.0) {
          if (parseInt(stockSingleData.sofferDenom) === OfferType.No_Offer) {
            price = stockSingleData.sproductmrPrice;
          } else if (parseInt(stockSingleData.sofferDenom) === OfferType.Flat) {
            price = stockSingleData.sproductmrPrice - parsedOfferAmt;
          } else if (parseInt(stockSingleData.sofferDenom) === OfferType.Percentage) {
            price = stockSingleData.sproductmrPrice
              - ((stockSingleData.sproductmrPrice * parsedOfferAmt) / 100);
          }
        }
        setSingleStockData({
          ...stockSingleData,
          sofferAmt: value,
          sproductSellingPrice: price,
        });
      }
    } else if (name === 'taxMethodWs') {
      const previousCheckedValue = !stockSingleData.taxMethodWs;
      const { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice } =
        calculatePurchasePriceBasedOnTaxMethod(previousCheckedValue, stockSingleData.purchaseCgst, stockSingleData.purchaseSgst);
      setSingleStockData(prev => (
        {
          ...prev,
          productWholeSalePrice: parsedPrice,
          sproductWholeSalePrice: parsedSmallPrice,
          purchaseCgst: parsedCgst,
          purchaseSgst: parsedSgst,
          taxMethodWs: previousCheckedValue,
        }
      ));
    } else if (name === 'taxMethod') {
      const previousCheckedValue = !stockSingleData.taxMethod;
      const { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice, parsedSp, parsedSmallSp, parsedOfferAmt, parsedSOfferAmt } =
        calculateRegularPriceBasedOnTaxMethod(previousCheckedValue
          , stockSingleData.saleCgst
          , stockSingleData.saleSGst
          , stockSingleData.offerDenom, stockSingleData.offerAmt,
          stockSingleData.sofferDenom, stockSingleData.sofferAmt,
          stockSingleData.productmrPrice, stockSingleData.sproductmrPrice);

      setSingleStockData(prev => (
        {
          ...prev,
          productmrPrice: parsedPrice,
          sproductmrPrice: parsedSmallPrice,
          productSellingPrice: parsedSp,
          sproductSellingPrice: parsedSmallSp,
          saleCgst: parsedCgst,
          saleSGst: parsedSgst,
          taxMethod: previousCheckedValue,
          offerAmt: parsedOfferAmt,
          sofferAmt: parsedSOfferAmt,
        }
      ));
    }
  };

  const getOfferTag = (offerDenom, offerAmt, value) => {
    let price = 0.0;
    const parsedOfferAmt = parseFloat(offerAmt) || 0.0;
    if (value === 0.0)
      return price;
    if (parseInt(offerDenom) === OfferType.No_Offer) {
      price = value;
    } else if (parseInt(offerDenom) === OfferType.Flat) {
      price = value - parsedOfferAmt;
    } else if (parseInt(offerDenom) === OfferType.Percentage) {
      price = value
        - ((value * parsedOfferAmt) / 100);
    }
    return price;
  };


  const handleSupplierChange = (event, option) => {
    if (option != null) {
      //console.log("handleSupplierChange", event, option);
      setSupplierSingleData({
        ...supplierSingleData,
        supplierId: option.supplierId,
        supplierName: option.supplierName
      });
      setSingleStockData({
        ...stockSingleData, supplierId: option.supplierId,
        supplierName: option.supplierName
      });
    }
  };

  const getProductByProductId = async (prdId) => {
    try {
      const cartUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeCartType)}${GET_SINGLE_PRODUCT_DATA}?token=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&prdId=${prdId}`;
      const response = await axios.get(cartUrl, {});
      printDebugLog("getProductByProductId", response);
      if (response.data.status === SUCCESS) {
        const jcJson = response.data.jcJson;
        setSupplierSundryAllowed(jcJson.supplierSundryAllowed);
        if (!jcJson.supplierSundryAllowed) {
          setSupplierSundryDenom(SupplierSundryStock.Create_New);
        }
        setItemDets({
          ...itemDets,
          prdId: jcJson.prdId,
          hsnCode: jcJson.hsnCode,
          prd: jcJson.prd,
          composition: jcJson.composition,
          productImage: jcJson.productImage,
          catgName: jcJson.catgName,
          rack_no: jcJson.rack_no,
          cnvDetails: jcJson.cnvDetails,
          unitName: jcJson.unitName,
          smallUnitName: jcJson.smallUnitName,
          smallUnitCount: jcJson.smallUnitCount,
          unitRestrictions: jcJson.unitRestrictions,
          unitMultiplierCount: jcJson.unitMultiplierCount,
          unitMultiplierName: jcJson.unitMultiplierName,
          smallUnitRestrictions: jcJson.smallUnitRestrictions,
        });
        const stockCreated = jcJson.stockCreated;
        if (stockCreated) {
          const mBigUnitePriceMap = jcJson.bigUnitePriceMap;
          const mSmallUnitPriceMap = jcJson.smallUnitePriceMap;
          setStockManagement({
            ...stockManagement,
            stockThresHold:jcJson.stockThresHold,
            liveStockData: jcJson.liveStockData,
            liveStockOnlineData: jcJson.liveStockOnlineData,
            bigUnitPriceMap: mBigUnitePriceMap,
            smallUnitPriceMap: mSmallUnitPriceMap,
            saleSGst: jcJson.saleCgst,
            saleCgst: jcJson.saleCgst,
            saleIgst: jcJson.saleIgst,
            purchaseIGst: jcJson.purchaseIGst,
            purchaseCgst: jcJson.purchaseCgst,
            purchaseSgst: jcJson.purchaseSgst,
            sbcCessTax: jcJson.sbcCessTax,
            custGstDisabled: jcJson.custGstDisabled,
            buyerGstTaken: jcJson.buyerGstTaken,
            taxMethod: jcJson.taxMethod,
            taxMethodWs: jcJson.taxMethodWs,
          });
          if (mBigUnitePriceMap) {
            const data = mBigUnitePriceMap[rateCards];
            if (data) {
              setBigUnitPriceMap(data);
            } else {
              setBigUnitPriceMap(BIG_UNIT_PRICE);
            }
          }
          if (mSmallUnitPriceMap) {
            const data = mSmallUnitPriceMap[rateCards];
            if (data) {
              setSmallUnitPriceMap(data);
            } else {
              setSmallUnitPriceMap(SMALL_UNIT_PRICE);
            }
          }
          setHideCustomerPrice(false);
        } else {
          setHideCustomerPrice(true);
        }

      }
    } catch (e) {
      console.error("getProductByProductId", e);
    }
  };

  const getSundryBillRef = async (mBillRef) => {
    try {
      const cartUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeCartType)}search-sundry-bill-ref?token=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}&billRef=${mBillRef}&type=${supplierSundryDenom}`;
      const response = await axios.get(cartUrl, {});
      printDebugLog("getSundryBillRef", response);
      if (response.data.status === SUCCESS) {
        const jcJson = response.data.jcJson;
        setSundryBillRef(jcJson.fulldata);
      } else {
        setSundryBillRef([]);
      }
    } catch (e) {
      console.error("getSundryBillRef", e);
    }
  };

  const getSupplierDetails = async () => {
      try {
        const cartUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeCartType)}fetch-supplier-few-details?token=${CART_INITIAL_STATE.apiToken}&shopHash=${storeActiveShopHash.id}`;
        const response = await snpAxios.get(cartUrl);
        if (response.data.status === SUCCESS) {
          setSupplierData(response.data.jcJson.value_2);
        }
      } catch (e) {
        console.log("getSupplierDetails errr ", e);
      }
    };



  const getStockDetails = async (mPrdId) => {
    //console.log("setItemDets1 => ",itemDets);
    if (isEmpty(mPrdId)) {
      console.warn("getStockDetails mPrdId is empty")
      if (isEmpty(location?.state.prdId))
        navigate("/dashboard/itementry");
      else
        mPrdId = location?.state.prdId;
    }
    if (isDataLoaded)
      setDataLoaded(false);
    try {
      const cartUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeCartType)}${GET_STOCK_DATA}`;
      const data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE.apiToken,
          shopHash: storeActiveShopHash.id,
          value_1: mPrdId,
          cartType: storeCartType,
        }
      };
      const response = await snpAxios.post(cartUrl, data);
      printDebugLog("stock response  => ", response.data);
      setDataLoaded(true);
      if (response.data.status === SUCCESS) {
        setStockData(response.data.jcJson.value_2);
      }
    } catch (error) {
      setDataLoaded(true);
      printInfoLog("getStockDetails errr ", error);
      //window.location.href = "#/error";
    };
  };

  const handleSubmitCustomerPrices = async () => {
    const catgUrl = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeCartType)}${UPDATE_CUSTOMER_PRICES}`;
    const filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        prdId: itemDets.prdId,
        rateCards: rateCards,
        taxMethod: stockManagement.taxMethod,
        taxMethodWs: stockManagement.taxMethodWs,
        stockThresHold: parseInt(stockManagement.stockThresHold) || 0,
        productmrPrice: parseFloat(bigUnitePriceMap.productmrPrice),
        offerDenom: parseInt(bigUnitePriceMap.offerDenom),
        offerAmt: parseFloat(bigUnitePriceMap.offerAmt),
        productSellingPrice: parseFloat(bigUnitePriceMap.productSellingPrice),
        productWholeSalePrice: parseFloat(bigUnitePriceMap.productWholeSalePrice),
        sproductmrPrice: parseFloat(smallUnitPriceMap.sproductmrPrice),
        sofferDenom: parseInt(smallUnitPriceMap.sofferDenom),
        sofferAmt: parseFloat(smallUnitPriceMap.sofferAmt),
        sproductSellingPrice: parseFloat(smallUnitPriceMap.sproductSellingPrice),
        sproductWholeSalePrice: parseFloat(smallUnitPriceMap.sproductWholeSalePrice),
      }
    };
    //console.log("handleSubmitCustomerPrices => ", catgUrl, filledData);
    setDataLoaded(false);
    snpAxios.post(catgUrl, filledData)
      .then(response => {
        //console.log(" handleSubmitCustomerPrices responses => ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          getProductByProductId(itemDets.prdId);
        } else {
          console.log("No Data display in dialog ", response.data.status);
        }
      }).catch(error => {
        setDataLoaded(true);
        console.log("error : ", error);
        //window.location.href = "#/error";
      });
  };




  const getStockBarcode = async () => {
    try {
      const catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + 
                    GETSTOCKBARCODE + "/" + CART_INITIAL_STATE.apiToken + "/" + stockSingleData.stockRefNo;
  
      setDataLoaded(false);
      handleDialogClose();
  
      const response = await axios.get(catgUrl);
  
      setDataLoaded(true);
      printDebugLog("response getStockBarcode => " + stockSingleData.stockRefNo, response.data);
  
      if (!isEmpty(response.data) && ![NODATA, ACCESSDENIED, SESSIONEXPIRED, FAILED].includes(response.data)) {
        const url = storeApiUrl.BACKEND_BASE_URL + "files/" + response.data;
        window.open(url, '_blank');
      } else {
        printInfoLog("response getStockBarcode => " + response.data);
      }
    } catch (error) {
      printInfoLog("getStockBarcode error", error);
      setDataLoaded(true);
    }
  };
  

  const setStockPriority = async () => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + STOCK_PRIORITY;
    //console.log("setStockPriority => ",catgUrl);
    setDataLoaded(false);
    handleDialogClose();
    let data = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        value_1: stockSingleData.stockRefNo,
      }
    };
    snpAxios.post(catgUrl, data)
      .then((response) => {
        printDebugLog("setStockPriority response=> ", response.data);
        setDataLoaded(true);
        if (response.data.status === SUCCESS) {
          getStockDetails(itemDets.prdId);
        }
      }).catch((error) => {
        printInfoLog("setStockPriority ", error);
        setDataLoaded(true);
      });
  };

  const handleDelete = (stockRefNo) => {
    var array = [...stockData];
    printDebugLog("handleDelete -> ", array);
    var index = array.findIndex(singleValue => singleValue.stockRefNo === stockRefNo);
    printDebugLog("handleDelete -> ", index);
    if (index !== -1) {
      array.splice(index, 1);
      setStockData(array);
    }
    handleDialogClose();
    getStockDetails(itemDets.prdId);
  };


  const deleteStock = async (stockRefNo) => {
    let catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + DELETESTOCK + "/" + CART_INITIAL_STATE.apiToken + "/" + stockRefNo + "/" + storeActiveShopHash.id;
    //console.log("deleteStock => ",catgUrl);
    setDataLoaded(false);
    let data = {
    };
    axios.get(
      catgUrl,
      data
    )
      .then((response) => {
        setDataLoaded(true);
        printDebugLog("response deleteStock = > " + stockSingleData.stockRefNo, response.data);
        if (!isEmpty(response.data) && response.data === SUCCESS) {
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: "Successfully Deleted ",
          });
          getProductByProductId(itemDets.prdId);
          handleDelete(stockRefNo);
        } else {
          printInfoLog("response deleteStock => " + response.data);
          setSnpComponent({
            ...snpComponent,
            snackBarOpen: true,
            snackBarMsg: response.data,
          });
        }
      }).catch((error) => {
        printInfoLog("deleteStock ", error);
        setDataLoaded(true);
      });
  };

  const handleSubmitStock = async () => {
    setFormSubmitted(true);
    if (!isValid()) {
      return;
    }
    const catgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeCartType) + getPersistUrl();
    if (isDataLoaded) {
      setDataLoaded(false);
    }
    setShowDialog(false);
    const { quantity, productWholeSalePrice, productmrPrice, productSellingPrice,
      sQuantity, sproductWholeSalePrice, sproductmrPrice, sproductSellingPrice
     } = handleStockAiQuantityPrices();
    const filledData = {
      jcJson: {
        apiToken: CART_INITIAL_STATE.apiToken,
        shopHash: storeActiveShopHash.id,
        prdId: itemDets.prdId,
        stockRefNo: stockSingleData.stockRefNo,
        supplierId: supplierSingleData.supplierId,
        billNo: stockSingleData.billNo,
        barcodeNo: stockSingleData.barcodeNo,
        batchNo: stockSingleData.batchNo,
        mfgDate: stockSingleData.mfgDate,
        expiryDate: stockSingleData.expiryDate,
        expirySla:parseInt(stockSingleData.expirySla) || '',
        expirySlaTimeDenom:stockSingleData.expirySlaTimeDenom,
        saleSGst: parseFloat(stockSingleData.saleSGst),
        saleCgst: parseFloat(stockSingleData.saleCgst),
        saleIgst: parseFloat(stockSingleData.saleIgst),
        quantity: quantity,
        sQuantity: sQuantity,
        notGoodQuality: stockSingleData.notGoodQuality,
        purchaseCgst: parseFloat(stockSingleData.purchaseCgst),
        purchaseSgst: parseFloat(stockSingleData.purchaseSgst),
        purchaseIGst: parseFloat(stockSingleData.igst),
        sbcCessTax: parseFloat(stockSingleData.sbcCessTax),
        custGstDisabled: stockSingleData.custGstDisabled,
        buyerGstTaken: stockSingleData.buyerGstTaken,
        outOfStock: stockSingleData.outOfStock,
        productmrPrice: productmrPrice,
        offerDenom: parseInt(stockSingleData.offerDenom),
        offerAmt: parseFloat(stockSingleData.offerAmt),
        productSellingPrice: productSellingPrice,
        productWholeSalePrice: productWholeSalePrice,
        taxMethod: stockSingleData.taxMethod,
        taxMethodWs: stockSingleData.taxMethodWs,
        sproductmrPrice: sproductmrPrice,
        sofferDenom: parseInt(stockSingleData.sofferDenom),
        sofferAmt: parseFloat(stockSingleData.sofferAmt),
        sproductSellingPrice: sproductSellingPrice,
        sproductWholeSalePrice: sproductWholeSalePrice,
        purchasePrice: parseFloat(stockSingleData.purchasePrice),
        isEdit: isEditProduct,
        amount: parseFloat(isEditProduct ? 0.0 : account.accAmt),
        partialAmt: parseFloat(isEditProduct ? 0.0 : account.partialAmt),
        paidStatus: parseInt(isEditProduct ? 0 : account.accStatus),
        drCr: isEditProduct ? "" : DEBIT,
        mode: parseInt(isEditProduct ? 0 : AccountingPaidMode.VIASTOCK),
        containsFreeStock: stockSingleData.containsFreeStock,
        ruleMain: stockSingleData.ruleMain,
        ruleFree: stockSingleData.ruleFree,
      }
    };
    printDebugLog("stock request", filledData);
    snpAxios.post(catgUrl, filledData)
      .then(response => {
        printDebugLog("stock update responses => ", response.data);

        if (response.data.status === SUCCESS) {
          getProductByProductId(itemDets.prdId);
          getStockDetails(itemDets.prdId);
        }
      }).catch(error => {
        console.error("error : ", error);
        setDataLoaded(true);
        setFormSubmitted(false);
        //window.location.href = "#/error";
      });
  };

  const handleUpdateStockDetails = (ev, option) => {
    printDebugLog("handleUpdateStockDetails", option);
    if (option != null) {
      //console.log("handleUpdateStockDetails", event, option);
      setListStock([]);
      setInputValue('');
      getProductByProductId(option.id);
      getStockDetails(option.id);
    }
  };
  const handleItemSearchChange = (event) => {
    if (event) {
      setInputValue(event.target.value);
    }
  };
  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      getProductDetails(e.target.value);
    }
  }

  const getProductDetails = async (stock) => {
    try {
      setDataLoaded(false);
      const productDetails = `${storeApiUrl.BACKEND_BASE_URL}${getPreUrlAccordingToCart(storeActiveShopHash.cartType)}${GETPRODDETAILS}?adminId=${CART_INITIAL_STATE.apiToken}&stock=${stock}&shopHash=${storeActiveShopHash.id}`;
      const response = await axios.get(productDetails, {});
      if (response.data.status === SUCCESS) {
        setListStock(response.data.jcJson.value_2);
      } else {
        setListStock([]);
      }
      setDataLoaded(true);
    } catch (error) {
      console.error("getProductDetails error : ", error);
      setDataLoaded(true);
    }
  };

  useEffect(() => {
    printDebugLog("RestoStockInformation", location.state?.prdId, location.state?.isEditStock);
    setDataLoaded(false);
    let prdId = "";
    if (!isEmpty(location.state?.prdId)) {
      prdId = location.state?.prdId;
    } else if (!isEmpty(itemDets.prdId)) {
      prdId = itemDets.prdId;
    } else {
      setDataLoaded(true);
      //Show Tool Tip
    }
    getSupplierDetails();
    if (!isEmpty(prdId)) {
      getProductByProductId(prdId);
      getStockDetails(prdId);
    }
    if (location.state?.isEditStock === 1) {
      setShowDialog(true);
    }
  }, [storeCartType, storeApiUrl, storeActiveShopHash, location.state]);


  const renderUnitPrice = () => {
    return (
      <>
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#c9f2ba",
            boxShadow: 1,
            borderRadius: 5,
            m: 1,
            padding: 2,
          }} >
          <Grid container spacing={1} justifyContent="center"
            alignItems="center">
            <Grid item xs={12}>
              <ThreeDText
                text={"Stock & Price /" + itemDets.unitName}
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left" />
            </Grid>
            <Grid item xs={12}>
              <SnpButton variant="contained"
                mcolor="#121a0c"
                mbgcolor="rgb(185, 238, 166)"
                mpadding="3px"
                onClick={() => {
                  const { quantity, productWholeSalePrice, productmrPrice, productSellingPrice } = handleUpdateBigPrice();
                  setSingleStockData(prev => ({
                    ...prev,
                    quantity: quantity,
                    productWholeSalePrice: productWholeSalePrice,
                    productmrPrice: productmrPrice,
                    productSellingPrice: productSellingPrice,
                  }))
                }}
              >
                auto
              </SnpButton>
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextField
                id="quantity"
                name="quantity"
                variant="filled"
                size="small"
                disabled={stockSingleData.cantDelete}
                label={"Enter Quantity"}
                value={stockSingleData.quantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || /^\d*$/.test(value)) {
                    setSingleStockData({
                      ...stockSingleData,
                      quantity: value,
                    });
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="productWholeSalePrice"
                name="productWholeSalePrice"
                variant="filled"
                size="small"
                label={"Purchase Price(" + getTaxTag(stockSingleData.taxMethodWs) + ")"}
                value={stockSingleData.productWholeSalePrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="productmrPrice"
                name="productmrPrice"
                variant="filled"
                size="small"
                label={"Enter MRP(" + getTaxTag(stockSingleData.taxMethod) + ")"}
                value={stockSingleData.productmrPrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Any Offer : </FormLabel>
                <RadioGroup row={true} aria-label="offerDenom" name="offerDenom" value={stockSingleData.offerDenom.toString()} onChange={handleInputChange}>
                  <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                  <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                  <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                </RadioGroup>
              </FormControl>
              {
                (stockSingleData.offerDenom > 1) &&
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <TextField
                    id="offerAmt"
                    name="offerAmt"
                    variant="filled"
                    size="small"
                    label="Enter Offer Value"
                    value={stockSingleData.offerAmt.toString()}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
              }
              <Typography
                variant="p"
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Selling Price({getTaxTag(stockSingleData.taxMethod)})</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{twoDecimal(stockSingleData.productSellingPrice) + "/per " + itemDets.unitName}</span>
              </Typography>
            </Grid>
          </Grid></Paper>
      </>
    )
  };

  const renderSmallUnitPrice = () => {
    return (
      <>
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#a0c194",
            boxShadow: 1,
            borderRadius: 5,
            m: 1,
            padding: 2,
          }} >
          <Grid container spacing={1} justifyContent="center"
            alignItems="center">
            <Grid item xs={12}>
              <ThreeDText
                text={"Stock & Price /" + itemDets.smallUnitName}
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left" />
            </Grid>
            <Grid item xs={12}>
              <SnpButton variant="contained"
                mcolor="#121a0c"
                mbgcolor="rgb(140, 196, 120)"
                mpadding="3px"
                onClick={() => {
                  const { sQuantity, sproductWholeSalePrice, sproductmrPrice, sproductSellingPrice } = handleUpdateSmallPrice();
                  setSingleStockData(prev => ({
                    ...prev,
                    sQuantity: sQuantity,
                    sproductWholeSalePrice: sproductWholeSalePrice,
                    sproductmrPrice: sproductmrPrice,
                    sproductSellingPrice: sproductSellingPrice,
                  }))
                }}
              >
                auto
              </SnpButton>
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextField
                id="sQuantity"
                name="sQuantity"
                variant="filled"
                size="small"
                disabled={stockSingleData.cantDelete}
                label={"Enter Quantity"}
                value={stockSingleData.sQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || /^\d*$/.test(value)) {
                    setSingleStockData({
                      ...stockSingleData,
                      sQuantity: value,
                    });
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="sproductWholeSalePrice"
                name="sproductWholeSalePrice"
                variant="filled"
                size="small"
                label={"Purchase Price(" + getTaxTag(stockSingleData.taxMethodWs) + ")"}
                value={stockSingleData.sproductWholeSalePrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="sproductmrPrice"
                name="sproductmrPrice"
                variant="filled"
                size="small"
                label={"Enter MRP(" + getTaxTag(stockSingleData.taxMethod) + ")"}
                value={stockSingleData.sproductmrPrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Any Offer : </FormLabel>
                <RadioGroup row aria-label="sofferDenom" name="sofferDenom" value={stockSingleData.sofferDenom.toString()} onChange={handleInputChange}>
                  <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                  <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                  <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {
              (stockSingleData.sofferDenom > 1) &&
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <TextField
                  id="sofferAmt"
                  name="sofferAmt"
                  variant="filled"
                  size="small"
                  label="Enter Offer Value"
                  value={stockSingleData.sofferAmt.toString()}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            }

            <Grid item xs={12}>
              <Typography
                variant="p"
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Selling Price({getTaxTag()})</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{twoDecimal(stockSingleData.sproductSellingPrice) + "/per " + itemDets.smallUnitName}</span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  };

  const renderMultiUnitPrice = () => {
    return (
      <>
        <Paper elevation={10}
          autoHeight
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "rgb(169, 209, 155)",
            boxShadow: 1,
            borderRadius: 5,
            m: 1,
            padding: 2,
          }} >
          <Grid container spacing={1} justifyContent="center"
            alignItems="center">
            <Grid item xs={12}>
              <ThreeDText
                text={"Stock & Price /" + itemDets.unitMultiplierName}
                display=""
                className="three-d-text-container"
                justifyContent="left"
                alignItems="left" />
            </Grid>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              <TextField
                id="sQuantity"
                name="sQuantity"
                variant="filled"
                size="small"
                label={"Enter Quantity"}
                value={stockSingleData.mQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || /^\d*$/.test(value)) {
                    let sQuantity = 0;
                    let quantity = 0;
                    if (!isNumberEmpty(value)) {
                      sQuantity = (value / itemDets.unitMultiplierCount) | 0;
                    }
                    if (!isNumberEmpty(value)) {
                      quantity = (sQuantity / itemDets.smallUnitCount) | 0;
                    }
                    setSingleStockData({
                      ...stockSingleData,
                      quantity: quantity,
                      sQuantity: sQuantity,
                      mQuantity: value,
                    });
                  }
                }}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="mproductWholeSalePrice"
                name="mproductWholeSalePrice"
                variant="filled"
                size="small"
                label={"Purchase Price(" + getTaxTag(stockSingleData.taxMethodWs) + ")"}
                value={stockSingleData.mproductWholeSalePrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                id="sproductmrPrice"
                name="sproductmrPrice"
                variant="filled"
                size="small"
                label={"Enter MRP(" + getTaxTag(stockSingleData.taxMethod) + ")"}
                value={stockSingleData.sproductmrPrice}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12} lg={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Any Offer : </FormLabel>
                <RadioGroup row aria-label="sofferDenom" name="sofferDenom" value={stockSingleData.sofferDenom.toString()} onChange={handleInputChange}>
                  <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                  <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                  <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {
              (stockSingleData.sofferDenom > 1) &&
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <TextField
                  id="sofferAmt"
                  name="sofferAmt"
                  variant="filled"
                  size="small"
                  label="Enter Offer Value"
                  value={stockSingleData.sofferAmt.toString()}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            }

            <Grid item xs={12}>
              <Typography
                variant="p"
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Selling Price({getTaxTag()})</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{stockSingleData.sproductSellingPrice + "/per " + itemDets.smallUnitName}</span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  };

  const renderDialogContent = () => {
    return (
      <>
        <div>
          {
            !isDataLoaded && <Loader />
          }
          <Grid container spacing={1}>
            {
              stockSingleData.isExpired &&
              <Grid item xs={12}>
                <center>
                  <Typography variant="h4" component="div">
                    **Item Already Expired**
                  </Typography>
                </center>
              </Grid>

            }
            {
              stockSingleData.outOfStock &&
              <Grid item xs={12}>
                <center>
                  <Typography variant="h4" component="div">
                    **Out Of Stock**
                  </Typography>
                </center>
              </Grid>

            }
          </Grid>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#e2ecde",
              boxShadow: 1,
              borderRadius: 5,
              m: 1,
              padding: 2,
            }} >
            <div>
              {
                itemDets.catgName &&
                <Typography
                  variant="p"
                  component="div">
                  <span style={{ color: "blue", fontWeight: "bold" }}>Cateogory</span>
                  <span style={{ margin: "0 4px" }}>:</span>
                  <span style={{
                    color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}>{itemDets.catgName}</span>
                </Typography>
              }

              {
                itemDets.hsnCode &&
                <Typography
                  variant="p"
                  component="div">
                  <span style={{ color: "blue", fontWeight: "bold" }}>HSN Code</span>
                  <span style={{ margin: "0 4px" }}>:</span>
                  <span style={{
                    color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}>{itemDets.hsnCode}</span>
                </Typography>
              }
              <Typography
                variant="p"
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Item Name</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{itemDets.prd}</span>
              </Typography>
              {
                itemDets.cnvDetails &&
                <Typography
                  variant="p"
                  component="div">
                  <span style={{ color: "blue", fontWeight: "bold" }}>Unit details</span>
                  <span style={{ margin: "0 4px" }}>:</span>
                  <span style={{
                    color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}>{itemDets.cnvDetails}</span>
                </Typography>
              }
            </div>
          </Paper>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#f3e6e4",
              boxShadow: 1,
              borderRadius: 5,
              m: 1,
              padding: 2,
            }} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ThreeDText
                  text="Purchase Information"
                  display=""
                  className="three-d-text-container"
                  justifyContent="left"
                  alignItems="left" />
              </Grid>
              
              {supplierSundryAllowed &&
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Select</FormLabel>
                    <RadioGroup
                      aria-label="supplierSundryDenom"
                      name="supplierSundryDenom"
                      value={supplierSundryDenom}
                      onChange={(e) => setSupplierSundryDenom(parseInt(e.target.value))}
                      row={true}
                    >
                      {Object.entries(SupplierSundryStock).map(([key, value]) => (
                        <FormControlLabel
                          key={key}
                          value={value}
                          control={<Radio />}
                          label={key.replaceAll("_", " ")}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>}
              {
                supplierSundryDenom !== SupplierSundryStock.Create_New 
                && <Grid item xs={12} md={6} sm={6} lg={6}>
                <Autocomplete
                  id="sundry-select"
                  options={listSundryBillRef}
                  autoHighlight
                  variant="filled"
                  size="small"
                  onChange={handleSelectBillRef}
                  fullWidth
                  onBlur={(e) => setSundryBillRef([])}
                  getOptionLabel={(option) => option?.billNo || ''}
                  value={stockSingleData.billNo || ''}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      {option.details}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Bill No"
                      helperText={<span style={{ color:"#0038b8", fontWeight:"bold",fontSize: '16px', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{"Selected Bill No: "+stockSingleData.billNo}</span>}
                      onChange={handleSundryBillSearch}
                      InputLabelProps={{
                        style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                      }} />
                  )}
                />
              </Grid>
              }
              {
                supplierSundryDenom === SupplierSundryStock.Create_New &&
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    required
                    id="billNo"
                    name="billNo"
                    disabled={isEditProduct}
                    variant="filled"
                    size="small"
                    label="Purchase Bill Number"
                    value={stockSingleData.billNo}
                    onChange={(e) => setSingleStockData({
                      ...stockSingleData,
                      billNo: e.target.value,
                    })}
                    fullWidth
                    InputLabelProps={{
                      style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                    }} />
                </Grid>
              }
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <Autocomplete
                  required
                  id="supplier-select"
                  disabled={isEditProduct && supplierSundryDenom !== SupplierSundryStock.Create_New}
                  options={supplierData}
                  autoHighlight
                  variant="filled"
                  size="small"
                  onChange={handleSupplierChange}
                  fullWidth
                  getOptionLabel={(option) => option.supplierName}
                  value={supplierSingleData}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      {option.supplierName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Supplier Information"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      error={formSubmitted && !stockSingleData.supplierId}
                      helperText={getErrorMessage('supplier-select')}
                      InputLabelProps={{
                        style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                      }} />
                  )}
                />
              </Grid>
              {
                supplierSundryAllowed &&
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="purchasePrice"
                    name="purchasePrice"
                    variant="filled"
                    size="small"
                    disabled={supplierSundryDenom !== SupplierSundryStock.Create_New}
                    label="Total Price(Purchase Bill)"
                    value={stockSingleData.purchasePrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) { // Allow empty string or numeric values
                        setSingleStockData({
                          ...stockSingleData,
                          purchasePrice: value,
                        });
                      }
                    }}
                    fullWidth
                    InputLabelProps={{
                      style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                    }} />
                </Grid>
              }
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <p style={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word"
                }}>
                  <input
                    name="buyerGstTaken"
                    type="checkbox"
                    checked={stockSingleData.buyerGstTaken}
                    onChange={handleCheckboxChange}
                  />
                  Have Supplier charged you GST?</p>
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="purchaseSgst"
                  name="purchaseSgst"
                  variant="filled"
                  size="small"
                  label="Purchase SGST(In %)"
                  disabled={!stockSingleData.buyerGstTaken}
                  value={stockSingleData.purchaseSgst}
                  onChange={(e) => handleNumberChange(e.target.name, e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="purchaseCgst"
                  name="purchaseCgst"
                  variant="filled"
                  size="small"
                  disabled={!stockSingleData.buyerGstTaken}
                  label="Purchase CGST(In %)"
                  value={stockSingleData.purchaseCgst}
                  onChange={(e) => handleNumberChange(e.target.name, e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>

              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="sbcCessTax"
                  name="sbcCessTax"
                  variant="filled"
                  size="small"
                  label="Enter SbcCess Tax(In %)"
                  value={stockSingleData.sbcCessTax}
                  onChange={(e) => setSingleStockData({
                    ...stockSingleData,
                    sbcCessTax: e.target.value,
                  })}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#fff9d5",
              boxShadow: 1,
              borderRadius: 5,
              m: 1,
              padding: 2,
            }} >
            <Grid container spacing={1} justifyContent="center"
              alignItems="center">
              <Grid item xs={12}>
                <ThreeDText
                  text="Item Information"
                  display=""

                  justifyContent="left"
                  alignItems="left"
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="batchNo"
                  disabled={isEditProduct}
                  name="batchNo"
                  variant="filled"
                  size="small"
                  label="Enter Batch Number"
                  value={stockSingleData.batchNo}
                  onChange={(e) => setSingleStockData({
                    ...stockSingleData,
                    batchNo: e.target.value,
                  })}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="barcodeNo"
                  name="barcodeNo"
                  disabled={isEditProduct}
                  variant="filled"
                  size="small"
                  label="Enter Bar Code Number"
                  value={stockSingleData.barcodeNo}
                  onChange={(e) => setSingleStockData({
                    ...stockSingleData,
                    barcodeNo: e.target.value,
                  })}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="mfgDate"
                  name="mfgDate"
                  variant="filled"
                  size="small"
                  label="Enter Manufacture Date"
                  value={stockSingleData.mfgDate}
                  onChange={(e) => setSingleStockData({
                    ...stockSingleData,
                    mfgDate: e.target.value,
                  })}
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '18px', fontWeight: 'bold' },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="expiryDate"
                  name="expiryDate"
                  variant="filled"
                  size="small"
                  label="Enter Expiry Date"
                  value={stockSingleData.expiryDate}
                  onChange={(e) => setSingleStockData({
                    ...stockSingleData,
                    expiryDate: e.target.value,
                  })}
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '18px', fontWeight: 'bold' },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="expirySla"
                  name="expirySla"
                  variant="filled"
                  label="Enter Expiry Sla"
                  size="small"
                  value={stockSingleData.expirySla}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || /^[0-9]*$/.test(value)) {
                      setSingleStockData({
                        ...stockSingleData,
                        expirySla: value,
                      });
                    }
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <FormControl fullWidth>
                  <InputLabel>Select Expiry Time Denom.</InputLabel>
                  <Select value={stockSingleData.expirySlaTimeDenom} onChange={(e) => setSingleStockData(prev=>({
                    ...prev,expirySlaTimeDenom:e.target.value,
                  }))}>
                    {
                      SLA_TIME_DENOM.map((val,index) => (
                        <MenuItem key={index} value={val}>{val}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#ead1dc",
              boxShadow: 1,
              borderRadius: 5,
              m: 1,
              padding: 2,
            }} >
            <Grid container spacing={1} justifyContent="center"
              alignItems="center">
              <Grid item xs={12}>
                <ThreeDText
                  text="Sale Information"
                  display=""

                  justifyContent="left"
                  alignItems="left" />
              </Grid>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <p style={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word"
                }}>
                  <input
                    name="custGstDisabled"
                    type="checkbox"
                    checked={stockSingleData.custGstDisabled}
                    onChange={handleCheckboxChange}
                  />
                  Do you want to disable GST for Customer?</p>
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="saleSGst"
                  name="saleSGst"
                  variant="filled"
                  size="small"
                  label="Sale SGST(In %)"
                  disabled={stockSingleData.custGstDisabled}
                  value={stockSingleData.saleSGst}
                  onChange={(e) => handleNumberChange(e.target.name, e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <TextField
                  id="saleCgst"
                  name="saleCgst"
                  variant="filled"
                  size="small"
                  label="Sale CGST(In %)"
                  disabled={stockSingleData.custGstDisabled}
                  onChange={(e) => handleNumberChange(e.target.name, e.target.value)}
                  fullWidth
                  value={stockSingleData.saleCgst}
                  InputLabelProps={{
                    style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                  }} />
              </Grid>
            </Grid>
          </Paper>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#d58d71",
              boxShadow: 1,
              borderRadius: 5,
              m: 1,
              padding: 2,
            }} >
            <Grid container spacing={1} justifyContent="center"
              alignItems="center">
              <Grid item xs={12} sx={{
                margin: { xs: '0 0 64px 0', md: '0', sm: '0 0 32px 0', lg: '0' }
              }}>
                <ThreeDText
                  text={"Add RateCard/Quantity for " + itemDets.cnvDetails}
                  display=""
                  className="three-d-text-container"
                  justifyContent="left"
                  alignItems="left" />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <FormLabel component="legend">Tax Method(Purchase Price) : {getTaxPercentage(stockSingleData.purchaseCgst, stockSingleData.purchaseSgst)}</FormLabel>
                <RadioGroup row aria-label="taxMethodWs" name="taxMethodWs"
                  value={stockSingleData.taxMethodWs} onChange={handleInputChange}>
                  <FormControlLabel value={true} disabled={!stockSingleData.buyerGstTaken} control={<Radio />} label="Inc. GST" />
                  <FormControlLabel value={false} disabled={!stockSingleData.buyerGstTaken} control={<Radio />} label="Exl. GST" />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <FormLabel component="legend">Tax Method(MRP) : {getTaxPercentage(stockSingleData.saleCgst, stockSingleData.saleSGst)}</FormLabel>
                <RadioGroup row aria-label="taxMethod" name="taxMethod" value={stockSingleData.taxMethod} onChange={handleInputChange}>
                  <FormControlLabel value={true} disabled={stockSingleData.custGstDisabled} control={<Radio />} label="Inc. GST" />
                  <FormControlLabel value={false} disabled={stockSingleData.custGstDisabled} control={<Radio />} label="Exl. GST" />
                </RadioGroup>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} sm={6} lg={6}>
              {renderUnitPrice()}
            </Grid>
            {
              !itemDets.unitRestrictions &&
              <Grid item xs={12} md={6} sm={6} lg={6}>
                {renderSmallUnitPrice()}
              </Grid>
            }
            {/* <Grid item xs={12} md={6} sm={6} lg={6}>
                {renderMultiUnitPrice()}
              </Grid> */}
          </Grid>
          {
            itemDets.unitRestrictions &&
            <Paper elevation={10}
              autoHeight
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#c5b4a3",
                boxShadow: 1,
                borderRadius: 5,
                m: 1,
                padding: 2,
              }} >
              <Grid container spacing={1}>
                {
                  stockSingleData.containsFreeStock &&
                  <Grid item xs={12}>
                    <ThreeDText
                      text="Free Stock Information"
                      display=""
                      className="three-d-text-container"
                      justifyContent="left"
                      alignItems="left" />
                  </Grid>
                }

                <Grid item xs={12} md={12} sm={12} lg={12}>
                  <p style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word"
                  }}>
                    <input
                      name="containsFreeStock"
                      type="checkbox"
                      checked={stockSingleData.containsFreeStock}
                      onChange={handleCheckboxChange}
                    />
                    Add free stock</p>
                </Grid>
                {
                  stockSingleData.containsFreeStock &&
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <TextField
                      id="ruleMain"
                      name="ruleMain"
                      variant="filled"
                      size="small"
                      label="Enter Rule for Paid Item"
                      onChange={(e) => handleNumberChange(e.target.name, e.target.value)}
                      fullWidth
                      value={stockSingleData.ruleMain}
                      InputLabelProps={{
                        style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                      }} />
                  </Grid>
                }
                {
                  stockSingleData.containsFreeStock && <Grid item xs={12} md={6} sm={6} lg={6}>
                    <TextField
                      id="ruleFree"
                      name="ruleFree"
                      variant="filled"
                      size="small"
                      label="Enter Rule for Free Item"
                      onChange={(e) => handleNumberChange(e.target.name, e.target.value)}
                      fullWidth
                      value={stockSingleData.ruleFree}
                      InputLabelProps={{
                        style: { fontSize: '18px', fontWeight: 'bold' }, // Adjust font size and weight here
                      }} />
                  </Grid>
                }
              </Grid>
            </Paper>
          }

          {
            !stockSingleData.outOfStock &&
            <Grid container spacing={1}>
              {
                isEditProduct &&
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <SnpButton variant="contained" mbgcolor="#313033" onClick={getStockBarcode}>
                    Generate BarCode
                  </SnpButton>
                </Grid>
              }
              {
                isEditProduct &&
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <SnpButton variant="contained" mbgcolor="#8a68b5" onClick={setStockPriority}>
                    {stockSingleData.priorityHighinSell ? "Remove Priority" : "Make First Priority"}
                  </SnpButton>
                </Grid>
              }
              {/* {
                isEditProduct &&
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <SnpButton variant="contained" mbgcolor="#f57a73">
                    Not Good Quality
                  </SnpButton>
                </Grid>
              } */}
            </Grid>
          }
        </div>
      </ >
    );
  }

  const renderDialogAction = () => {
    return (
      <>
        {
          !stockSingleData.outOfStock && <SnpButton variant="contained" color="primary" onClick={handleSubmitStock}>
            {isEditProduct ? "Update" : "Submit"}
          </SnpButton>
        }
        <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
          Cancel
        </DismissButton>
      </>
    );
  }

  const renderSnpComponent = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#e9d9d696",
              boxShadow: 1,
              borderRadius: 4,
              padding: 2,
              maxHeight: 230,
              height: 230,
            }} >
            <div>
              <Autocomplete
                id="search-select"
                options={listStock}
                autoHighlight
                variant="filled"
                size="small"
                fullWidth
                onChange={handleUpdateStockDetails}
                onBlur={(e) => setListStock([])}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                getOptionLabel={(option) => option?.details || ''}
                inputValue={inputValue}
                onInputChange={handleItemSearchChange}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    option.details?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.prdType?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.tags?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.catgName?.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                  >
                    {uiUtilInstance.getStockOptionLabel(option)}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Item"
                    onChange={handleSearch}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
              <Typography
                variant="p"
                style={{ fontSize: "14px" }}
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Rack No</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{
                  color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word'
                }}>{itemDets.rack_no}</span>
              </Typography>
              <Typography
                variant="p"
                style={{ fontSize: "14px" }}
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Available Stock</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{
                  color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word'
                }}>{stockManagement.liveStockData || "No Stock Found.Add new stock"}</span>
              </Typography>
              {
                stockManagement.liveStockOnlineData &&
                <Typography
                  variant="p"
                  style={{ fontSize: "14px" }}
                  component="div">
                  <span style={{ color: "blue", fontWeight: "bold" }}>Online Stock</span>
                  <span style={{ margin: "0 4px" }}>:</span>
                  <span style={{
                    color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}>{stockManagement.liveStockOnlineData}</span>
                </Typography>
              }
              <Typography
                variant="p"
                style={{ fontSize: "14px" }}
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Expired Stock</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{
                  color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word'
                }}>{stockManagement.expiryLiveStockData || "0"}</span>
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sm={12} lg={6}>
          <Paper elevation={10}
            autoHeight
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#7dc2c9",
              boxShadow: 1,
              borderRadius: 4,
              padding: 2,
              maxHeight: 230,
              height: 230,
            }} >
            <div>
              {
                itemDets.productImage &&
                <img
                  alt=""
                  style={{ border: "1", borderColor: "primary", height: "80px", width: "80px", borderRadius: "100%", justifyContent: "end" }}
                  src={storeApiUrl.BACKEND_BASE_URL + "files/" + itemDets.productImage}
                />
              }
              {
                itemDets.catgName &&
                <Typography
                  variant="p"
                  style={{ fontSize: "14px" }}
                  component="div">
                  <span style={{ color: "blue", fontWeight: "bold" }}>Cateogory</span>
                  <span style={{ margin: "0 4px" }}>:</span>
                  <span style={{
                    color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}>{itemDets.catgName}</span>
                </Typography>
              }

              {
                itemDets.hsnCode &&
                <Typography
                  variant="p"
                  style={{ fontSize: "14px" }}
                  component="div">
                  <span style={{ color: "blue", fontWeight: "bold" }}>HSN Code</span>
                  <span style={{ margin: "0 4px" }}>:</span>
                  <span style={{
                    color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word'
                  }}>{itemDets.hsnCode}</span>
                </Typography>
              }
              {
                (itemDets.composition && itemDets.composition.length > 0) && (
                  <Typography
                    variant="p"
                    style={{ fontSize: "14px" }}
                    component="div">
                    <span style={{ color: "blue", fontWeight: "bold" }}>{storeActiveShopHash.cartType === CartDets.Pharma
                      ? "Composition/Tags" : "Tags"}</span>
                    <span style={{ margin: "0 4px" }}>:</span>
                    <span style={{
                      color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}>{"[" + itemDets.composition + "]"}</span>
                  </Typography>
                )
              }
              <Typography
                variant="p"
                style={{ fontSize: "14px" }}
                component="div">
                <span style={{ color: "blue", fontWeight: "bold" }}>Item Name</span>
                <span style={{ margin: "0 4px" }}>:</span>
                <span style={{ color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{itemDets.prd}</span>
              </Typography>
            </div>
          </Paper>
        </Grid>

        {!hideCustomerPrice && stockData && stockData.length > 0 &&
          <Grid item xs={12}>
            <Paper elevation={10}
              autoHeight
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#f5f2e8",
                boxShadow: 1,
                borderRadius: 5,
                padding: 2,
              }} >
              <Grid container justifyContent="center"
                alignItems="center">
                <Grid item xs={12} md={4} sm={6} lg={4}>
                  <FormLabel component="legend">Tax Method(MRP) : {getTaxPercentage(stockManagement.saleCgst, stockManagement.saleSGst)}</FormLabel>
                  <RadioGroup row aria-label="taxMethod" name="taxMethod" value={stockManagement.taxMethod}
                    onChange={() => {
                      const previousCheckedValue = !stockManagement.taxMethod;
                      const { parsedCgst, parsedSgst, parsedPrice, parsedSmallPrice, parsedSp, parsedSmallSp, parsedOfferAmt, parsedSOfferAmt } =
                        calculateRegularPriceBasedOnTaxMethod(previousCheckedValue
                          , stockManagement.saleCgst
                          , stockManagement.saleSGst
                          , bigUnitePriceMap.offerDenom, bigUnitePriceMap.offerAmt,
                          smallUnitPriceMap.sofferDenom, smallUnitPriceMap.sofferAmt,
                          bigUnitePriceMap.productmrPrice, smallUnitPriceMap.sproductmrPrice);
                      setStockManagement({
                        ...stockManagement,
                        taxMethod: previousCheckedValue,
                        saleCgst: parsedCgst,
                        saleSGst: parsedSgst,
                      });
                      setBigUnitPriceMap({
                        ...bigUnitePriceMap,
                        productmrPrice: parsedPrice,
                        productSellingPrice: parsedSp,
                        offerAmt: parsedOfferAmt,
                      });
                      if (!itemDets.unitRestrictions) {
                        setSmallUnitPriceMap({
                          ...smallUnitPriceMap,
                          sproductmrPrice: parsedSmallPrice,
                          sproductSellingPrice: parsedSmallSp,
                          sofferAmt: parsedSOfferAmt,
                        });
                      }
                    }}>
                    <FormControlLabel value={true} control={<Radio />} label="Inc. GST" />
                    <FormControlLabel value={false} control={<Radio />} label="Exl. GST" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6} sm={6} lg={6}>
                  <TextField
                    id="stockThresHold"
                    name="stockThresHold"
                    variant="filled"
                    size="small"
                    label="Enter Stockthreshold(in %)"
                    value={stockManagement.stockThresHold}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*$/.test(value)) {
                        const mStockThresHold = parseInt(value) || '';
                        setStockManagement({
                          ...stockManagement,
                          stockThresHold: mStockThresHold,
                        });
                      }
                    }}
                    fullWidth
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <p style={{
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    color: "#8b0000",
                    fontSize: "17px"
                  }}>
                    **Note: These prices will change again if you create/update changes while Stock Entry
                  </p>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        }
        {!hideCustomerPrice && stockData && stockData.length > 0 &&
          <Grid item xs={12} md={6} sm={12} lg={6}>
            <Paper elevation={10}
              autoHeight
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#c9f2ba",
                boxShadow: 1,
                borderRadius: 5,
                padding: 2,
              }} >
              <Grid container spacing={1} justifyContent="center"
                alignItems="center">
                <Grid item xs={12}>
                  <ThreeDText
                    text={"Stock & Price / " + itemDets.unitName}
                    display=""
                    className="three-d-text-container"
                    justifyContent="left"
                    alignItems="left" />
                </Grid>
                <Grid item md={6} xs={12} sm={6} lg={6}>
                  <Typography
                    variant="p"
                    fontSize="14px"
                    component="div">
                    <span style={{ color: "blue", fontWeight: "bold" }}>{"Purchase Price(" + getTaxTag(stockManagement.taxMethodWs) + ")"}</span>
                    <span style={{ margin: "0 4px" }}>:</span>
                    <span style={{
                      color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}>{"\u20B9" + bigUnitePriceMap.productWholeSalePrice}</span>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} sm={6} lg={6}>
                  <TextField
                    id="productmrPrice"
                    name="productmrPrice"
                    variant="filled"
                    size="small"
                    label={"Enter MRP(" + getTaxTag(stockManagement.taxMethod) + ")"}
                    value={bigUnitePriceMap.productmrPrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        const mrp = parseFloat(value) || 0.0;
                        const price = getOfferTag(bigUnitePriceMap.offerDenom, bigUnitePriceMap.offerAmt, mrp);
                        setBigUnitPriceMap({
                          ...bigUnitePriceMap,
                          productmrPrice: value,
                          productSellingPrice: parseFloat(price),
                        })
                      }
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Any Offer : </FormLabel>
                    <RadioGroup row={true} aria-label="offerDenom" name="offerDenom" value={bigUnitePriceMap.offerDenom} onChange={(e) => {
                      let price = bigUnitePriceMap.productSellingPrice;
                      const value = e.target.value;
                      if (bigUnitePriceMap.productmrPrice > 0.0) {
                        if (Number(value) === OfferType.No_Offer) {
                          price = bigUnitePriceMap.productmrPrice;
                        } else if (Number(value) === OfferType.Flat) {
                          price = bigUnitePriceMap.productmrPrice - bigUnitePriceMap.offerAmt;
                        } else if (Number(value) === OfferType.Percentage) {
                          price = bigUnitePriceMap.productmrPrice
                            - ((bigUnitePriceMap.productmrPrice * bigUnitePriceMap.offerAmt) / 100);
                        }
                      }
                      setBigUnitPriceMap({
                        ...bigUnitePriceMap,
                        offerDenom: Number(value),
                        productSellingPrice: price,
                      })
                    }}>
                      <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                      <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                      <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                    </RadioGroup>
                  </FormControl>
                  {
                    (bigUnitePriceMap.offerDenom > 1) &&
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                      <TextField
                        id="offerAmt"
                        name="offerAmt"
                        variant="filled"
                        size="small"
                        label="Enter Offer Value"
                        value={bigUnitePriceMap.offerAmt.toString()}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            const parsedOfferAmt = parseFloat(value) || 0;
                            let price = bigUnitePriceMap.productSellingPrice;
                            if (bigUnitePriceMap.productmrPrice > 0.0) {
                              if (parseInt(bigUnitePriceMap.offerDenom) === OfferType.No_Offer) {
                                price = bigUnitePriceMap.productmrPrice;
                              } else if (parseInt(bigUnitePriceMap.offerDenom) === OfferType.Flat) {
                                price = bigUnitePriceMap.productmrPrice - parsedOfferAmt;
                              } else if (parseInt(bigUnitePriceMap.offerDenom) === OfferType.Percentage) {
                                price = bigUnitePriceMap.productmrPrice
                                  - ((bigUnitePriceMap.productmrPrice * parsedOfferAmt) / 100);
                              }
                            }
                            setBigUnitPriceMap({
                              ...bigUnitePriceMap,
                              offerAmt: value,
                              productSellingPrice: price,
                            });
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      fontSize="15px"
                      component="div">
                      <span style={{ color: "blue" }}>Selling Price({getTaxTag(stockManagement.taxMethod)})</span>
                      <span style={{ margin: "0 4px" }}>:</span>
                      <span style={{
                        color: "green",
                        fontFamily: "monospace",
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'
                      }}>{"\u20B9" + bigUnitePriceMap.productSellingPrice + "/per " + itemDets.unitName}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid></Paper>
          </Grid>
        }
        {!hideCustomerPrice && !itemDets.unitRestrictions && stockData && stockData.length > 0 &&
          <Grid item xs={12} md={6} sm={12} lg={6}>
            <Paper elevation={10}
              autoHeight
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#93c47d",
                boxShadow: 1,
                borderRadius: 5,
                padding: 2,
              }} >
              <Grid container spacing={1} justifyContent="center"
                alignItems="center">
                <Grid item xs={12}>
                  <ThreeDText
                    text={"Stock & Price / " + itemDets.smallUnitName}
                    display=""
                    className="three-d-text-container"
                    justifyContent="left"
                    alignItems="left" />
                </Grid>
                <Grid item md={6} xs={12} sm={6} lg={6}>
                  <Typography
                    variant="p"
                    fontSize="14px"
                    component="div">
                    <span style={{ color: "blue", fontWeight: "bold" }}>{"Purchase Price(" + getTaxTag(stockManagement.taxMethodWs) + ")"}</span>
                    <span style={{ margin: "0 4px" }}>:</span>
                    <span style={{
                      color: "green", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word'
                    }}>{"\u20B9" + smallUnitPriceMap.sproductWholeSalePrice}</span>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} sm={6} lg={6}>
                  <TextField
                    id="productmrPrice"
                    name="productmrPrice"
                    variant="filled"
                    size="small"
                    label={"Enter MRP(" + getTaxTag(stockManagement.taxMethod) + ")"}
                    value={smallUnitPriceMap.sproductmrPrice}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        const mrp = parseFloat(value) || 0.0;
                        const price = getOfferTag(smallUnitPriceMap.sofferDenom, smallUnitPriceMap.sofferAmt, mrp);
                        setSmallUnitPriceMap({
                          ...smallUnitPriceMap,
                          sproductmrPrice: value,
                          sproductSellingPrice: parseFloat(price)
                        })
                      }
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Any Offer : </FormLabel>
                    <RadioGroup row={true} aria-label="offerDenom" name="offerDenom" value={smallUnitPriceMap.sofferDenom} onChange={(e) => {
                      let price = smallUnitPriceMap.sproductSellingPrice;
                      const value = e.target.value;
                      if (smallUnitPriceMap.sproductmrPrice > 0.0) {
                        if (Number(value) === OfferType.No_Offer) {
                          price = smallUnitPriceMap.sproductmrPrice;
                        } else if (Number(value) === OfferType.Flat) {
                          price = smallUnitPriceMap.sproductmrPrice - smallUnitPriceMap.sofferAmt;
                        } else if (Number(value) === OfferType.Percentage) {
                          price = smallUnitPriceMap.sproductmrPrice
                            - ((smallUnitPriceMap.sproductmrPrice * smallUnitPriceMap.sofferAmt) / 100);
                        }
                      }
                      setSmallUnitPriceMap({
                        ...smallUnitPriceMap,
                        sofferDenom: Number(value),
                        sproductSellingPrice: price,
                      })
                    }}>
                      <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                      <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                      <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                    </RadioGroup>
                  </FormControl>
                  {
                    (smallUnitPriceMap.sofferDenom > 1) &&
                    <Grid item md={12} xs={12} sm={12} lg={12}>
                      <TextField
                        id="offerAmt"
                        name="offerAmt"
                        variant="filled"
                        size="small"
                        label="Enter Offer Value"
                        value={smallUnitPriceMap.sofferAmt.toString()}
                        onChange={(e) => {
                          const value = e.target.value
                          if (value === '' || /^\d*\.?\d*$/.test(value)) {
                            const parsedOfferAmt = parseFloat(value) || 0;
                            let price = smallUnitPriceMap.sproductSellingPrice;
                            if (smallUnitPriceMap.sproductmrPrice > 0.0) {
                              if (parseInt(smallUnitPriceMap.sofferDenom) === OfferType.No_Offer) {
                                price = smallUnitPriceMap.sproductmrPrice;
                              } else if (parseInt(smallUnitPriceMap.sofferDenom) === OfferType.Flat) {
                                price = smallUnitPriceMap.sproductmrPrice - parsedOfferAmt;
                              } else if (parseInt(smallUnitPriceMap.sofferDenom) === OfferType.Percentage) {
                                price = smallUnitPriceMap.sproductmrPrice
                                  - ((smallUnitPriceMap.sproductmrPrice * parsedOfferAmt) / 100);
                              }
                            }
                            setSmallUnitPriceMap({
                              ...smallUnitPriceMap,
                              sofferAmt: value,
                              sproductSellingPrice: price,
                            });
                          }
                        }}
                        fullWidth
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Typography
                      variant="p"
                      fontSize="15px"
                      component="div">
                      <span style={{ color: "blue" }}>Selling Price({getTaxTag(stockManagement.taxMethod)})</span>
                      <span style={{ margin: "0 4px" }}>:</span>
                      <span style={{
                        color: "green",
                        fontFamily: "monospace",
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'break-word'
                      }}>{"\u20B9" + smallUnitPriceMap.sproductSellingPrice + "/per " + itemDets.smallUnitName}</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid></Paper>
          </Grid>
        }
        {itemDets.unitRestrictions && <Grid item xs={12} md={6} sm={6} lg={6} />}
        {stockData && stockData.length > 0 &&
          <Grid item xs={12} md={6} sm={6} lg={6}>
            <EnterButton
              variant="contained"
              color="primary"
              onClick={handleHideChange}
            >
              {(hideCustomerPrice ? "Show" : "Hide") + " Customer Prices"}
            </EnterButton>
          </Grid>}

        {
          !isEmpty(itemDets.prdId) &&
          <Grid item xs={6} md={6} sm={6} lg={6}>
          <EnterButton variant="contained" color="primary"
            onClick={() => {
              if (isRoleAllowed(STOCKCREATE)) {
                setSingleStockData({
                  ...stockSingleData,
                  saleCgst: stockManagement.saleCgst,
                  saleIgst: stockManagement.saleIgst,
                  saleSGst: stockManagement.saleSGst,
                  purchaseCgst: stockManagement.purchaseCgst,
                  purchaseSgst: stockManagement.purchaseSgst,
                  purchaseIGst: stockManagement.purchaseIGst,
                  custGstDisabled: stockManagement.custGstDisabled,
                  buyerGstTaken: stockManagement.buyerGstTaken,
                  taxMethod: stockManagement.taxMethod,
                  taxMethodWs: stockManagement.taxMethodWs,
                  productWholeSalePrice: bigUnitePriceMap.productWholeSalePrice,
                  sproductWholeSalePrice: smallUnitPriceMap.sproductWholeSalePrice,
                  productmrPrice:bigUnitePriceMap.productmrPrice,
                  offerDenom:bigUnitePriceMap.offerDenom,
                  offerAmt:bigUnitePriceMap.offerAmt,
                  productSellingPrice:bigUnitePriceMap.productSellingPrice,
                  sproductmrPrice:smallUnitPriceMap.sproductmrPrice,
                  sofferDenom:smallUnitPriceMap.sofferDenom,
                  sofferAmt:smallUnitPriceMap.sofferAmt,
                  sproductSellingPrice:smallUnitPriceMap.sproductSellingPrice,
                })
                setShowDialog(true);
              } else {
                setSnpComponent({
                  ...snpComponent,
                  isLoader: false,
                  negativeReq: false,
                  positiveReq: true,
                  positiveAction: "Ok Got it",
                  showDialog: true,
                  outSideClickDismiss: true,
                  cancelExtra: true,
                  requestType: 99,
                  subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                  handleSnpSubmit: () => handleDialogClose(),
                });
              }
            }}>
            Create New Stock Entry
          </EnterButton>
        </Grid>
        }
        {
          !hideCustomerPrice && !isEmpty(itemDets.prdId) &&
          <Grid item xs={12} md={4} sm={6} lg={4}>
            <EnterButton
              variant="contained" mbgcolor="#03396c"
              onClick={() => {
                if (isRoleAllowed(INVENTORYUPDATEPRICING)) {
                  handleSubmitCustomerPrices();
                } else {
                  setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: false,
                    positiveReq: true,
                    positiveAction: "Ok Got it",
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: "You Are not allowed to Add/Update Stock Details.Contact the Owner",
                    handleSnpSubmit: () => handleDialogClose(),
                  });
                }
              }}>
              Update New Prices
            </EnterButton>
          </Grid>
        }
        {
          !isDataLoaded && <Loader />
        }
        <DialogBox
          showDialog={showDialog}
          title={isEditProduct ? "Update Stock" : "Create New Stock Entry"}
          onClose={handleDialogClose}
          dialogContent={renderDialogContent()}
          dialogActions={renderDialogAction()}
        />
        <CustomDataGrid
          getRowId={row => row.stockRefNo}
          rows={stockData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowClassName={(params) => {
            if (params.row.outOfStock) {
              return 'out-of-stock-row';
            } else if (params.row.isExpired) {
              return 'expired-stock-row';
            }
            return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
          }
          }
        />
      </Grid>
    );
  };

  return (
    <>
      <SnpComponent showDialog={snpComponent.showDialog}
        title={snpComponent.title}
        subTitle={snpComponent.subTitle}
        negativeAction={snpComponent.negativeAction}
        positiveReq={snpComponent.positiveReq}
        positiveAction={snpComponent.positiveAction}
        negativeReq={snpComponent.negativeReq}
        handleDialogClose={() => setSnpComponent(INITIAL_SNP_COMPONENT)}
        iconReq={snpComponent.iconReq}
        iconContent={snpComponent.iconContent}
        content={renderSnpComponent()}
        onSnackClose={handleSnackClose}
        cancelExtra={snpComponent.cancelExtra}
        onCancel={snpComponent.handleSnpCancel}
        snackBarOpen={snpComponent.snackBarOpen}
        snackBarMsg={snpComponent.snackBarMsg}
        outSideClickDismiss={snpComponent.outSideClickDismiss}
        handleSubmit={snpComponent.handleSnpSubmit}
        gui={snpComponent.gui}
      />
    </>
  );
}