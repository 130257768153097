import { Grid, Typography, Box, RadioGroup, Radio, FormControlLabel, Paper, LinearProgress, TextField, Autocomplete } from "@mui/material";
import SnpButton from "../../global/SnpButton";
import { InternalBillType, isEmpty, SUCCESS } from "../../../constants/constant";
import { useState } from "react";
import uiUtilInstance from "../../../utilities/uiUtil";
import { snpAxios } from "../../global/api";

const MartReturnWasteHeader = ({ billNo = "", refBillNo = "", selectedDate = "", baseUrl = "",
    billType = InternalBillType.RETURN_BILL,
    setBillType,
    handleSelect,
    handleClearEveryThing,
    isPaid }) => {

    const [loader, setLoader] = useState(false);
    const [listAllBill, setListAllBill] = useState([]);
    const [listStock, setListStock] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const fetchBill = async (regexStr) => {
        try {
            setLoader(true);
            const fetchBillUrl = `${baseUrl}&regexStr=${regexStr}&paidBill=1&requiredReturnWasteBill=1`;
            const response = await snpAxios.post(fetchBillUrl, {});
            if (response.data.status === SUCCESS) {
                setListAllBill(response.data.jcJson.listBills || []);
            }
            setLoader(false);
        } catch (error) {
            console.error("Error fetching bills: ", error);
            setLoader(false);
        }
    };

    const handleItemSearchChange = (event) => {
        if (event) {
            setInputValue(event.target.value);
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        if (value && value.length >= 4) {
            fetchBill(value);
        }
    };

    const handleBillTypeChange = (event) => {
        const value = event.target.value;
        setBillType(Number(value));
        setInputValue("");
    };

    const handleSelectBill = (type, option) => {
        setInputValue("");
        handleSelect(type, option);
    }

    return (
        <Paper elevation={3}
            autoHeight
            sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#adccdf",
                boxShadow: 1,
                borderRadius: 5,
                padding: 1,
            }} >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            padding: "2px",
                        }}
                    >
                        {/* Bill No */}
                        <Typography>
                            <span style={{ color: "rgba(3, 92, 49, 0.88)", fontSize: "14px" }}>Bill No</span>
                            <span style={{ margin: "0 4px" }}>:</span>
                            <span style={{
                                color: "rgba(4, 63, 34, 0.88)", fontFamily: "monospace", whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word', fontSize: "16px", fontWeight: "bold"
                            }}>{billNo}</span>
                        </Typography>

                        {/* Date */}
                        <Typography sx={{ fontSize: "14px", color: "black" }}>
                            Date: {selectedDate}
                        </Typography>

                        {/* Create New Bill Button */}
                        <SnpButton
                            variant="contained"
                            mbgcolor="#266e8f"
                            disabled={isEmpty(billNo)}
                            onClick={handleClearEveryThing}
                        >
                            Create New Bill
                        </SnpButton>
                    </Box>
                </Grid>
                {/* Second Row - Radio Button Group */}
                <Grid item xs={12}>
                    <RadioGroup
                        row
                        value={billType}
                        onChange={handleBillTypeChange}
                        sx={{ display: "flex" }}
                    >
                        <FormControlLabel value={InternalBillType.RETURN_BILL} control={<Radio />} label="Return Bill" />
                        <FormControlLabel disabled value={InternalBillType.WASTE_BILL} control={<Radio />} label="Waste/Spoil Item Bill" />
                    </RadioGroup>
                </Grid>
                {/* Third Row - TextBox */}
                <Grid item xs={12} md={3} lg={3} sm={4}>
                            {
                                billType === InternalBillType.RETURN_BILL &&
                                <>
                                    {loader && <LinearProgress />}
                                    <Autocomplete
                                        id="search-select"
                                        options={listAllBill}
                                        autoHighlight
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        disabled={isPaid}
                                        onChange={(e, option) => handleSelectBill(InternalBillType.RETURN_BILL, option)}
                                        onBlur={(e) => setListAllBill([])}
                                        isOptionEqualToValue={(option, value) => option?.billNo === value?.billNo}
                                        getOptionLabel={(option) => uiUtilInstance.getSearchSelectDetails(option)}
                                        inputValue={inputValue}
                                        onInputChange={handleItemSearchChange}
                                        filterOptions={(options, { inputValue }) =>
                                            options.filter((option) =>
                                                option.billNo?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                option.custMob?.toLowerCase().includes(inputValue.toLowerCase())
                                            )
                                        }
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                            >
                                                {uiUtilInstance.getSearchSelectDetails(option)}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Here"
                                                onChange={handleSearch}
                                                disabled={isPaid}
                                                helperText={!isEmpty(refBillNo) && <><span style={{fontFamily:"monospace" , fontWeight:"bold" , fontSize:"16px"}}>{"Selected Bill No: " + refBillNo}</span></>}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </>
                            }
                            {
                                billType === InternalBillType.WASTE_BILL &&
                                <>
                                    {loader && <LinearProgress />}
                                    <Autocomplete
                                        id="search-select"
                                        options={listStock}
                                        autoHighlight
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        onChange={(e, option) => handleSelectBill(InternalBillType.WASTE_BILL, option)}
                                        onBlur={(e) => setListStock([])}
                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                        getOptionLabel={(option) => option?.details || ''}
                                        inputValue={inputValue}
                                        onInputChange={handleItemSearchChange}
                                        filterOptions={(options, { inputValue }) =>
                                            options.filter((option) =>
                                                option.details?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                option.prdType?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                option.tags?.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                option.catgName?.toLowerCase().includes(inputValue.toLowerCase())
                                            )
                                        }
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                {...props}
                                            >
                                                {uiUtilInstance.getStockOptionLabel(option)}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Here"
                                                onChange={handleSearch}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </>
                            }
                        </Grid>
            </Grid>
        </Paper>
    )
};

export default MartReturnWasteHeader;