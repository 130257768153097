import React from "react";

const PriceTag = ({ price, finalPrice, offerAmt }) => {
  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {offerAmt > 0 ? (
        <>
        <span style={{ color: "blue", fontWeight: "bold",fontSize: "14px" }}>Sold Price/s</span>
        <span style={{ margin: "0 4px" }}>:</span>
          <span style={{ fontSize: "14px", fontWeight: "bold", color: "green" }}>
            ₹{finalPrice}
          </span>
          <span
            style={{
              fontSize: "14px",
              color: "#046304",
              textDecoration: "line-through",
            }}
          >
            ₹{price}
          </span>
        </>
      ) : (
        <span style={{ fontSize: "20px", fontWeight: "bold" }}>₹{price}</span>
      )}
    </div>
  );
};

export default PriceTag;
