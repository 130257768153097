import { BillStatus, FoodType, InternalBillType, KITCHEN_COOKING, KITCHEN_READY, OfferType, PayMedium, SEND_TO_KITCHEN, Spicy, TRIP_ASSIGNED, TRIP_DELIVERED } from "../../../constants/constant";

 export const INITIAL_SNP_COMPONENT = {
    showDialog: false,
    outSideClickDismiss: true,
    title: "",
    subTitle: "",
    negativeAction: "",
    positiveAction: "",
    positiveReq: false,
    isLoader: false,
    iconReq: false,
    iconContent: "",
    snackBarOpen: false,
    snackBarMsg: "",
    cancelExtra: false,
    negativeReq: false,
    requestType: 0,
    handleSnpSubmit: null,
    handleSnpCancel: null,
    gui: "",
    snackVertical: "",
    snackHorizontal: "",
    snackColor: "",
  };

  //const [gstFeature, setGstFeature] = useState(false);

  export const INITIAL_BILL_STOCK_DATA = {
    prdId: "",
    name: "",
    sgst: 0,
    cgst: 0,
    price: 0.0,
    foodQuantityName: "",
    priceIndex: 0,
    finalprice: 0.0,
    note: "",
    savedQuantity: 1,
    quantity: 1,
    offerDenom: 0,
    offerAmt: 0.0,
    print: false,
    combo: false,
  };
  export const INITIAL_STOCK_DATA = {
    id: "",
    catgId: "",
    catgName: "",
    name: "",
    productImage: [],
    ingredients: "",
    spicy: Spicy.LESS_SPICY,
    foodType: FoodType.VEG,
    sgst: 0.0,
    cgst: 0.0,
    outOfStock: false,
    offertype: OfferType.No_Offer,
    offerAmt: 0.0,
    price: [],
    liveStockCount: "",
    unitCount: 0.0,
    smallUnitCount: 0.0,
    unitRestrictions: 0.0,
  };
  export const INITIAL_BILL_DETAILS = {
    subTotal: 0.0,
    cgst: 0.0,
    cgstAmt: 0.0,
    sgstAmt: 0.0,
    sgst: 0.0,
    isGstAllowed: true,
    roundOff: 0.0,
    cashTender: 0.0,
    cashReturn: 0.0,
    pointRedeem: 0.0,
    isPointAdded: false,
    total: 0.0,
    currDues: 0.0,
    pendingAmt: 0.0,
    offerDenom: OfferType.No_Offer,
    offerAmt: 0.0,
    billingType: InternalBillType.TakeAway,
    lapsedTime: 0,
    lpatialAmt: [],
    token: -1,
  };



  export const INITIALROOMDETAIS = {
    roomNo: "",
    roomBooked: false,
    hotelBillId: "",
    custName: "",
    saved: false,
    mobNo: "",
    emailId: "",
    address: "",
  };
  export const INITIAL_RIDER_DETAILS = {
    id: "",
    name: "",
  };

  export const INITIAL_LISTBILLINGDATA = {
    billNo: "",
    value_2: [],
    delItems: [],
    custId: "",
    custMob: "",
    custName: "",
    custEmail: "",
    address: "",
    custGstDetails: "",
    custCompanyName: "",
    billStatus: BillStatus.NO_ENTRY,
    pointRedeem: 0,
    token: 0,
    roundOff: 0.0,
    kot: 0,
    hotelBillId: "",
    hotelBillNo: "",
    homeDelvId: "",
    homeDelvOtp: "",
    generateOtp: "",
    onlineBillOtherName: "",
    settleDown: false,
    payLaterSignal: false,
   // createdAt: toda,
    tripUpdateReq: false,
    tripStatus: "",
    pTripStatus: "",
    assignedBy: "",
    assignedByName: "",
    riderId: "",
    riderIdName: "",
    transactionId: "",
    payAtCounterOpt: false,
    kotCopies: 1,
  };

  export const INITIAL_PAYMENT_DETAILS = {
    onlinePayThrough: "",
    onlinePayMedium: "",
    cashPayThrough: "",
    onlinePay: 0.0,
    cashPay: 0.0,
    cashTender: 0.0,
    cashReturn: 0.0,
    delvName: "",
    payMedium: PayMedium.NOPAY,
  };

  export const INITIAL_DEL_ITEM = {
    showDialog: false,
    prdId: "",
    delReason: "",
    prdName: "",
  };
  export const INITIAL_GENERATE_DIALOG = {
    showDialog: false,
    otp: "",
  };


 export  const TRIP_STATUS = [
    SEND_TO_KITCHEN,
    KITCHEN_COOKING,
    KITCHEN_READY,
    TRIP_ASSIGNED,
    TRIP_DELIVERED,
  ];

 export  const TRIP_STATUS_BACK = [
    {
      no: 1,
      name: SEND_TO_KITCHEN,
    },
    {
      no: 2,
      name: KITCHEN_COOKING,
    },
    {
      no: 3,
      name: KITCHEN_READY,
    },
    {
      no: 4,
      name: TRIP_ASSIGNED,
    },
    {
      no: 6,
      name: TRIP_DELIVERED,
    },
  ];

 export  const INITIAL_PARTIAL_PAY = {
    id: "",
    partialAmt: 0.0,
    onlineBillPaidThrough: "",
    onlinePay: 0.0,
    cashBillPaidThrough: "",
    cashPay: 0.0,
    paidAcceptBy: "",
    paidOn: "",
    cashTendered: 0.0,
    cashReturn: 0.0,
  };