import {
  AddCircleRounded,
  RemoveCircleRounded,
  SoupKitchen,
} from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  CART_INITIAL_STATE,
  FAILED,
  GETKOTBYITEM,
  isEmpty,
  isNumberEmpty,
  OfferType,
  printDebugLog,
  SUCCESS,
} from "../../../constants/constant";
import {
  removeItemFromBill,
  updateBillItem,
} from "../../../store/billDetailsSlice";
import CustomDataGrid from "../../global/CustomDataGrid";
import { setSnpComponentDetails } from "../../../store/snpGlobalSLice";
import BillItemEdit from "./BillItemEdit";
import BillItemDelete from "./BillItemDelete";
import { INITIAL_BILL_STOCK_DATA, INITIAL_STOCK_DATA } from "./RestBillConstants";
import axios from "axios";

export const generateBillItemsColumns = (
  handleAction,
  handleQuantityChage,
  setSnpSnackBarDetails,
  isPaid
) => {
  return [
    {
      field: "prddets",
      headerName: "Item",
      flex: 1,
      minWidth: 190,
      renderCell: (val) => {
        let data = val.row.name;
        if (val.row.foodQuantityName)
          data = data + "(" + val.row.foodQuantityName + ")";
        return data;
      },
    },
    {
      field: "quantity",
      headerName: "Qty",
      flex: 1,
      minWidth: 180,
      editable: true,
      renderCell: (val) => {
        return (
          <Grid container spacing={0}>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              <TextField
                id="colQuantity"
                //className={colQuantityClasses.input}
                name="colQuantity"
                type="number"
                inputMode="numeric"
                value={Number(val.row.quantity)}
                variant="standard"
                size="small"
                disabled={isPaid}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  "& .MuiInputBase-input": {
                    textAlign: "center",
                  },
                }}
                onChange={(e) => handleQuantityChage(val.row, e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() =>
                          handleQuantityChage(
                            val.row,
                            Number(val.row.quantity) + 1
                          )
                        }
                      >
                        <AddCircleRounded
                          color="success"
                          sx={{
                            fontSize: "2rem",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          handleQuantityChage(
                            val.row,
                            Number(val.row.quantity) - 1
                          )
                        }
                      >
                        <RemoveCircleRounded
                          color="warning"
                          sx={{
                            fontSize: "2rem",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "savedQuantity",
      headerName: "P.Qty",
      flex: 1,
      minWidth: 70,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: (val) => {
        return "\u20B9 " + val.row.price.sAmt;
      },
    },
    // {
    //     field: 'offer', headerName: 'Offer', flex: 1,
    //     renderCell: (val) => {
    //         return (parseInt(val.row.offerDenom) === OfferType.No_Offer) ?
    //             0.0 : (parseInt(val.row.offerDenom) === OfferType.Percentage) ? val.row.offerAmt + "%" : "Flat" + val.row.offerAmt;
    //     }
    // },
    // what is this finalPrice?
    {
      field: "finalPrice",
      headerName: "T. Price",
      flex: 1,
      renderCell: (val) => {
        return "\u20B9 " + val.row.finalPrice;
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            disabled={isPaid}
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              // if (listbillingData.payLaterSignal)
              //   setSnpComponent({
              //     ...snpComponent,
              //     snackBarMsg: "Paid bill cannot be edit(Paid from Hotel Pay)",
              //     snackBarOpen: true,
              //   });
              //else
              handleAction(cellValues);
            }}
          ></EditIcon>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              // if (listbillingData.payLaterSignal)
              //   setSnpComponent({
              //     ...snpComponent,
              //     snackBarMsg:
              //       "Paid bill cannot be deleted(Paid from Hotel Pay)",
              //     snackBarOpen: true,
              //   });
              // else
              handleAction(cellValues);
            }}
          ></DeleteIcon>
        );
      },
    },
    {
      field: "kot",
      headerName: "KOT",
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <SoupKitchen
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              handleAction(cellValues);
            }}
          ></SoupKitchen>
        );
      },
    },
  ];
};
const BillItemList = () => {
  const { billItems, isPaid, billDetails, billCachedProductDetails } = useSelector((state) => state.bill);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(INITIAL_STOCK_DATA);
  const [selectedBillItem, setSelectedBillItem] = useState(INITIAL_BILL_STOCK_DATA);
  const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
  const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
useEffect(()=>{
  console.log("billDetails: ", billDetails);
  
})

    const kotDisplay = (prdId) => {
        let dUrl = storeApiUrl.BACKEND_BASE_URL + GETKOTBYITEM + "/" + CART_INITIAL_STATE.apiToken + "/" + billDetails.billNo + "/" + storeActiveShopHash.id + "/" + billDetails.id;
        let data = {};
        //setSnpLoader(true);
        axios.get(
            dUrl,
            data
        )
            .then((response) => {
                //printLog("kotDisplay<=",response.data);
                //setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    let kots = response.data.jcJson.kots;
                    dispatch(setSnpComponentDetails({
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Dismiss",
                       // positiveReq: kotPrintItemWise,
                        positiveAction: "Print",
                        showDialog: true,
                        iconContent: <SoupKitchen />,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 99,
                        //handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                        //handleSnpSubmit: () => { printKotItmeWise(prdId); setSnpComponent(INITIAL_SNP_COMPONENT); },
                        //gui: buildKotDisplayData(response.data.jcJson.prdName, kots),
                    }));
                } else if (response.data.status === FAILED) {
                  dispatch(setSnpComponentDetails({
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 2,
                        subTitle: "Still Bill not reached to KOT state!",
                    }));
                }
            }).catch((error) => {
               // setSnpLoader(false);
                dispatch(setSnpComponentDetails({
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Ok!",
                    positiveReq: false,
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 2,
                    subTitle: "Still Bill not reached to KOT state!",
                }));
                printDebugLog("error : ", error);
                //window.location.href = "#/error";
            });
    }



  const handleQuantityChage = (cellData, updatedQuantity) => {
    console.log("new data after quantity change: ", cellData, updatedQuantity);

    let quantity = isEmpty(updatedQuantity) ? 0 : parseFloat(updatedQuantity);
    // check if quantity is exxeding stock count
    let stockSingleData = { unitCount: 10 }; //mock data
    if (
      Number(stockSingleData.unitCount) > 0 &&
      quantity > stockSingleData.unitCount
    ) {
      // setSnpComponent({
      //     ...snpComponent,
      //     snackBarOpen: true,
      //     snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
      // });
      console.log("out of stock", quantity);
      console.log("stockSingleData", stockSingleData);
    } else {
      // if (quantity <= 0) {
      //     quantity = 0.5;
      // }
      // if (isNumberEmpty(quantity)) {
      //     quantity = 0;
      // }

      let sPrice = 0.0;
      const currentPrice = cellData.price.sAmt;
      if (currentPrice > 0.0) {
        // why this check?
        quantity = parseFloat(quantity);
        let offerDenom =
          isNumberEmpty(cellData.offerDenom) ||
          cellData.offerDenom === Number.MIN_SAFE_INTEGER
            ? OfferType.No_Offer
            : Number(cellData.offerDenom);
        if (offerDenom === OfferType.No_Offer) {
          sPrice = currentPrice;
        } else if (offerDenom === OfferType.Flat) {
          sPrice = currentPrice - cellData.offerAmt;
        } else if (offerDenom === OfferType.Percentage) {
          sPrice = currentPrice - (currentPrice * cellData.offerAmt) / 100;
        }
        //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
        sPrice = quantity * sPrice;
        const updatedBillItem = {
          ...cellData,
          quantity: quantity,
          finalPrice: sPrice,
          print: false,
        };
        console.log("updatedBillItem: ", updatedBillItem);
        //let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
        //printLog("handleColQuantityChange =",sPrice,index)
        // let oldPrice = parseFloat(listbillingData.value_2[index].finalprice);
        const oldPrice = 0;
        // listbillingData.value_2[index].finalprice = sPrice;
        // listbillingData.value_2[index].quantity = quantity;
        // listbillingData.value_2[index].print = false;
        //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
        //doTotal(oldPrice, sPrice, parseFloat(cellData.cgst), parseFloat(cellData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);

        doTotal(
          oldPrice,
          sPrice,
          parseFloat(cellData.cgst),
          parseFloat(cellData.sgst),
          parseFloat(0),
          true,
          0,
          0,
          0
        );
        dispatch(updateBillItem(updatedBillItem));
        // setBillingData({
        //     ...listbillingData,
        //     value_2: listbillingData.value_2,
        //    // cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "", why do we need to chane these value?
        // });
      }
    }
  };

  const doTotal = (
    oldPrice = 0.0,
    billTotal = 0.0,
    mCgst = 0.0,
    mSgst = 0.0,
    mPointRedeem = 0.0,
    mIgstAllowed = false,
    mDelvCharge = 0.0,
    m_OfferDenom = OfferType.No_Offer,
    m_OfferAmt = 0.0
  ) => {
    printDebugLog(
      "doTotal =>",
      oldPrice,
      billTotal,
      mCgst,
      mSgst,
      mPointRedeem,
      mIgstAllowed,
      mDelvCharge,
      m_OfferDenom,
      m_OfferAmt
    );
    const parsedOldPrice = parseFloat(oldPrice) || 0.0;
    const parsedBillTotal = parseFloat(billTotal) || 0.0;
    const parsedPointRedeem = parseFloat(mPointRedeem) || 0.0;
    const parsedDelvCharge = parseFloat(mDelvCharge) || 0.0;
    const parsedOfferAmt = parseFloat(m_OfferAmt) || 0.0;
    printDebugLog(
      "doTotal =>",
      parsedOldPrice,
      parsedBillTotal,
      parsedPointRedeem,
      parsedDelvCharge,
      parsedOfferAmt
    );
    let mRoundOff = 0.0;
    const billDetails_subTotal = 0;
    let subTotal =
      parseFloat(billDetails_subTotal) + parsedBillTotal - parsedOldPrice;
    let total = subTotal;
    switch (parseInt(m_OfferDenom)) {
      case OfferType.Flat:
        total -= parsedOfferAmt;
        break;
      case OfferType.Percentage:
        total -= (subTotal * parsedOfferAmt) / 100;
        break;
      default:
        break; // No offer
    }

    printDebugLog("doTotal", total);
    let cgstAmt = 0.0;
    let sgstAmt = 0.0;
    if (mIgstAllowed) {
      cgstAmt = (total * parseFloat(mCgst)) / 100;
      sgstAmt = (total * parseFloat(mSgst)) / 100;
    }

    if (parsedPointRedeem >= total) {
      mPointRedeem = total.toFixed(2);
      total = 0.0;
    } else {
      total +=
        parseFloat(cgstAmt.toFixed(2)) +
        parseFloat(sgstAmt.toFixed(2)) -
        parsedPointRedeem +
        parsedDelvCharge;
      mRoundOff = total.toFixed() - total;
      total = total + mRoundOff;
    }

    printDebugLog("doTotal <=", total, subTotal, cgstAmt, sgstAmt, mRoundOff);
    //   const dues = partilBillAvail ? total : 0.0;
    //   {}
    //   setBillDetails(prvs => ({
    //       ...prvs,
    //       subTotal: subTotal.toFixed(2),
    //       cgst: mCgst,
    //       sgst: mSgst,
    //       pendingAmt: dues.toFixed(2),
    //       cgstAmt: cgstAmt.toFixed(2),
    //       sgstAmt: sgstAmt.toFixed(2),
    //       isGstAllowed: mIgstAllowed,
    //       total: total.toFixed(2),
    //       roundOff: mRoundOff.toFixed(2),
    //       pointRedeem: mPointRedeem,
    //       isPointAdded: (mPointRedeem > 0.0),
    //       offerDenom: m_OfferDenom,
    //       offerAmt: m_OfferAmt,
    //       cashTender: 0,
    //       cashReturn: 0,
    //   }));
    //   if (parsedDelvCharge > 0.0) {
    //       setPaymentDetails((prev) => ({
    //           ...prev,
    //           delvName: prev.delvName,  // Only this is being set
    //           onlinePayMedium: "",
    //           onlinePayThrough: "",
    //           onlinePay: 0.0,
    //           cashPay: 0.0,
    //           cashPayThrough: "",
    //           cashReturn: 0.0,
    //           cashTender: 0.0,
    //           payMedium: PayMedium.NOPAY,
    //       }));
    //   } else {
    //       setPaymentDetails(INITIAL_PAYMENT_DETAILS);
    //   }
    //  // setOnlinePayEnb(false); why do we need it?
    //  // setCashPayEnb(false);
    //   setDelvCharge(parsedDelvCharge);
  };
  const handleBillItemAction = (rowDetails) => {
    const selectedProductData = []; // mocked
    printDebugLog("handleBillItemAction", rowDetails);
    printDebugLog("handleBillItemAction.row", rowDetails.row);
    const { field } = rowDetails;
    printDebugLog("rowDetails: ", field);
    if (!isPaid) {
      if (field === "edit") {
        let billDetails = rowDetails.row;
        setShowEditDialog(true);
        const product = billCachedProductDetails.find(
          (product) => product.id === rowDetails.row.id
        );
        if (product) {
          //let prvsVal = selectedProductData[index];
          // setItemDetailsOnEdit(opt, prvsVal);
          console.log("billDetails: ", billDetails);
          console.log("productDets: ", product);
          setSelectedBillItem({...selectedBillItem, ...billDetails})
          setSelectedProduct({...selectedProduct, ...product})
        } else {
          // getProductDataByonEdit(opt);
        }
      } else if (field === "delete") {
        // is this all function only for delete product? can we make it simple
        // what does this mean that we are deleting product if the bill is not saved
        console.log("billDetails.billNo", billDetails.billNo);
        
        if (isEmpty(billDetails.billNo)) {
          var index = billItems.findIndex(
            (singleValue) => singleValue.prdId === rowDetails.row.prdId
          );
          if (index !== -1) {
            //printLog("handleCellClick b", index, array);
            let oldPrice =
              parseFloat(billItems[index].price) * billItems[index].quantity;
            doTotal(
              oldPrice,
              0.0,
              parseFloat(billItems[index].cgst),
              parseFloat(billItems[index].sgst),
              parseFloat(billDetails.pointRedeem),
              billDetails.isGstAllowed,
              0 , //  delvCharge
              billDetails.offerDenom,
              billDetails.offerAmt
            );

            //printLog("handleCellClick ", listbillingData.value_2);
            dispatch(removeItemFromBill(billItems[index]));
          }
        } else {
          //
          // if (delReason) {
          // setDelItemDialog({
          //   delItemDialog,
          //   showDialog: true,
          //   prdId: event.row.prdId,
          //   delReason: "",
          //   prdName: event.row.name,
          // });
          // }
          //else deleteFromBill(event.row.prdId);
        }
      }
    } else {
      dispatch(
        setSnpComponentDetails({
          snackBarMsg: "Paid Dish Can't be Edited/Deleted",
          snackBarOpen: true,
        })
      );
    }
  };

  const memoizedData = useMemo(() => {
    console.log("fetched bill items from data: ", billItems);
    return billItems.map((item) => item); // Example:some preprocessing
  }, [billItems]);
  return (
    <div>
      <CustomDataGrid
        getRowId={(row) => row.id}
        rows={memoizedData}
        columns={generateBillItemsColumns(
          handleBillItemAction,
          handleQuantityChage,
          setSnpComponentDetails,
          isPaid
        )}
      />

      <BillItemDelete showDeleteDialog={showDeleteDialog} setShowDeleteDialog={setShowDeleteDialog}  />
      <BillItemEdit showEditDailog={showEditDialog} setShowEditDialog={setShowEditDialog} isPaid={false} productDetails={selectedProduct} billItemDetails={selectedBillItem} doTotal={doTotal} />
    </div>
  );
};

// Wrapping with React.memo to prevent unnecessary re-renders
export default React.memo(BillItemList);
