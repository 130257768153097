import { ChooseItemPrices, OfferType, printDebugLog } from "../../../constants/constant";

export const unitChangePriceLook = (mIndex, stockInfos, lastStockRefNo, choosePrices, chooseUnit, unitName, management, newAmount) => {
   const pItem = stockInfos.find(col => col.stockRefNo === lastStockRefNo);
   let price = 0.0;
   if (chooseUnit === unitName) {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         management.bigPrice.productmrPrice : pItem.bigPrice.productmrPrice;
   } else {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         management.smallPrice.productmrPrice : pItem.smallPrice.productmrPrice;
   }
   if (price !== newAmount) {
      return -1;
   }
   return mIndex
}

export const getColumnByItemIndex = (mColumnList, mItemIndex) => {
   return mColumnList.find(col => col.itemIndex === mItemIndex);
}

export const singleElementUseCaseCheck = (item, choosePrices, chooseUnit, currentStockRefNo, newAmount, stockInfos, unitName, management) => {
   if (item.choosePrices === choosePrices) {
      if (chooseUnit === item.unit && item.lastStockRefNo !== currentStockRefNo && item.price !== newAmount) {
         return -1;
      } else if (item.chooseUnit !== chooseUnit && item.lastStockRefNo !== currentStockRefNo) {
         return unitChangePriceLook(item.itemIndex, stockInfos, item.lastStockRefNo, choosePrices, chooseUnit, unitName, management, newAmount);
      }
   }

   if (item.choosePrices !== choosePrices
      && item.chooseUnit !== chooseUnit && item.lastStockRefNo !== currentStockRefNo) {
      //if one item is selected and changed the unit with other batch then treat as new entry only if both batch have different amount
      return unitChangePriceLook(item.itemIndex, stockInfos, item.lastStockRefNo, choosePrices, chooseUnit, unitName, management, newAmount);
   }
   return item.itemIndex;
}

export const findColumnIndex = (mColumnList, stockInfos, cnvMap, management, currentStockRefNo, prdId, choosePrices, chooseUnit, newAmount, mSelectedIndex) => {
   if (!mColumnList) {
      return -1;
   }
   if (!Array.isArray(mColumnList)) {
      return -1;
   }
   // Filter the list to find entries with the given prdId
   if (mSelectedIndex !== -1) {
      const item = getColumnByItemIndex(mColumnList, mSelectedIndex);
      return singleElementUseCaseCheck(item, choosePrices, chooseUnit, currentStockRefNo, newAmount, stockInfos, cnvMap.unitName, management);
   }
   const filteredColumns = mColumnList.filter(col => col.prdId === prdId);

   if (filteredColumns.length === 1) {
      printDebugLog("findColumnIndex", "inside one");
      // Case 1: Only one entry with this prdId
      const item = filteredColumns[0];

      return singleElementUseCaseCheck(item, choosePrices, chooseUnit, currentStockRefNo, newAmount, stockInfos, cnvMap.unitName, management);
   } else if (filteredColumns.length > 1) {
      printDebugLog("findColumnIndex", "inside multiple");
      // Case 2: Multiple entries with this prdId
      const matchingItem = filteredColumns.find(col =>
         col.unit === chooseUnit && col.price === newAmount
      );
      printDebugLog("findColumnIndex", matchingItem);
      return matchingItem === undefined ? -1 : matchingItem.itemIndex;
   }

   return -1;
};


const columnRateCardNormalBenefits = (singleStock, choosePrices, chooseUnit, mManagement, mCnvMap) => {
   let price = 0.0;
   let offerAmt = 0.0;
   let offerDenom = OfferType.No_Offer;
   let finalSp = 0.0;
   let cgstAmt = 0.0;
   let sgstAmt = 0.0;
   let appliedOfferAmt = 0.0;
   const cgst = mManagement.bigPrice.cgst;
   const sgst = mManagement.bigPrice.sgst;
   if (chooseUnit === mCnvMap.unitName) {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.productmrPrice : singleStock.bigPrice.productmrPrice;
      offerAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.offerAmt : singleStock.bigPrice.offerAmt;
      offerDenom = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.offerDenom : singleStock.bigPrice.offerDenom;
      cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.cgstAmt : singleStock.bigPrice.cgstAmt;
      sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.sgstAmt : singleStock.bigPrice.sgstAmt;
      const sp = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.productSellingPrice : singleStock.bigPrice.productSellingPrice;
      appliedOfferAmt = parseFloat(price) - parseFloat(sp);
      finalSp = parseFloat(sp);
   } else if (chooseUnit === mCnvMap.smallUnitName) {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.productmrPrice : singleStock.smallPrice.productmrPrice;
      offerAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.offerAmt : singleStock.smallPrice.offerAmt;
      offerDenom = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.offerDenom : singleStock.smallPrice.offerDenom;
      cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.cgstAmt : singleStock.smallPrice.cgstAmt;
      sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.sgstAmt : singleStock.smallPrice.sgstAmt;
      const sp = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.productSellingPrice : singleStock.smallPrice.productSellingPrice;
      appliedOfferAmt = parseFloat(price) - parseFloat(sp);
      finalSp = parseFloat(sp);
   } else {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.productmrPrice : singleStock.multiplierSmall.productmrPrice;
      offerAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.offerAmt : singleStock.multiplierSmall.offerAmt;
      offerDenom = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.offerDenom : singleStock.multiplierSmall.offerDenom;
      cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.cgstAmt : singleStock.multiplierSmall.cgstAmt;
      sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.sgstAmt : singleStock.multiplierSmall.sgstAmt;
      const sp = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.productSellingPrice : singleStock.multiplierSmall.productSellingPrice;
      appliedOfferAmt = parseFloat(price) - parseFloat(sp);
      finalSp = parseFloat(sp);
   }
   cgstAmt = cgstAmt.toFixed(2);
   sgstAmt = sgstAmt.toFixed(2);
   return { price, offerDenom, offerAmt, finalSp, cgst, sgst, cgstAmt, sgstAmt, appliedOfferAmt };
}

const columnRateCardAssociateBenefits = (singleStock, choosePrices, chooseUnit, mManagement, mCnvMap) => {
   let price = 0.0;
   let offerAmt = 0.0;
   let offerDenom = OfferType.No_Offer;
   let finalSp = 0.0;
   let cgstAmt = 0.0;
   let sgstAmt = 0.0;
   let appliedOfferAmt = 0.0;
   const cgst = mManagement.bigPrice.cgst;
   const sgst = mManagement.bigPrice.sgst;
   if (chooseUnit === mCnvMap.unitName) {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.productmrPrice : singleStock.bigPrice.productmrPrice;
      cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.cgstAmt : singleStock.bigPrice.cgstAmt;
      sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.bigPrice.sgstAmt : singleStock.bigPrice.sgstAmt;
      finalSp = parseFloat(price);
   } else if (chooseUnit === mCnvMap.smallUnitName) {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.productmrPrice : singleStock.smallPrice.productmrPrice;
      cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.cgstAmt : singleStock.smallPrice.cgstAmt;
      sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.smallPrice.sgstAmt : singleStock.smallPrice.sgstAmt;
      finalSp = parseFloat(price);
   } else {
      price = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.productmrPrice : singleStock.multiplierSmall.productmrPrice;
      cgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.cgstAmt : singleStock.multiplierSmall.cgstAmt;
      sgstAmt = choosePrices === ChooseItemPrices.Customer_Prices ?
         mManagement.multiplierSmall.sgstAmt : singleStock.multiplierSmall.sgstAmt;
      finalSp = parseFloat(price);
   }
   cgstAmt = cgstAmt.toFixed(2);
   sgstAmt = sgstAmt.toFixed(2);
   return { price, offerDenom, offerAmt, finalSp, cgst, sgst, cgstAmt, sgstAmt, appliedOfferAmt };
}


export const baseColumnRateCard = (singleStock, choosePrices, chooseUnit, mManagement, mCnvMap , useAssociateBenefit) => {
      return (
         useAssociateBenefit ? 
            columnRateCardAssociateBenefits(singleStock, choosePrices, chooseUnit, mManagement, mCnvMap) 
            : columnRateCardNormalBenefits(singleStock, choosePrices, chooseUnit, mManagement, mCnvMap)
      );
};