import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INITIAL_BILL_DETAILS } from "../components/billing/billing-ref/RestBillConstants";
import SnpError from "../exceptions/SnpError";
import { isEmpty, update } from "lodash";
import { CART_INITIAL_STATE, FETCH_BILL, SUCCESS } from "../constants/constant";
import { snpAxios } from "../components/global/api";

const billDetails = createSlice({
  name: "bill",
  initialState: {
    billDetails: {},
    billPaymentDetails: {},
    billRoomDetails: {},
    billRiderDetails: {},
    billItems: [],
    billCachedProductDetails: [],
    loading: false,
    error: null,
  },
  reducers: {
    setBillDetails: (state, action) => {
      state.billDetails = action.payload;
    },

    addItemToBill: (state, action) => {
      console.log("addBillItem: action.payload", action.payload);
      state.billItems.push(action.payload);
    },

    removeItemFromBill: (state, action) => {
      state.billItems = state.billItems.filter(
        (item) => item.id !== action.payload.id
      );
    },
    updateBillItem: (state, action) => {
      console.log("updating bill: ", action.payload);
      
      const updatedItem = action.payload;
      const index = state.billItems.findIndex(item => item.id === updatedItem.id);
      
      if (index !== -1) {
        state.billItems[index] = updatedItem;
      }
    },
    

    updateBillDetails: (state, action) => {
      const updatedBill = action.payload;
      state.billDetails = {...state.billDetails, updatedBill};
    },

    updateBillPaymentDetails: (state, action) => {
      const updatedDetails = action.payload;
      state.billPaymentDetails = {...state.billPaymentDetails, updatedDetails}
    } ,

    updateBillRoomDetails: (state, action) => {
      const updatedDetails = action.payload;
      state.billRoomDetails = {...state.billRoomDetails, updatedDetails}
    } ,

    updateBillRiderDetails: (state, action) => {
      const updatedDetails = action.payload;
      state.billRiderDetails = {...state.billRiderDetails, updatedDetails}
    } ,

    addCachedBillProductDetails: (state, action) => {
      state.billCachedProductDetails.push(action.payload)
    } ,

    extraReducers: (builder) => {
      builder.addCase(fetchBillDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBillDetails.fulfilled, (state, action) => {
        state.billItems = action.payload;
        state.loading = false;
      })
      .addCase(fetchBillDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    },
  },
});

const paeseFetchedBillDetails = () => {

}
export const fetchBillDetails = createAsyncThunk(
  "billDetails/fetchBillDetails",
  async (mBillNo, thunkAPI) => {
    if (!isEmpty(mBillNo)) {
      const state = thunkAPI.getState();
      const storeApiUrl = state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL;
      const shopHash = state.setActiveShopHashReducer.activeShopHash;
      let fetchBillUrl = storeApiUrl?.BACKEND_BASE_URL + FETCH_BILL;
      let data = {
        jcJson: {
          apiToken: CART_INITIAL_STATE?.apiToken,
          billNo: mBillNo,
          shopHash: shopHash?.id,
        },
      };
      //printLog("fetchBill  => ", data);
      snpAxios.post(fetchBillUrl, data).then((response) => {
        //printDebugLog("fetchBill response  => ", response.data);
        if (response.data.status === SUCCESS) {
          return response.data.jcJson;
        } else {
          throw new SnpError(response.data.status, response.data.message);
        }
      });
    } else {
      //printLog("Empty Bill No");
    }
  }
);
export const billDetailsReducer = billDetails.reducer;
export const { setBillDetails, addItemToBill, updateBillItem,updateBillDetails, removeItemFromBill, updateBillPaymentDetails, updateBillRoomDetails, addCachedBillProductDetails } = billDetails.actions;
